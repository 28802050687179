.page-reports-historico-avaluadores {
	.main-content {
		margin-left: 0;
		margin-right: 0;

		 > h1 {
			padding: 0;
		}
	}

	.avaluador {
		margin-bottom: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid $base-gray-soft-2;
		font-size: .87em; // 14px
		color: $base-gray-dark;

		.label {
			color: $base-blue-soft;
		}
	}
}

