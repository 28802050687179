// http://ana.seedlabs.co/eras/reportes
.page-reports {
	@include report-page;

	#avaluadores-form {
		@include report-page-uppercase;

		.report-container {
			background-color: #df5c58;
		}

		.form-select {
			max-width: 160px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	#sancionados-form {
		@include report-page-uppercase;

		.report-container {
			background-color: #f2b630;
		}
	}

	#tiempos-form {
		@include report-formato-textual;
		@include report-page-less-padding; // reduce padding-top

		@media only screen and (max-width: $mobile) {
			margin-bottom: 22px;
		}

		.report-container {
			background-color: #4a4a4a;

			> span {
				text-transform: none;

				&:nth-of-type(2) {
					font-size: 1.2em;
					line-height: 125%;
				}
			}

			@media only screen and (max-width: $tablet) {
				margin-bottom: 0;
			}
		}
	}

	#historico-avaluadores-form {
		@include report-formato-textual;
		@include report-page-uppercase;

		.report-container {
			background-color: #5b83b7;
		}
	}

	#detalle-avaluadores-form {
		@include report-formato-textual;
		@include report-page-uppercase;

		@media only screen and (max-width: $tablet) {
			margin-bottom: 102px;
		}

		.report-container {
			background-color: #456ca3;
			margin-bottom: 0;
			padding-top: 40px;
		}

		.form-item {
			margin-left: -9px;
			margin-right: -9px;
			position: static;
		}

		.form-text {
			position: absolute;
			//top: 45px;
			bottom: -50px;
			left: 0;
			right: auto;
			margin: auto;
			border-color: #456ca3;
			padding: 5px 10px 3px;
			height: 30px;
		}

		.form-actions {
			bottom: -82px;
			left: 0;
			margin: auto;
			position: absolute;
			right: 0;

			.form-submit {
				padding-top: 5px;
				padding-bottom: 6px;
				width: 100%;
			}
		}
	}

	#solicitudes-form {
		@include report-formato-textual;
		@include report-page-uppercase;

		.report-container {
			background-color: #797e7f;

			> span {
				&:nth-of-type(1) {
					padding-top: 5px;
				}
			}
		}
	}

	[id*="estado-avaluadores"] {
		.report-container {
			background-color: #5b83b7;

			.form-select {
				border: 1px solid #fff;
			}
		}
		
		.fieldset-wrapper {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			text-align: center;

			> span {
				text-align: center;
				width: 100%;
			}
		}

		.form-type-select {
			width: 48%;

			&::after {
				display: none;
			}
		}
		
	}

	.report-history {
		max-width: 800px;
		margin: 20px 0 0;
		padding: 20px 0;
		border-top: 1px solid $linea-color;

		li {
			@include inline-block;
			list-style: none;
			margin-right: 10px;
		}

		a {
			@include custom-button-1;
		}
	}
}












