html.page-era {
	height: 100%;
}

body.page-era {
	height: 100%;
	overflow: hidden;

	.layout-center,
	.layout-3col,
	.layout-3col__full,
	.main-content,
	.main-content > .row-fluid,
	.era-content {
		height: 100%;
	}

	.main-content {
		overflow-y: scroll;
	}
}

.page-era {
	p {
		color: white;
		margin-bottom: 20px;
	}

	.era-top {
		text-align: center;
		padding: 36px 0 14px;
		box-shadow: 0 5px 10px #111;
		position: relative;
		z-index: 50;
		background-color: white;

		@media only screen and (max-width: $tablet) {
			padding: 14px 0 6px;

			img {
				max-width: 70px;
				height: auto;
			}
		}
	}

	.form-title {
		color: white;
		font-size: 1.25em;
		font-weight: 100;
		letter-spacing: .5px;
		line-height: 100%;
		padding-bottom: 22px;
	}

	// Reset estilos core del date
	.container-inline-date {
		@include clearfix;

		.date-padding,
		.form-item .form-item {
			float: none;
			margin: 0;
		}

		.form-item {
			margin-right: 0;
			display: block;
		}
	}

	.form-item {
		margin-bottom: 9px;

		label {
			@include form-era-label;
			text-align: left;
			margin-bottom: 13px;
		}

		.form-text {
			font-size: 1em;
			font-weight: 400;
			padding: 3px 10px;
			border: 0;
			background-color: white;
			color: $base-blue-soft;
			height: 30px;
		}

		&.form-type-file {
			label {
				color: $base-blue-soft;
				border: 0;
			}
		}
	}

	.field-name-field-imagen-perfil {
		.label,
		label {
			@include inline-block;
			font-size: 14px !important;
		}

		.form-type-managed-file {
			text-align: right;
			font-size: 0;
		}
	}

	.era-content {
		height: 100%;
		padding-top: 27px;
		//background-color: $base-blue-soft;
		background-repeat: no-repeat;
		background-position: 50% 0;
		background-size: cover;
		position: relative;

		@media only screen and (min-width: $tablet) {
			padding-top: 60px;
		}

		> div {
			position: relative;
			z-index: 40;
		}
	}

	.era-buttons {
		text-align: center;
		margin-bottom: 10px;
		margin: 0 10px 10px;

		> div {
			margin: 0 auto 15px;

			@media only screen and (min-width: $tablet) {
				@include inline-block;
				width: 33%;
			}
		}

		a {
			@include custom-button-1;
			display: block;
			border-color: white;
			text-transform: none;
			font-weight: 400;
			color: white;
			margin: 0 auto;
			padding: .85em 0;
			font-size: 1.25em;
			max-width: 400px;
			letter-spacing: .5px;

			&:hover {
				color: white;
				border-color: white;
				background-color: $base-blue-dark;
			}

			&.active {
				background-color: $base-blue-dark;
			}
		}
	}

	.era-button {
		max-width: 570px;
		margin: 0 auto;
		text-align: center;
		padding: 0 0 20px;
		position: relative;
		border-bottom: 1px solid white;
		margin-bottom: 40px;

		@media only screen and (max-width: $mobile) {
			border: 1px solid white;
			border-top: 0;
			margin: 0 10px 10px;
		}

		.era-button-inner {
			@media only screen and (max-width: $mobile) {
				border-bottom: 1px solid white;
				position: relative;
				padding-bottom: 17px;
				margin: 0 15px;
			}

			&::after {
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 5.5px 9px;
				border-color: transparent transparent white;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
			}
		}

		a {
			font-size: 1.25em;
			text-decoration: none;
			color: white;
		}
	}

	// parent: .era-content
	#register-section {
		text-align: center;
		padding: 0 10px;

		form {
			@include inline-block;
			border: 1px solid white;
			max-width: 800px;
			width: 100%;
			padding: 13px 70px 30px;

			@media only screen and (max-width: $tablet) {
				padding-left: 12px;
				padding-right: 12px;
			}

			@media only screen and (max-width: $mobile) {
				border-bottom: 0;
			}
		}

		.label {
			@include form-era-label;

			@media only screen and (max-width: $mobile) {
				width: 89px;
				padding-top: 0;
				text-align: justify;
			}
		}

		.group-column1,
		.group-column2,
		.group-column3 {
			display: block;
			width: 100%;
		}

		#edit-profile-avaluador-field-document-type {
			label {
				display: none;
			}
		}

		@include cms-input-text-datepicker('.field-widget-date-popup', '.form-text', 200px);

		.field-widget-date-popup {
			background-color: white;
			width: 100%;
			max-width: none;

			legend,
			.form-text {
				font-size: 1em;
			}

			legend {
				font-weight: 400;

				.fieldset-legend {
					width: auto;
				}
			}
		}

		#edit-profile-avaluador {
			max-width: 100%;
			border: 0;
			margin: 0;
			padding: 0;
			display: block;

			> legend {
				@include form-era-title;
				display: block;
				text-align: center;
				color: white;
				margin: 0;
				padding: 15px 0 25px;
				width: 100%;
			}
		}

		#edit-actions {
			padding-top: 50px;

			.form-submit {
				@include custom-button-1;
				background-color: white;
				border: 0;
				font-size: 1.2em;
				padding: 1em 1.3em;
				font-weight: 600;

				@media only screen and (max-width: $desktop) {
					display: block;
					width: 100%;
					float: none;
					margin: 0 auto;
				}
			}
		}

    .field-name-field-documentos-adicionales {
      color: #FFF;
      text-align: left;
      .fieldset-description {
        padding: 10px 0;
      }
      .field-type-file {
        label {
          min-width: 75%;
        }
        .file-widget {
          display: block;
          float: right;
        }
      }
    }
	}

	div.form-item-files-experience {
		margin-bottom: 20px;
	}

	// parent: .era-content
	#login-section,
	#register-era {
		text-align: center;
		display: none;
		padding: 0 10px;

		@media only screen and (max-width: $mobile) {
			margin-bottom: 0 !important;
		}

		label {
			color: white;
			text-align: left;
			font-weight: 100;
			font-size: 1.1em;
			line-height: 100%;
			margin-bottom: 14px;
		}

		.form-item {
			margin-bottom: 19px;
		}

		form {
			@include inline-block;
			border: 1px solid white;
			width: 100%;
			padding: 12px;

			@media only screen and (min-width: $mobile) {
				max-width: 400px;
			}

			@media only screen and (max-width: $mobile) {
				border-bottom: 0;
			}
		}

		.description {
			color: white;
			font-size: .8em;
			text-align: left;
			display: none;
		}

		.form-actions {
			margin: 30px 0;

			@media only screen and (max-width: $mobile) {
				margin-bottom: 20px;
			}
		}

		.form-submit {
			@include custom-button-2;
			border-color: white !important;
			color: white;
			font-size: 1.2em;
			padding: .62em 1.8em;
			background: none;
			font-weight: 100;
		}
	}

	#register-section,
	#login-section,
	#register-era {
		@media only screen and (min-width: $mobile) {
			margin-bottom: 70px;
		}
	}

	//.form-item-categories {
	#edit-profile-avaluador-field-categorias,
	#edit-profile-avaluador-field-regimen,
	#edit-profile-avaluador-field-certificados-de-experienci-und-0-field-categorias,
	#edit-profile-avaluador-field-certificados-de-personas-und-0-field-objeto-del-certificado,
	#edit-profile-avaluador-field-certificados-de-personas-und-0-field-entidad {
		.form-type-select > label {
			display: none;
		}

		.SumoSelect {
			width: 100%;
			display: block;

			&  > .CaptionCont {
				@include form-text;
				width: 100%;
				font-size: 1em;
				padding: 4px 10px 2px;
				border: 0;
				border-radius: 0;
				height: 30px;

				& > span {
					font-size: inherit;
					color: $base-blue-soft;
					text-align: left;
					font-size: inherit;
					font-weight: 400;
					font-style: normal;
					padding-top: 1px;
					padding-bottom: 1px;
				}

				& > label {
					margin: 0;
					width: 42px;

					i {
						background-image: url('../img/sprites/Arrow-blue-down.png');
						opacity: 1;
						width: 22px;
						height: 23px;
					}
				}
			}

			& > .optWrapper {
				background-color: $base-blue-soft;
				border: 0;
				border-radius: 0;

				&.open {
					top: 31px;
				}

				& > .options > li {
					border-color: white;

					&:hover {
						background-color: $base-blue-dark;
					}

					label {
						font-size: 1em;
						margin: 0;
					}
				}
			}

			& > .optWrapper.multiple > .options > li.selected span i,
			& .select-all.selected > span i,
			& .select-all.partial > span i {
				background-image: url('../img/sprites/Check-box-selected.png');
				border: 1px solid white;
			}

			& > .optWrapper.multiple > .options > li span i,
			.select-all > span i {
				border: 0;
				border-radius: 0;
			}
		}
	}

	.form-type-file {
		text-align: right;
	}

	.form-type-managed-file {
		label {
			color: $base-blue-soft;
		}
	}

	.field-name-field-certificados-de-experienci,
	.field-name-field-certificados-de-personas {
		padding-top: 40px;

		table {
			border: 0;
			width: 100%;
			padding: 0;
			background: none;
		}

		tr,
		td,
		th {
			border: 0;
			width: 100%;
			padding: 0;
			background: none;
			display: block;
		}

		tbody {
			tr {
				padding-bottom: 20px;
				border-bottom: 1px solid white;
			}
		}

		th {
			label {
				@include form-era-title;
			}
		}

		.tabledrag-toggle-weight-wrapper,
		.field-multiple-drag {
			display: none;
		}

		table {
			padding: 0 10px;
		}
	}

	.field-name-field-categoria-experiencia {
		// Botón Eliminar
		& + .form-submit {
			@include custom-button-6;
		}
	}

	.field-name-field-documento-experiencia,
	.field-name-field-documento-personas {
		overflow: hidden;
		//padding-bottom: 20px;
		//margin-bottom: 10px;

		// Botón Subir
		.form-submit {
			@include custom-button-6;
		}

		// Botón Eliminar
		& + .form-submit {
			@include custom-button-5;
			margin-top: 10px;
		}

		.label {
			@include custom-button-6;
			@include form-era-label;
			color: white;
			background: none;
			border-color: transparent;
			border-left: 0;
			padding-left: 0;
		}

		.label,
		label,
		.file-widget {
			float: left;
		}

		label {
			@include custom-button-6;
			margin-right: 20px;
			cursor: pointer;
		}
		.form-type-mfw-managed-file {
			position: relative;
		}

		.form-file {
			opacity: 0;
			height: 30px;
			width: 220px;
			position: absolute;
			left: 0;
			top: 0;

			&:focus + label {
				background-color: black;
				outline: 1px dotted #000;
				outline: -webkit-focus-ring-color auto 5px;
			}
		}

		.description {
			@include form-era-label;
			float: left;
			width: 100%;
			clear: both;
			color: white;
			font-size: 1em;
			text-align: left;

			br {
				display: none;
			}
		}
	}

	.file-widget {
		.file {
			display: block;
			margin-right: 20px;
			float: left;
		}

		.file-icon,
		.file-size {
			display: none;
		}

		a {
			@include inline-block;
			width: auto;
			color: white;
			border-color: white;
			margin-left: 0;
			margin-right: 0;
			max-width: none;
			white-space: normal;
			word-wrap: break-word;
		}
		.file {
			display: block;
		}
	}

	.image-widget {
		.image-widget-data {
			display: none;
		}
	}

	.form-type-select .form-select {
		background-position: 98.2% 50%;
	}

	// Añadir otro item
	.field-add-more-submit {
		@include custom-button-5;
	}

	.field-name-field-documentos-adicionales {
		.file {
			a {
				color: $base-blue-soft;
			}
		}
	}
	
}
