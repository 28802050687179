.block-diagonal {
	padding: 20px;
	position: relative;

	.block__title {
		color: white;
	}

	.content {
		position: relative;
		z-index: 10;

		p {
			color: white;
		}
	}
}
