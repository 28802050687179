// URL: gestion-usuarios
// VIEW: view-admin-users
.table-layout-1 {
	@include table-layout-view-filter('.views-widget-filter-field_user_number_value');
	@include table-layout-view-header;
	@include table-edit-actions;
	//@include table-view-title-header-filters;
	@include clearfix;
	position: relative;

	.linea {
	//	@include table-line;
	}

	.form-table__sticky-header, .sticky-header {
		top: 77px !important;
		background-color: white;
		z-index: 20;

		&::before {
			display: none;
		}
	}

	.view-header {
		p {
			font-size: 1em;
		}
	}

	.view-content {
		
		clear: both;
		width: 100%;
		//margin-top: 15px;
		//overflow: auto;
		float: left;

		@media only screen and (min-width: $mobile) {
			height: 520px;
			//width: calc(100% + 24px) !important;
		}
	}

	// Borrar cuando haya puesto la nueva clase
	table {
		//margin-bottom: 20px;

		// JS appended div from scripts.js - function tableMobileType1 
		.appended-th {
			display: none;

			& + .form-type-checkbox {
				margin: 0 auto;
				display: block;
			}
		}

		@media only screen and (min-width: $tablet) {
			.views-field-views-row-selector {
				text-align: center;
				min-width: 37px;

				.form-item {
					margin: 0 auto;
				}
			}

			.views-field-name {
				//min-width: 190px;
				width: 24%;
			}

			.views-field-field-user-number {
				//min-width: 190px;
				width: 21.50%;
			}

			.views-field-field-pro-number {
				//min-width: 190px;
				width: 21.50%;
			}

			.views-field-field-status {
				//min-width: 125px;
				width: 15%;
			}

			.views-field-edit-node {
				//min-width: 125px;
				width: 13%;
				text-align: right;
			}
		}

		@media only screen and (max-width: $tablet) {
			@include tableMobileType1;
		}
	}
}