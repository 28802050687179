.block-ana-custom-ana-validate-avaluador {
	text-align: center;
	padding: 0 20px;
	//max-width: 740px;
	//margin: 0 auto;

	//.block-inner,
	#edit-actions {
		max-width: 740px;
		margin: 0 auto;
		padding: 0 0 20px;
		border-bottom: 1px solid $base-blue-soft;

		@media only screen and (min-width: $mobile) {
			padding: 0 0 40px;
		}
	}

	#result_avaluador {
		max-width: 1200px;
		margin: 0 auto;

		.form-wrapper .top {
			padding-top: 22px;
		}
	}

	.block__title {
		padding-top: 21px;
		margin-bottom: 0; //21px;
		font-size: 1.6em;
		font-weight: 400;

		@media only screen and (min-width: $mobile) {
			padding-top: 40px;
			font-size: 2.5em;
			//margin-bottom: 10px; //40px;
		}	
	}

	p {
		margin: 0 auto 13px;
		max-width: 740px;
	}

	a {
		text-decoration: none;
		color: $base-blue-soft;
	}

	.form-item {
		margin-bottom: 20px;
	}

	.form-text {
		padding: 4px 10px 5px;
		font-size: .9em;

		&::placeholder {
			color: #a1a1a1; //$base-gray-soft-2;
		}
	}

	.form-item {
		max-width: 350px;
		margin-left: auto;
		margin-right: auto;
	}

	.form-text {
		border-color: $base-gray-soft-2;
	}

	.top-item {
		@media only screen and (min-width: 600px) {
			width: 33.33%;
			float: left;
		}
	}

	.top,
	.middle,
	.sanciones {
		text-align: left;
		overflow: hidden;
		line-height: 120%;
	}

	.top,
	.middle {
		color: $base-blue-soft;
		margin-bottom: 32px;
	}

	.sanciones {
		font-size: .85em;
		line-height: 145%;
		color: $base-gray-dark;
		padding-bottom: 24px;
		border-bottom: 1px solid $base-blue-soft;

		.view-content {
			overflow: hidden;
			margin: 0 -30px;
		}

		.views-row {
			padding: 0 30px;

			@media only screen and (min-width: $tablet) {
				width: 50%;
				float: left;
			}
		}
	}

	.views-field-field-date,
	.views-field-title {
		margin-bottom: 2px;
	}

	.views-field-field-date {
		.views-label {
			color: $base-blue-soft;
		}

		.views-label,
		.field-content {
			@include inline-block;
		}
	}

	&#block-ana-custom-ana-validate-certificado {
		max-width: 740px;
		padding: 0 0 20px;
		margin: 0 auto;
		border-bottom: 1px solid $base-blue-soft;

		@media only screen and (min-width: $mobile) {
			padding: 0 0 40px;
		}

		.description {
			margin: 10px auto 0;
		}

		.form-item {
			max-width: inherit;
		}

		.form-text {
			max-width: 350px;
		}
	}
}
