.table-mobile-layout-1 {
	@media only screen and (max-width: $tablet) {
		.view-content {
			width: 100% !important;
		}
	}

	.view-header {
		margin-bottom: 20px;

		a {
			@include custom-button-1;
			font-size: 1em;
		}
	}

	table {
		margin-top: 6px; // para que se vea el before que hace de border top
		//margin-bottom: 20px;
		table-layout: fixed;

		// JS appended div from scripts.js - function tableMobileType1 
		.appended-th {
			display: none;

			& + .form-type-checkbox {
				margin: 0 auto;
				display: block;
			}
		}
		
		// Border top separado levemente de la tabla
		&::before,
		&::after {
			content: '';
		}

		.views-field-views-row-selector {
			width: 38px;
			padding-left: 0;
			padding-right: 0;
		}

		@media only screen and (min-width: $tablet) {
			.views-field-views-row-selector {
				text-align: center;
				min-width: 37px;

				.form-item {
					margin: 0 auto;
				}
			}

			.views-field-name {
				//min-width: 190px;
				width: 24%;
			}

			.views-field-field-user-number {
				//min-width: 190px;
				width: 21.50%;
			}

			.views-field-field-pro-number {
				//min-width: 190px;
				width: 21.50%;
			}

			.views-field-field-status {
				//min-width: 125px;
				width: 15%;
			}

			.views-field-edit-node {
				//min-width: 125px;
				width: 13%;
				text-align: right;
			}
		}

		@media only screen and (max-width: $tablet) {
			@include tableMobileType1;
		}
	}
}