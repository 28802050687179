#block-user-login {
	max-width: 700px;
	margin: 0 auto;
	padding: 23px 20px 38px;

	@media only screen and (min-width: $tablet) {
		padding: 23px 20px 47px; // 0 20px 47px;
	}

	.block-inner {
		max-width: 370px;
		margin: 0 auto;
	}

	.block__title {
		@include block-title;
		text-align: center;
		font-weight: 400;
	}

	.form-item {
		margin-bottom: 17px;

		&.form-type-password {
			margin-bottom: 15px;
		}
	}

	.form-text {
		padding: 4px 10px 5px;
	}

	label {
		font-weight: normal;
		font-size: 1.13em;
	}

	.form-actions {
		text-align: center;
		margin-bottom: 19px;
	}

	.item-list {
		ul {
			margin: 0;
			padding: 0;
		}

		li {
			list-style: none;
			text-align: center;

			&:first-child {
				display: none;
			}

			a {
				@include default-link;
				color: $base-blue-dark;
				font-size: 1.15em;
				text-decoration: none;
				font-weight: 500;
				padding-bottom: 5px; //10px;
				border-bottom: 1px solid $base-blue-soft;
			}
		}
	}
}

