div div table {
	border: 0;
	padding-top: 5px;
	margin: 0;
	position: relative;
	font-size: 1em;
	color: $dark;

	th,
	td {
		padding: 0;
		border-right: 1px solid $table-vertical-color;
		text-align: center;

		&:last-child {
			border-right: 0;
		}
	}

	thead {
		tr {
			border-bottom: 0;
		}

		th {
			color: $base-blue-dark;
			font-weight: 100;
			text-align: center;
			padding: 5px 10px 10px;
			line-height: 100%;
			border-bottom: 1px solid $table-horizontal-color;
		}
	}

	tbody {
		border: 0;

		tr {
			background-color: transparent;
			border-bottom: 1px solid $table-horizontal-color;
			padding: 0;

			&:last-child {
				border: 0;
			}

			&.ajax-new-content {
				td {
					text-align: left;
				}
			}
		}

		td {
			padding: 11px 10px 12px; //5px 0;
			font-weight: 400;
			color: $texto-tabla;
		}
	}

	a {
		@include custom-button-1;
		@include custom-button-tiny;
		@include inline-block;
		font-size: .8em;
		text-decoration: none;
		//min-width: 95px;
		width: 24%;
		font-weight: 600;
		text-align: center;
		margin-left: 3%; //15px;
		margin-right: 3%; //15px;
		max-width: 100px;
		min-width: 65px;
		box-sizing: content-box;
	
		@media only screen and (max-width: $tablet) {
			&:first-child {
				margin-left: 0;
			}
		}

		& + a {
			margin-left: 3%;
		}

		&:visited {
			color: $base-blue-soft;
		}

		@media only screen and (max-width: $tablet) {
			padding: 5px 0;
			//min-width: 100px;
		}
	}

	a {
		padding: 6px 5px 5px;
	}

	.views-field-nothing {
		a {
			background-color: white;
		}
	}

	& + .form-actions {
		.form-submit {
			margin-right: 20px;
		}
	}

	td.table-buttons {
		@media only screen and (min-width: $tablet) {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

