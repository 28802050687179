div.form-item {
	margin: 0 0 15px;

	label {
		margin-bottom: 14px;
		//padding-bottom: 20px;
		line-height: 100%;
	}
}

.form-text {
	@include form-text;
}