.page-user-edit-avaluador {
	@include cms-monster-fields('#edit-profile-avaluador-field-certificados-de-personas');
	@include cms-monster-fields('#edit-profile-avaluador-field-certificados-de-experienci');
	@include cms-input-text-datepicker('div.field-name-field-traslado-date', '.form-text', 300px);
	@include cms-input-text-datepicker('div.field-name-field-aprobacion-date', '.form-text', 350px);

	div.field-name-field-aprobacion-date {
		.fieldset-wrapper {
			width: auto;
		}

		.form-item {
			@include inline-block;
			width: auto;

			.form-text {
				width: 84px;
			}

			& + .form-item {
				.form-text {
					width: 60px;
				}
			}
		}
	}
	.SumoSelect{
		width: 100%;
	}

	.field-name-field-documentos-adicionales {
    text-align: left;
    .fieldset-description {
      padding: 10px 0;
    }
    .field-type-file {
      label {
        min-width: 75%;
      }
      .file-widget {
        display: block;
        float: right;
      }
    }
  }
}
