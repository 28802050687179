.block {
	$local-title-width: 367px; // Usado en .title-size-2 y en .form-requiere-informacion

	&.block-encabezado {
		color: $base-gray-dark;

		.contextual-links-wrapper {
			right: 100px;
		}

		.block__title {
			@include page-title;
		}

		.field-name-field-title-descripcion {
			@include page-subtitle;

			@media only screen and (max-width: $desktop) {
				margin-top: -12px;
				//font-size: .85em;
			}
		}

		.header-results {
			margin: -9px 0 8px;
			font-size: .85em;
			font-weight: 300;
		}

		.field-name-field-link {
			a {
				@include custom-button-1
				font-size: 1em;
				font-weight: 400;
				padding: 0.7em 1.5em;
			}

			@media only screen and (max-width: $desktop) {
				right: 0;
				margin-left: auto;
				margin-right: auto;
				text-align: center;
			}
		}
	}

	// URL: http://ana.seedlabs.co/gestion-usuarios
	&.block-encabezado-altern {
		margin-top: 40px;
		padding-top: 22px;
		position: static; // cuando se está logueado, adquiere relative que no queremos

		@media only screen and (max-width: $desktop) {
			padding-top: 10px;
		}

		@media only screen and (max-width: $mobile) {
			padding-top: 9px;
		}

		.field-name-field-link {
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	.block__title {
		color: $base-blue-soft;
	}

	&.logo-ana {
		.field-name-field-image {
			img {
				max-width: 95px;
				display: block;
				margin: 0 auto;

				@media only screen and (min-width: $desktop) {
					max-width: 150px;
				}
			}
		}
	}

	&.logos-footer {
		text-align: center;
		padding: 30px 0 20px;
		border-bottom: 1px solid $base-gray-soft-2;
		max-width: 1275px;
		margin: 0 auto;

		.field-item {
			@include inline-block;
			width: 77px; //49%;
			margin: 0 10px 10px;
			vertical-align: middle;

			.field-item {
				width: auto;
				margin: 0;
			}

			@media only screen and (min-width: $mobile) {
				width: 125px;
				margin: 0 5px 10px;
			}
		}

		img {
			max-width: 100%;
			width: 100%;
			height: auto;
		}
	}
	
	&.copyright {
		padding: 13px 0 16px;

		p {
			text-align: center;
			margin: 0;
			font-size: .85em;
		}
	}

	&#block-ana-custom-ana-top {
		@include clearfix;
		font-size: .9em;

		&.show-button {
			@media only screen and (max-width: $tablet) {
				img {
					display: none;
				}

				// js clone
				.block-encabezado {
					display: block;
				}
			}
		}

		.logo-era {
			text-align: center;
		}

		.user_image {
			float: left;
			margin-right: 10px;
		}

		img {
			max-width: 95px;

			@media only screen and (min-width: $desktop) {
				max-width: 155px;
				height: auto;
			}
		}

		.user {
			// Estos estilos deben estar también en .sidebar-menu
			display: none;

			@media only screen and (min-width: $desktop) {
				display: block;
				padding: 20px 30px 0 10px;
			}
			// END
		}

		.username {
			color: white;
		}

		a {
			color: $base-yellow;
			text-decoration: none;
		}

		// js clone
		.block-encabezado {	
			display: none;
			padding-bottom: 10px;

			a {
				border-color: white;
				color: white;
			}
		}

		.userlogout {
			float: right;
		}
	}

	&.block-presentacion {
		@include block-default;
		padding-bottom: 25px;
		overflow: hidden;
		background-color: $base-blue-dark;
		
		@media only screen and (max-width: $mobile) {
			padding-top: 21px;
		}

		.block-inner {
			@include site-mobile-padding;
			margin: 0 auto;
			max-width: 1075px;
		}

		.block__title {
			@include block-title;
			text-align: center;
			color: white;
			text-transform: uppercase;
			position: relative;
			font-weight: 400;

			@media only screen and (max-width: $mobile) {
				font-size: 1.6em;
				margin-bottom: 18px;
			}

			em  {
				font-style: normal;
			}
		}

		p {
			color: white;
			margin: 0;
			font-size: 1.1em; // mobile
			line-height: 142%; // mobile
			font-weight: 400;

			a {
				color: white;
			}
		}

		@media only screen and (min-width: 666px) {
			padding-top: 45px;
			padding-bottom: 60px;

			.block__title {
				@include block-title;
				font-family: 'open sans';
				font-weight: 700;
				margin-right: 55px;
				margin-bottom: 0;
				width: 240px;
				float: left;
				font-size: 58px;
				text-align: left;
				line-height: 132%;
				top: auto;
				top: -5px;

				em {
					font-size: 94px;
				}
			}

			.entity {
				overflow: hidden;
			}
		}

		@media only screen and (min-width: $desktop) {
			p {
				@include paragraph-xxl;
			}
		}

		// Bloques con el título más ancho y con menor font-size
		&.block-title-size-2 {
			padding-left: 10px;
			padding-right: 10px;
			padding-bottom: 0;

			// Tablet
			@media only screen and (min-width: $mobile) and (max-width: $desktop) {
				padding-top: 27px;
			}

			.block__title,
			.entity {
				@media only screen and (min-width: $mobile) and (max-width: $desktop) {
					max-width: 590px;
					margin: 0 auto;
				}
			}

			.block__title {
				font-family: "Raleway";

				em {
					font-size: inherit;
				}

				// Tablet
				@media only screen and (min-width: $mobile) and (max-width: $desktop) {
					font-size: 2.2em;
					line-height: 125%;
				}

				// Celular y Tablet: el título adquiere full width y se pone encima del párrafo
				// Al mismo tiempo .centered-pictures reduce .views-row height a 165px
				@media only screen and (max-width: $desktop) {
					width: auto;
					float: none;
					text-align: center;
				}

				@media only screen and (min-width: $desktop) {
					width: 390px; //$local-title-width; // variable al inicio del archivo
					font-size: 35px;
					line-height: 145%;

					em {
						font-size: 63px;
					}
				}
			}
		}
	}

	&.centered-pictures {
		padding-top: 15px;

		.view-header {
			text-align: center;
			padding-bottom: 20px;
		}

		// Tablet
		@media only screen and (min-width: $mobile) and (max-width: $desktop) {
			padding-top: 35px;
		}

		// Desktop
		@media only screen and (min-width: $desktop) {
			padding-top: 60px;
		}

		.view-content {
			text-align: center;

			@media only screen and (max-width: $mobile) {
				padding-left: $mobile-site-padding;
				padding-right: $mobile-site-padding;
			}
		}

		.views-row {
			@include inline-block;
			$local-height-mobile: 220px;
			$local-height-tablet: 165px;
			$local-height-desktop: 270px;
			max-width: 370px;
			width: 100%;
			height: $local-height-mobile;
			margin-bottom: 10px;
			border: 1px solid #969696;
			position: relative;

			// Tablet (tiene que usarse en  .form-require-informacion)
			@media only screen and (min-width: $mobile) and (max-width: $desktop) {
				height: $local-height-tablet;
				max-width: 220px;
				margin: 0 13px 28px;
			}

			// Desktop (tiene que usarse en  .form-require-informacion)
			@media only screen and (min-width: $desktop) {
				height: $local-height-desktop;
				margin: 0 22px 44px;
			}
		}

		.views-row-inner {
			@include vertical-align-text;
		}

		.views-field-field-entity-logo {
			cursor: pointer;
		}

		.active-js .views-field-field-status {
			opacity: 1;
			visibility: visible;
			bottom: 80%;
		}

		.views-field-field-status {
			@include custom-shadow-1;
			opacity: 0;
			visibility: hidden;
			position: absolute;
			bottom: 60%;
			left: -1px;
			background-color: $base-blue-soft;
			border: 1px solid white;
			width: auto;
			padding: 19px 24px 20px;
			transition: .6s ease-out all;
			z-index: 20;

			@media only screen and (min-width: $mobile) {
				left: -86px;
				width: 390px;
				padding-top: 24px;
			}

			@media only screen and (min-width: $desktop) {
				left: -11px;
			}

			&::before {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				width: 100px;
				height: 30px;
				z-index: 20;
				background-color: $base-blue-soft;
			}

			&::after {
				@include custom-shadow-1;
				content: "";
				width: 30px;
				height: 30px;
				transform: rotate(45deg);
				position: absolute;
				bottom: -15px;
				left: 0;
				right: 0;
				margin: auto;
				background-color: $base-blue-soft;
				border-right: 1px solid white;
				border-bottom: 1px solid white;
				z-index: 10;
			}
		}

		.close {
			background-color: $base-blue-dark;
			border: 1px solid white;
			width: 32px;
			height: 0;
			padding-top: 30px;
			overflow: hidden;
			position: absolute;
			top: -16px;
			right: -16px;
			border-radius: 50px;
			color: white;
			text-decoration: none;

			@media only screen and (max-width: $mobile) {
				right: 0;
				left: 0;
				margin: auto;
			}

			&::before,
			&::after {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
				left: 14px;
				width: 2px;
				height: 20px;
				background: white;
				transform: rotate(45deg);
			}

			&::after {
				transform: rotate(-45deg);
			}
		}

		.text {
			color: white;
			text-align: left;
			line-height: 135%;
			margin-bottom: 15px;
			font-size: .95em;
		}

		.link {
			text-align: center;
			position: relative;
			z-index: 20;

			a {
				@include custom-button-2;
			}
		}
	}

	// bloque con formulario pequeño
	&.form-requiere-informacion {
		@include block-default;
		background-color: $base-blue-dark;
		padding-left: 10px;
		padding-right: 10px;
		padding-bottom: 0;

		.block-inner {
			@include site-mobile-padding;
			margin: 0 auto;
			max-width: 1075px;
		}

		.block__title,
		.entity {
			// Tablet y desktop
			@media only screen and (min-width: $mobile) and (max-width: $desktop) {
				width: $local-title-width; // variable al inicio del archivo
				margin: 0 auto;
				max-width: 590px;
			}
		}

		.block__title {
			@include raleway-regular;
			font-size: 1.4em;
			color: white;
			margin-bottom: 10px;

			br {
				@media only screen and (min-width: 420px) {
					display: none;
				}
			}

			@media only screen and (max-width: $tablet) {
				text-align: center;
			}

			@media only screen and (max-width: $mobile) {
				font-size: 1.4em;
				padding: 0;
			}
		}

		p {
			color: white;
			font-size: 1em;
			line-height: 100%;

			@media only screen and (max-width: $mobile) {
				text-align: center;
			}
		}
	}

	&#block-ana-suscripcion-block-form-suscripcion {
		@include block-default;
		background-color: $base-blue-dark;
		padding-top: 10px;
		padding-left: 10px;
		padding-right: 10px;

		.block-inner {
			@include site-mobile-padding;
			margin: 0 auto;
			max-width: 1075px;
			overflow: hidden;
			position: relative;

			// Tablet y desktop
			@media only screen and (min-width: $mobile) and (max-width: $desktop) {
				width: $local-title-width; // variable al inicio del archivo
				padding: 0;
				margin: 0 auto;
				max-width: 590px;
			}
		}

		label {
			display: none;
		}

		form {
			max-width: 366px;
			position: relative;
		}

		.form-item-email {
			@include inline-block;
			max-width: 295px;
			width: 100%;
			margin-right: 1px;

			.form-text {
				height: 31px;
				border-color: white;
				padding: 6px 10px 4px;
			}
		}

		.form-submit {
			@include custom-button-6;
			border: 0;
			border-left: 1px solid $base-blue-dark;
			position: absolute;
			top: 0;
			right: 0;
			height: 31px;
			text-transform: uppercase;
		}
	}

	&#block-views-traslado-avaluadores-block-1 {
		.block__title {
			@include page-subtitle;
		}
	}
}

&#block-views-view-groups-home-block {
	.view-header {
		@include wrapper-content;
	}
}

