.page-admin-create-user {
  .form-type-select {
    select {
      border: 1px solid $base-blue-dark;
    }
    .SumoSelect {
      width: 100%;
      .CaptionCont {
        width: 100%;
        border: 1px solid $base-blue-dark;
        label {
          margin: 5px 0;
        }
      }
    }
  }
  .form-text {
      padding: 5px 10px;
  }
  .form-item {
    margin-bottom: 20px;
  }
  .field-type-field-collection {
    .field-multiple-table {
      td {
        text-align: left;
      }
    }
  }

  .field-name-field-documentos-adicionales {
    text-align: left;
    .fieldset-description {
      padding: 10px 0;
    }
    .field-type-file {
      label {
        min-width: 75%;
      }
      .file-widget {
        display: block;
        float: right;
      }
    }
  }
}
