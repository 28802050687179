.form-type-checkbox {
	position: relative;

	label {
		@include inline-block;

		&.js {
			width: 20px;
			height: 20px;
			margin: 0 2px;
			vertical-align: middle;
		}
	}

	.form-checkbox {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
		width: 100%;
		height: 100%;

		& + label {
			position: relative;
			z-index: 5;

			span {
				display: inline-block;
				width: 20px;
				height: 20px;
				background: url('../img/sprites/Check-box-empty.png') left top no-repeat;
			}
		}

		&:checked + label {
			span {
				background-image: url('../img/sprites/Check-box-selected.png');
			}
		}
	}

}

