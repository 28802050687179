form {
	// Use this with js/scripts/ function inputFileStyles()
	.form-field-type-file {
		margin-bottom: 0; //10px;
	}

	.form-type-file, // era/1
	.form-field-type-file,
	.form-type-managed-file {
		position: relative;
		text-align: left;

		.label {
			width: 100%;
		}

		.tabledrag-handle {
			width: 25px;
			height: 25px;
			float: none;

			.handle {
				margin: 0 !important;
			}

			& + .file-widget {
				// display: block;
				width: 100%;

				> .file {
					display: flex;
					align-items: center;
					padding: 0;
					height: auto;
					min-height: 31px;

					> a {
						padding: 0;
						border: 0;
						text-align: left;
					}
				}
			}
		}

		.file-size,
		.description {
			display: none;
		}

		fieldset {
			border: 0;
			padding: 0;
			margin: 0;
		}

		legend {
			@include input-placeholder;
		}

		// css class
		.label {
			@include input-placeholder;
			@include inline-block;
			//padding: 10px 10px 10px 0;
			padding: 8px 10px 6px 0; // era/1
		}

		label {
			@include input-text-default;
			@include inline-block;
			cursor: pointer;
			//margin: 2px 0 ;
			margin-bottom: 0 !important; // evita que otros margenes de otros label lo sobreescriban
			background-color: white;
			padding: 8px 10px 3px;
			//padding: 9px 10px 8px;
			background-size: 15px auto;
			color: $base-blue-soft;
			padding-top: 6px;
			//font-size: 1em;
			//line-height: 100%;
			text-align: left;
			//line-height: 100%;
			//font-weight: 400;
			border: 1px solid $base-blue-soft;
			line-height: 100%; // 135%
		}

		.form-type-managed-file {
			// text-align: right;
		}

		label {
			padding: 8px 10px 6px; //8px 10px 3px;
			height: auto;
		}

		.file-widget {
			.form-file {
				opacity: 0;
				height: 30px;
				width: 75%;
				max-width: 75%;
				position: absolute;
				left: 0px;
				top: 0;

				&:focus + label {
					background-color: black;
					outline: 1px dotted #000;
					outline: -webkit-focus-ring-color auto 5px;
				}
			}

			.file {
				opacity: 1;
				height: 30px;
				// width: 75%;
				// max-width: 75%;
				// position: absolute;
				left: 0px;
				top: 0;
				display: inline-block;
				background: #fff;
				border: 1px solid $base-blue-soft;
				padding: 5px;
			}
		}

		.image-widget,
		.file-widget {
			@include inline-block;
			max-width: 90%;
		}

		.form-submit {
			@include input-text-default;
			@include custom-button-2;
			text-transform: none;
			font-size: 1em;
			line-height: 100%;
			border: 0;
			margin: 0; // 2px 0 0; fastidia en http://ana.seedlabs.co/group/1/node/add/sancion
		}

		// custom
		table {
			margin-bottom: 5px;
		}

		.tabledrag-toggle-weight-wrapper,
		.file-icon {
			display: none;
		}

		a.tabledrag-handle {
			padding: 0;
			margin: 0;
			min-width: auto;
			height: 29px;

			.handle {
				margin: -2px 5em;
			}
		}

		.draggable {
			.form-submit  {
				width: 30px;
				height: 30px;
				padding: 0;
				background-color: white;
				background-image: url(../img/sprites/Cerrar.png);
				background-repeat: no-repeat;
				background-position: 50%;
				text-indent: -9999px;
				text-align: left;
			}

			a {
				width: auto;
				max-width: none;
			}
		}

		div div table::before {
			display: none;
		}

		div div table thead {
			th,
			td {
				vertical-align: top;
				text-align: left;
			}

			th:first-child {
				text-align: left;
				width: 90%;
			}

			th:last-child {
				display: none;
			}
		}
	}

	.webform-component-file {
		//padding-top: 22px; // a) por el description con margen top negativo

		.form-managed-file {
			position: relative;
			z-index: 10;

			/*
			& + .description {
				margin-top: -75px; // b) Mueve el description arriba del input file

				br:first-child {
					margin-bottom: 60px; // c) Separa la primera línea del description de las siguientes
				}
			}*/
		}

		label {
			cursor: pointer;
			margin: 0;
			padding: 1em 3em 1em 2em;
			// agregando otros estilos
			background-color: #989a9b;
			font-size: 12px;
			padding: 13px 55px 12px 20px;
			background-size: 15px auto;
		}

		.form-file {
			&:focus + label {
				background-color: black;
				outline: 1px dotted #000;
				outline: -webkit-focus-ring-color auto 5px;
			}
		}

		.form-submit {
			display: none;
		}
	}

	.form-field-type-file .form-wrapper {
		> .fieldset-wrapper {
			display: flex;
			flex-wrap: wrap;
			padding-bottom: 20px;

			> * {
				width: 100%;
			}

			.fieldset-description {
				order: 10;
				color: #466c9e;
				margin-top: -10px;
				font-size: 12px;
			}
		}
	}
}
