.sidebar-menu {
	padding: 0 10px;
	position: relative;
	// Estos estilos deben estar también en block.scss #block-ana-custom-ana-top
	display: none;

	@media only screen and (min-width: $desktop) {
		display: block;
		padding: 10px 30px 0 10px; // padding-top no tiene que ser igual
	}
	// END

	.menu {
		margin: 0;
		padding: 0;
	}

	.menu__item {
		padding: 0;
		list-style: none;
		border-top: 2px solid white;
		position: relative;

		&:last-child {
			border-bottom: 2px solid white;
		}

		&:hover,
		&.is-active-trail {
			&::before {
				@include sidebar-button-hover;
			}
		}
	}

	.menu__link {
		color: white;
		text-transform: uppercase;
		//padding: 1em 0;
		height: 50px;
		font-size: 1em;
		text-decoration: none;
		display: block;
		line-height: 145%;
		vertical-align: middle;
		display: table-cell;

		&:hover,
		&.is-active-trail {
			color: $base-gray-soft-2;
		}

		@media only screen and (min-width: $desktop) {
			// padding-right: 110px; // lo saqué porque este se ve mal #dhtml_menu-671 en /eras
		}
	}
}
