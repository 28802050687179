// For firefox
.ff {
	&.page-raa-reports {
		.block-form {
			overflow: hidden;
		}

		.form-type-select {
			position: relative;

			&::after {
				content: "";
				width: 27px;
				height: 12px;
				position: absolute;
				top: 9px;
				right: -1px; //4px;
				z-index: 20;
				background: url("../img/sprites/Arrow-white-down.png") no-repeat 100% 50%;
			}
		}

		@mixin local-select-arrow($color) {
			.form-type-select {
				&::after {
					background-color: $color;
				}
			}
		}

		@mixin local-select-fix($value) {
			select {
				padding-left: $value;
				margin-left: -$value;
			}
			
			option {
				width: $value*2;
			}
		}
		
		.fieldset-wrapper {
			max-width: 250px;

			select {
				box-sizing: content-box;
				padding-right: 0 !important;
				cursor: pointer;
			}

			option {
				max-width: none;
				background-color: white;
			}
			
			.form-type-select {
				position: relative;
				z-index: 20;

				& + .form-type-select {
					z-index: 10;
				}
			}
		}

		#avaluadores-wrapper {
			@include local-select-arrow(#4a4a4a);

			.form-item-categorias {
				@include local-select-fix(260px);
			}

			.form-item-era {
				@include local-select-fix(210px);
			}
		}

		#numero-avaluadores-wrapper {
			@include local-select-arrow(#5c84b3);

			.form-item-era {
				@include local-select-fix(210px);
			}

			.form-item-estado {
				@include local-select-fix(120px);
			}
		}

		#tiempo-aprovacion-wrapper {
			@include local-select-arrow(#466c9e);
		}

		#solicitud-registro-wrapper {
			@include local-select-arrow(#f2b630);
		}

		#regimen-avaluadores-wrapper {
			@include local-select-arrow(#5c84b3);

			.form-type-select {
				&::after {
					right: 10px;
				}
			}

			select {
				padding-left: 200px;
				margin-left: -185px;
				background-color: transparent;
				width: 90%;
				max-width: none;
				padding-right: 0;
				box-sizing: content-box;
				background-image: none;

				@media only screen and (max-width: 610px) {
					padding-left: 40px;
					margin-left: -25px;
				}
			}

			option {
				background-color: white;
				width: 600px; //555px;
				max-width: none;

				@media only screen and (max-width: 610px) {
					width: 300px
				}
			}
		}
	}

	&.page-reports {
		form {
			.form-item {
				.form-select {
					min-width: 100px;
				}
			}
		}

		#avaluadores-form {
			.form-select {
				max-width: 170px;
			}
		}
	}
}
