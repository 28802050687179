.page-group-add,
.page-group-edit {
	@include manual-active-sidebar-item('#dhtml_menu-670');

	// * REFERENCIA: /sites/all/themes/zen_ana/img/diseno/mixins-form-comite.jpg
	@include cms-input-text('.form-type-textfield', 'label', '.form-text', 200px);
	//@include cms-input-text-datepicker('div.field-widget-date-popup', '.form-text');
	@include cms-input-text('.field-widget-options-select', 'label', '.form-select', 200px);
	@include cms-input-text-date('.field-type-datetime', 'legend', '.form-text', 200px);
	//@include cms-select('.field-widget-options-select', '.form-item', 'label', 'select', 500px, 200px);
	@include cms-centered-submit('.form-actions', '.form-submit');

	form {
		@include bordered-top;
	}
}
