.form-textarea-wrapper {
	.form-textarea {
		resize: vertical;
	}

	.grippie {
		display: none;
	}
}


