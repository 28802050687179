.form-type-select {
	// Estos estilos están en http://ana.seedlabs.co/era/1
	.form-select {
		background: url('../img/sprites/Arrow-blue-down.png') no-repeat 97% 50% white;
		appearance: none;
	    text-indent: 1px;
	    text-overflow: '';
		width: 100%;
		border: 0;
		color: $base-blue-soft;
		font-size: 1em;
		padding: 0 4px; //4px;
		border-radius: 0;
		height: 30px;
	}
}
