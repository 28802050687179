/*#ana-custom-certificate-form------------{
  .search-container{ 
    label{
      color: #a4a4a4;
      font-size: 14px;
      font-weight: 300;
    }
    #edit-search-container{
      font-size: 0px;
      .form-item,.form-submit{
        display: inline-block;
        vertical-align: top;
        font-size: 16px;
        max-width: 240px;
      }
      .form-submit{
        @include custom-button-2();
        padding: 0.18em 1.35em;
        font-size: 12px;
      }
    }
  }
  #result_certificate{
    table{
      &:before{
        display: none;
      }
      border:1px solid #797e7f;
      tr{
        td{
          text-align: left;
          padding: 8px;
          &:first-child{
            color:#5c84B3;
          }
        }
      }
    }
    .form-submit{
      @include custom-button-2();
      padding: 0.18em 1.35em;
      font-size: 12px;
      margin-top: 24px;
    }
  }
}
*/
