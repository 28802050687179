// REFERENCIA: /sites/all/themes/zen_ana/img/diseno/mixins-form-comite.jpg
//        URL: /admin/people/create
.page-admin-people-create {
	@include manual-active-sidebar-item('#dhtml_menu-733');
	@include cms-input-text-description('.form-type-textfield', 'label', '.form-text', 200px);
	@include cms-input-text-description('.form-type-password', 'label', '.form-text', 200px);
	@include cms-input-text-description('.form-type-password', 'label', '.form-text', 200px);
	@include cms-centered-submit('.form-actions', '.form-submit');
	//@include cms-input-text-datepicker('div.field-widget-date-popup', '.form-text');
	@include cms-input-text-date('div.field-widget-date-popup', 'legend', '.form-text', 200px);

	form {
		@include bordered-top;
	}

	.form-type-password {
		position: relative;
	}

	div.password-suggestions ul {
		margin: 0;
	}

	.confirm-parent, .password-parent {
		width: 100%;
	}

	.password-strength,
	div.password-confirm {
		@media only screen and (min-width: $mobile) {
			width: auto;
			position: absolute;
			left: 210px;
		}
	}

	.form-type-checkbox label {
		display: inline-block;
		vertical-align: middle;
	}

	.form-type-checkbox {
		label {
			margin: 0;
		}
	}
}
