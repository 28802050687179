// URL: avaluadores
// VIEW: view-admin-users
.table-layout-2 {
	// Estilos generales del campo de filtro
	@include table-layout-view-filter('.form-item');
	@include table-view-title-header-filters;
	@include table-edit-actions;
	@include clearfix;

	.linea {
	//	@include table-line;
	}

	// Texto debajo del título
	.view-header {
		color: $base-gray-dark;
	}

	// Campo de filtro
	.view-filters {
		text-align: left;
		max-width: 395px;

		form {
			display: block;
		}
	}

	/*
	.view-content {
		clear: both;
		width: 100% !important;
		padding-top: 15px;
	}*/
	
	.view-content {
		clear: both;
		width: 100% !important;
		//width: calc(100% + 24px) !important;
		margin-top: 0; //15px;
		//height: 520px;
		overflow: auto;
		float: left;
	}

	table {
		position: relative;

		/*
		&::after {
			content: '';
			background: $table-horizontal-color;
			width: 100%;
			height: 1px;
			position: absolute;
			left: 0;
			bottom: -10px;
			display: block;
		}*/

		thead {
			th {
				border-bottom: 0;
				padding: 0 0 13px;

				&:first-child {
					text-align: left;
				}
			}
		}

		tbody {
			tr {
				border-bottom: 0;

				&:hover {
					background-color: $base-gray-soft;
				}

				&:last-child {
					// border-bottom: 1px solid $table-border-color;
				}
			}

			td {
				@media only screen and (min-width: $tablet) {
					padding: 2px 0;
				}

				&:first-child {
					text-align: left;
				}
			}
		}
	}

	.views-field-name {
		//min-width: 250px;
		width: 30%;
		text-align: left;
	}

	.views-field-field-unique-code {
		//min-width: 125px;
		width: 15%;
	}

	.views-field-field-date {
		//min-width: 175px;
		width: 20%;
	}

	.views-field-nothing {
		//min-width: 200px;
		width: 23%;
	}

	.views-field-view-user {
		//min-width: 110px;
		width: 12%;
	}

	a {
		border: 0;

		&:hover {
			text-decoration: underline;
		}
	}
	
	@media only screen and (max-width: $tablet) {
		.jspPane {
			width: 100% !important;
		}
	}

	.review {
		display: none;
	}
}
