.header {
	border-bottom: 2px solid $base-blue-soft;

	.header-inner {
		@include wrapper-content;
		@include clearfix;
		padding: 20px $mobile-site-padding;

		@media only screen and (min-width: $mobile) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
	}

	.header__logo {
		max-width: 100px;

		@media only screen and (min-width: $mobile) {
			max-width: 140px;
		}

		@media only screen and (min-width: $desktop) {
			max-width: inherit;
		}
	}

	.header__region {
		float: right;
		clear: none;
	}

	.logo-in-header {
		max-width: 120px;

		@media only screen and (min-width: $mobile) {
			max-width: inherit;
		}
	}

	img {
		max-width: 100%;
		width: 100%;
	}
}
