@media only screen and (min-width: $tablet) {
/*	
	.page-traslado-avaluadores{
		.view-content {
			width: 100% !important;
		}
		
	  #block-views-traslado-avaluadores-block-1{
	    overflow: hidden;
	    h2.block__title{
	      color: #a4a4a4;
	      font-size: 14px;
	      font-weight: 300;
	    }
	  }
	  .view-id-traslado_avaluadores.view-display-id-page{
	    clear:both;
	    margin-top: 24px;
	    .view-header{
	      h3{
	        color:#5c84B3;
	        font-weight: normal;
	        font-size: 16px;
	        margin-bottom: 16px;
	      }
	    }
	    table{
	      border: 1px solid;
	      &:before{
	        display: none;
	      }
	      .views-field-field-firstname{
	        text-align: left;
	        padding-left: 8px;
	      }
	    }
	  }
	}
*/
}
