@charset "UTF-8";
* {
  box-sizing: border-box;
}

html {
  font-size: 100%;
  line-height: 1.5em;
  font-family: Verdana, Tahoma, "DejaVu Sans", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
}

@media print {
  html {
    font-size: 12pt;
  }
}

body {
  margin: 0;
  padding: 0;
  color: #000;
  background-color: #fff;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
[hidden] {
  display: none;
}

a {
  color: #0072b9;
  -webkit-text-decoration-skip: objects;
}

:visited {
  color: #003353;
}

/*
a:active {
  color: color(link-active);

  &:not(.button) {
    // Remove the gray background on active links in IE 10.
    background-color: transparent;
  }
}
*/
a:active,
a:hover {
  outline-width: 0;
}

@media print {
  :link,
  :visited {
    text-decoration: underline;
  }
  a[href]::after {
    content: " (" attr(href) ")";
    font-weight: normal;
    font-size: 16px;
    text-decoration: none;
  }
  a[href^='javascript:']::after,
  a[href^='#']::after {
    content: '';
  }
}

h1,
.header__site-name {
  font-size: 2rem;
  line-height: 3rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h2 {
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h3 {
  font-size: 1.25rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h4 {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h5 {
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h6 {
  font-size: 0.625rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  text-decoration: underline dotted;
}

@media print {
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
}

b,
strong {
  font-weight: inherit;
}

strong,
b {
  font-weight: bolder;
}

pre,
code,
kbd,
samp,
var {
  font-family: Menlo, "DejaVu Sans Mono", "Ubuntu Mono", Courier, "Courier New", monospace, sans-serif;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #fd0;
  color: #000;
}

small {
  font-size: 0.875rem;
}

sub,
sup {
  font-size: 0.625rem;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

.divider,
hr {
  margin: 1.5rem 0;
  border: 0;
  border-top: 1px solid #cccccc;
}

.divider > :first-child, hr > :first-child {
  margin-top: 1.5rem;
}

blockquote {
  margin: 1.5rem 2rem;
}

dl,
menu,
ol,
ul {
  margin: 1.5rem 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

dd {
  margin: 0 0 0 32px;
}

[dir="rtl"] dd {
  margin: 0 32px 0 0;
}

menu,
ol,
ul {
  padding: 0 0 0 32px;
}

[dir="rtl"] menu, [dir="rtl"]
ol, [dir="rtl"]
ul {
  padding: 0 32px 0 0;
}

figure {
  margin: 1.5rem 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

p,
pre {
  margin: 1.5rem 0;
}

img {
  border-style: none;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  box-sizing: border-box;
  max-width: 100%;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

input {
  overflow: visible;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button, [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.button,
button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -moz-appearance: button;
  -webkit-appearance: button;
}

.button,
button,
[type='button'],
[type='reset'],
[type='submit'] {
  display: inline-block;
  padding: 2px 6px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #000;
}

.button::-moz-focus-inner,
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.button:-moz-focusring,
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.button:hover, .button:focus, .button:active,
button:hover,
button:focus,
button:active,
[type='button']:hover,
[type='button']:focus,
[type='button']:active,
[type='reset']:hover,
[type='reset']:focus,
[type='reset']:active,
[type='submit']:hover,
[type='submit']:focus,
[type='submit']:active {
  text-decoration: none;
  color: #000;
}

[disabled].button,
button[disabled],
[disabled][type='button'],
[disabled][type='reset'],
[disabled][type='submit'] {
  cursor: default;
  color: #999999;
}

[disabled].button:hover,
button[disabled]:hover,
[disabled][type='button']:hover,
[disabled][type='reset']:hover,
[disabled][type='submit']:hover, [disabled].button:focus,
button[disabled]:focus,
[disabled][type='button']:focus,
[disabled][type='reset']:focus,
[disabled][type='submit']:focus, [disabled].button:active,
button[disabled]:active,
[disabled][type='button']:active,
[disabled][type='reset']:active,
[disabled][type='submit']:active {
  color: #999999;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

fieldset {
  padding: 0.525rem 0.9375rem 0.975rem;
  border: 1px solid #cccccc;
  margin: 0 2px;
}

legend {
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  white-space: normal;
  color: inherit;
  margin-left: -5px;
  padding: 0 5px;
}

label {
  display: block;
  font-weight: bold;
}

optgroup {
  font-weight: bold;
}

textarea {
  overflow: auto;
}

table {
  margin: 1.5rem 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

td,
th {
  padding: 0;
}

.layout-3col {
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 0;
  padding-right: 0;
}

.layout-3col:before {
  content: "";
  display: table;
}

.layout-3col:after {
  content: "";
  display: table;
  clear: both;
}

.layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar, .layout-3col__col-1, .layout-3col__col-2, .layout-3col__col-3, .layout-3col__col-4, .layout-3col__col-x {
  clear: both;
  padding-left: 20px;
  padding-right: 20px;
  float: left;
  width: 100%;
  margin-left: 0%;
  margin-right: -100%;
}

[dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x {
  float: right;
  margin-right: 0%;
  margin-left: -100%;
}

@media (min-width: 777px) {
  .layout-3col {
    margin-left: -12px;
    margin-right: -13px;
    padding-left: 0;
    padding-right: 0;
  }
  .layout-3col:before {
    content: "";
    display: table;
  }
  .layout-3col:after {
    content: "";
    display: table;
    clear: both;
  }
  [dir="rtl"] .layout-3col {
    margin-left: -13px;
    margin-right: -12px;
  }
  .layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__first-left-sidebar, .layout-3col__col-1, .layout-3col__col-3, .layout-3col__col-x:nth-child(2n + 1) {
    float: left;
    width: 50%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__second-left-sidebar, .layout-3col__col-2, .layout-3col__col-4, .layout-3col__col-x:nth-child(2n) {
    clear: none;
    float: left;
    width: 50%;
    margin-left: 50%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    float: right;
    margin-right: 50%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    padding-left: 13px;
    padding-right: 12px;
  }
}

@media (min-width: 999px) {
  .layout-3col__full {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__full {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__left-content {
    float: left;
    width: 66.66667%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__left-content {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__right-content {
    float: left;
    width: 66.66667%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__right-content {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__left-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    float: left;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  .layout-3col__right-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    float: left;
    margin-left: 0%;
    margin-right: -100%;
  }
  .layout-3col__col-1, .layout-3col__col-x:nth-child(3n+1) {
    clear: both;
    float: left;
    width: 33.33333%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-x:nth-child(3n+1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__col-2, .layout-3col__col-x:nth-child(3n+2) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-x:nth-child(3n+2) {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__col-3, .layout-3col__col-x:nth-child(3n) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(3n) {
    float: right;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  .layout-3col__col-4 {
    display: none;
  }
}

.layout-3col__grid-item-container {
  padding-left: 0;
  padding-right: 0;
}

.layout-3col__grid-item-container:before {
  content: "";
  display: table;
}

.layout-3col__grid-item-container:after {
  content: "";
  display: table;
  clear: both;
}

.layout-center {
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  max-width: 1165px;
}

@media (min-width: 777px) {
  .layout-center {
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-center {
    padding-left: 13px;
    padding-right: 12px;
  }
}

.layout-center--shared-grid,
.layout-center.layout-3col {
  padding-left: 0;
  padding-right: 0;
}

.layout-swap {
  position: relative;
}

@media (min-width: 555px) {
  .layout-swap {
    padding-top: 48px;
  }
}

@media (min-width: 555px) {
  .layout-swap__top {
    position: absolute;
    top: 0;
    height: 48px;
    width: 100%;
  }
}

.jspScrollable,
.jspContainer {
  width: 100% !important;
}

.jspPane {
  width: calc(100% - 24px) !important;
}

.jspVerticalBar {
  width: 5px;
}

.jspTrack {
  background: #f1f0f2;
}

.jspDrag {
  background: #5b83b7;
}

@media only screen and (max-width: 480px) {
  .jspContainer {
    height: auto !important;
  }
  .jspPane {
    top: auto !important;
    position: relative !important;
    width: 100% !important;
  }
  .jspVerticalBar {
    display: none !important;
  }
}

.container-inline-date .form-item, .container-inline-date .form-item input {
  background: none;
}

.field-collection-container .field-items .field-item {
  margin: 0;
}

.field-collection-view {
  border: 0;
}

.container-inline-date .form-item .form-item,
.container-inline-date .date-padding,
.field-collection-view .entity-field-collection-item {
  float: none;
  width: auto;
  display: block;
}

.container-inline-date .form-item, .container-inline-date .form-item input {
  display: block;
}

.field-collection-container,
.field-collection-view {
  border: 0;
  margin: 0;
  padding: 0;
}

#autologout-cache-check {
  margin: 0;
  display: block;
}

.hamburger-button {
  width: 40px;
  height: 39px;
  position: absolute;
  overflow: hidden;
  margin: 0 0 0 10px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  border: 1px solid white;
  z-index: 10;
}

@media only screen and (min-width: 960px) {
  .hamburger-button {
    display: none;
  }
}

.hamburger-button span {
  display: block;
  position: absolute;
  width: 24px;
  height: 4px;
  background: white;
  opacity: 1;
  left: 7px;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.hamburger-button span:nth-child(1) {
  top: 8px;
}

.hamburger-button span:nth-child(2) {
  top: 17px;
}

.hamburger-button span:nth-child(3) {
  top: 26px;
}

@media only screen and (min-width: 480px) and (max-width: 800px) {
  .hamburger-button span {
    /*width: 30px;

				&:nth-child(1) {
			  		top: 14px;
				}

				&:nth-child(2) {
			  		top: 24px;
				}

				&:nth-child(3) {
			  		top: 34px;
				}*/
  }
}

@media only screen and (max-width: 480px) {
  .hamburger-button span {
    /*&:nth-child(1) {
			  		top: 11px;
				}

				&:nth-child(2) {
			  		top: 21px;
				}

				&:nth-child(3) {
			  		top: 31px;
				}*/
  }
}

.hamburger-button.open span:nth-child(1) {
  top: 17px;
  transform: rotate(135deg);
}

.hamburger-button.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.hamburger-button.open span:nth-child(3) {
  top: 17px;
  transform: rotate(-135deg);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Raleway';
  font-weight: 400;
  margin: 0 0 10px;
  line-height: 125%;
}

h1 {
  font-family: 'Raleway';
  font-weight: 400;
  font-size: 2.40em;
  line-height: 100%;
  color: #466c9e;
  text-align: left;
}

@media only screen and (max-width: 960px) {
  h1 {
    font-size: 1.62em;
    padding: 0;
    font-weight: 400;
    line-height: 140%;
  }
}

p {
  margin: 0 0 10px;
  font-size: 1em;
}

/*
html{
	min-height:100%;
	height:100%;
}
*/
body {
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: .5px;
  color: #4a4a4a;
  background-size: cover, cover;
}

body > .layout-center {
  padding: 0;
  max-width: inherit;
  position: relative;
}

body > .layout-center > .layout-3col {
  margin: 0;
}

body > .layout-center > .layout-3col > .layout-3col__full {
  margin: 0;
  padding: 0;
}

body > .layout-center > .layout-swap > .layout-3col__full {
  padding: 0;
}

::placeholder {
  color: #6e91c0;
  opacity: 1;
}

.layout-swap {
  padding: 0;
}

.layout-3col__left-sidebar {
  padding-left: 0;
  z-index: 20;
}

.layout-3col__right-content {
  padding-top: 40px;
}

@media only screen and (max-width: 960px) {
  .layout-3col__right-content {
    padding-top: 87px;
  }
}

@media only screen and (max-width: 480px) {
  .layout-3col__right-content {
    padding-top: 74px;
  }
}

.layout-3col__right-content > h1 {
  font-family: 'Raleway';
  font-weight: 400;
  font-size: 2.40em;
  line-height: 100%;
  color: #466c9e;
  text-align: left;
}

@media only screen and (max-width: 960px) {
  .layout-3col__right-content > h1 {
    font-size: 1.62em;
    padding: 0;
    font-weight: 400;
    line-height: 140%;
  }
}

.layout-3col__right-content {
  position: relative;
}

.layout-3col__right-content::before {
  content: "";
  background: #5a83b6;
  position: absolute;
  right: 100%;
  bottom: 0;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 800px) {
  .layout-3col__right-content {
    min-height: 600px;
  }
}

.layout-swap__top {
  display: none;
}

.view-header p {
  color: #797e7f;
}

.layout-swap {
  position: static;
}

.layout-3col__right-content {
  width: 100%;
  float: left;
  clear: left;
  margin-right: -100%;
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (max-width: 480px) {
  .layout-3col__right-content {
    overflow: hidden;
  }
}

@media only screen and (min-width: 480px) and (max-width: 960px) {
  .layout-3col__right-content {
    width: 72%;
    margin-left: 28%;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (min-width: 960px) {
  .layout-3col__right-content {
    width: 72%;
    margin-left: 28%;
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (max-width: 960px) {
  .layout-3col__right-content {
    margin-left: 0;
    width: 100%;
  }
}

aside.layout-3col__left-sidebar {
  float: right;
  clear: right;
  padding-left: 0;
  padding-right: 0;
  margin-left: -100%;
  background-color: #5a83b6;
  overflow: hidden;
  padding-top: 20px;
  box-sizing: border-box;
}

@media only screen and (min-width: 480px) and (max-width: 960px) {
  aside.layout-3col__left-sidebar {
    width: 28%;
    margin-right: 72%;
    display: block;
  }
}

@media only screen and (min-width: 960px) {
  aside.layout-3col__left-sidebar {
    width: 28%;
    margin-right: 72%;
    display: block;
  }
}

@media only screen and (min-width: 960px) {
  aside.layout-3col__left-sidebar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    height: 100vh;
    margin: 0;
  }
}

@media only screen and (max-width: 960px) {
  aside.layout-3col__left-sidebar {
    position: fixed;
    margin: 0;
    clear: none;
    float: none;
    display: block;
    padding-top: 10px;
    padding-bottom: 1px;
    width: 100%;
    min-height: 60px;
  }
}

.messages {
  display: none;
}

.main-content > .messages {
  display: block;
}

.sticky-header {
  display: none !important;
}

.form-actions.moved-with-js {
  width: 100%;
  float: left;
  clear: left;
  padding: 18px 0 30px;
  background-color: white;
  position: relative;
  z-index: 20;
}

.form-actions.moved-with-js .form-submit {
  margin-right: 18px;
}

@media only screen and (max-width: 800px) {
  .form-actions.moved-with-js {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 18px;
  }
  .form-actions.moved-with-js .form-submit {
    margin-right: 0;
    width: 33.33%;
  }
}

.main-content::before {
  content: '';
  display: table;
}

.main-content::after {
  content: '';
  display: table;
  clear: both;
}

.linea,
.linea-1,
.linea-2 {
  margin: 10px 0;
  width: 100%;
  height: 1px;
  float: left;
  clear: both;
  background: #797E7F;
}

@media only screen and (max-width: 800px) {
  .linea-table.linea-1 {
    margin-bottom: 0;
  }
  .linea-table.linea-2 {
    margin-top: 0;
  }
}

.logo-raa {
  text-align: center;
}

.subtitle {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  color: #a4a4a4;
  margin-bottom: 11px;
  margin-top: -8px;
  padding-bottom: 19px !important;
}

@media only screen and (max-width: 480px) {
  .subtitle {
    padding-bottom: 9px !important;
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 960px) {
  aside.layout-3col__left-sidebar {
    height: auto !important;
  }
}

aside.content-toogle {
  position: fixed;
  top: 0px;
  right: -300px;
  width: 300px;
  height: 100%;
  background: #fff;
  padding: 20px 16px;
  font-size: 13px;
  transition: .3s ease-out all;
  box-shadow: -12px 5px 28px -13px rgba(0, 0, 0, 0.4);
  /*
	.view-sanciones-avaluador{
		font-size: 12px;
		.views-field-field-date{
			.views-label{
				color:$base-blue-soft;
			}
			.field-content{
				display: inline-block;
				color:$base-gray-dark;
				font-weight: 200;
			}
		}
		.views-field-body{
			line-height: 1.2;
			font-weight: 200;
			color:$base-gray-dark;
		}
	}
	*/
}

.sidebar-content-visible aside.content-toogle {
  right: 0;
  z-index: 14;
}

aside.content-toogle h4 {
  color: #5a83b6;
  text-align: center;
}

aside.content-toogle .top {
  color: #5a83b6;
  font-size: 12px;
}

aside.content-toogle .close {
  text-align: right;
  font-size: 20px;
  padding-top: 10px;
}

aside.content-toogle .close a {
  color: #5a83b6;
  cursor: pointer;
  text-decoration: none;
}

.description {
  font-size: 12px;
  color: #797e7f;
}

.skip-link__wrapper {
  display: none;
}

.messages {
  margin: 0 auto;
  max-width: 1240px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto !important;
  margin-right: auto !important;
}

@media only screen and (min-width: 480px) {
  .messages {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.form-cutom-ajax {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem 0;
}

.form-cutom-ajax .button {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  font-size: 1.1em;
  box-shadow: none;
  color: #5a83b6;
  border: 1px solid #5a83b6;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0;
  font-size: 1em;
  font-weight: 400;
  padding: 0.7em 1.5em;
  margin: 1rem 0;
}

.form-cutom-ajax label {
  margin-left: 1rem;
}

div.form-item {
  margin: 0 0 15px;
}

div.form-item label {
  margin-bottom: 14px;
  line-height: 100%;
}

.form-text {
  width: 100%;
  border: 1px solid #5a83b6;
  padding: 1px 10px 3px;
  font-weight: 100;
  border-radius: 0;
  box-shadow: none;
}

.form-actions .form-submit {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  font-size: 1.1em;
  box-shadow: none;
  color: #5a83b6;
  border: 1px solid #5a83b6;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0;
}

.form-actions .form-submit:hover {
  color: #446a99;
  border-color: #446a99;
}

.form-type-checkbox {
  position: relative;
}

.form-type-checkbox label {
  display: inline-block;
  vertical-align: top;
}

.form-type-checkbox label.js {
  width: 20px;
  height: 20px;
  margin: 0 2px;
  vertical-align: middle;
}

.form-type-checkbox .form-checkbox {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
}

.form-type-checkbox .form-checkbox + label {
  position: relative;
  z-index: 5;
}

.form-type-checkbox .form-checkbox + label span {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("../img/sprites/Check-box-empty.png") left top no-repeat;
}

.form-type-checkbox .form-checkbox:checked + label span {
  background-image: url("../img/sprites/Check-box-selected.png");
}

form .form-field-type-file {
  margin-bottom: 0;
}

form .form-type-file,
form .form-field-type-file,
form .form-type-managed-file {
  position: relative;
  text-align: left;
}

form .form-type-file .label,
form .form-field-type-file .label,
form .form-type-managed-file .label {
  width: 100%;
}

form .form-type-file .tabledrag-handle,
form .form-field-type-file .tabledrag-handle,
form .form-type-managed-file .tabledrag-handle {
  width: 25px;
  height: 25px;
  float: none;
}

form .form-type-file .tabledrag-handle .handle,
form .form-field-type-file .tabledrag-handle .handle,
form .form-type-managed-file .tabledrag-handle .handle {
  margin: 0 !important;
}

form .form-type-file .tabledrag-handle + .file-widget,
form .form-field-type-file .tabledrag-handle + .file-widget,
form .form-type-managed-file .tabledrag-handle + .file-widget {
  width: 100%;
}

form .form-type-file .tabledrag-handle + .file-widget > .file,
form .form-field-type-file .tabledrag-handle + .file-widget > .file,
form .form-type-managed-file .tabledrag-handle + .file-widget > .file {
  display: flex;
  align-items: center;
  padding: 0;
  height: auto;
  min-height: 31px;
}

form .form-type-file .tabledrag-handle + .file-widget > .file > a,
form .form-field-type-file .tabledrag-handle + .file-widget > .file > a,
form .form-type-managed-file .tabledrag-handle + .file-widget > .file > a {
  padding: 0;
  border: 0;
  text-align: left;
}

form .form-type-file .file-size,
form .form-type-file .description,
form .form-field-type-file .file-size,
form .form-field-type-file .description,
form .form-type-managed-file .file-size,
form .form-type-managed-file .description {
  display: none;
}

form .form-type-file fieldset,
form .form-field-type-file fieldset,
form .form-type-managed-file fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

form .form-type-file legend,
form .form-field-type-file legend,
form .form-type-managed-file legend {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
}

form .form-type-file .label,
form .form-field-type-file .label,
form .form-type-managed-file .label {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  display: inline-block;
  vertical-align: top;
  padding: 8px 10px 6px 0;
}

form .form-type-file label,
form .form-field-type-file label,
form .form-type-managed-file label {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  height: 30px;
  padding: 1px 10px 3px;
  border: 1px solid #d8d8d9;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  margin-bottom: 0 !important;
  background-color: white;
  padding: 8px 10px 3px;
  background-size: 15px auto;
  color: #5a83b6;
  padding-top: 6px;
  text-align: left;
  border: 1px solid #5a83b6;
  line-height: 100%;
}

form .form-type-file label,
form .form-field-type-file label,
form .form-type-managed-file label {
  padding: 8px 10px 6px;
  height: auto;
}

form .form-type-file .file-widget .form-file,
form .form-field-type-file .file-widget .form-file,
form .form-type-managed-file .file-widget .form-file {
  opacity: 0;
  height: 30px;
  width: 75%;
  max-width: 75%;
  position: absolute;
  left: 0px;
  top: 0;
}

form .form-type-file .file-widget .form-file:focus + label,
form .form-field-type-file .file-widget .form-file:focus + label,
form .form-type-managed-file .file-widget .form-file:focus + label {
  background-color: black;
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

form .form-type-file .file-widget .file,
form .form-field-type-file .file-widget .file,
form .form-type-managed-file .file-widget .file {
  opacity: 1;
  height: 30px;
  left: 0px;
  top: 0;
  display: inline-block;
  background: #fff;
  border: 1px solid #5a83b6;
  padding: 5px;
}

form .form-type-file .image-widget,
form .form-type-file .file-widget,
form .form-field-type-file .image-widget,
form .form-field-type-file .file-widget,
form .form-type-managed-file .image-widget,
form .form-type-managed-file .file-widget {
  display: inline-block;
  vertical-align: top;
  max-width: 90%;
}

form .form-type-file .form-submit,
form .form-field-type-file .form-submit,
form .form-type-managed-file .form-submit {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  height: 30px;
  padding: 1px 10px 3px;
  border: 1px solid #d8d8d9;
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  font-size: 1.1em;
  box-shadow: none;
  color: #5a83b6;
  border: 1px solid #5a83b6;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0;
  background-color: #5a83b6;
  border: 1px solid white;
  color: white;
  text-transform: none;
  font-size: 1em;
  line-height: 100%;
  border: 0;
  margin: 0;
}

form .form-type-file .form-submit:hover,
form .form-field-type-file .form-submit:hover,
form .form-type-managed-file .form-submit:hover {
  color: #446a99;
  border-color: #446a99;
}

form .form-type-file .form-submit:hover,
form .form-field-type-file .form-submit:hover,
form .form-type-managed-file .form-submit:hover {
  background-color: #446a99;
  color: white;
  border-color: white;
}

form .form-type-file table,
form .form-field-type-file table,
form .form-type-managed-file table {
  margin-bottom: 5px;
}

form .form-type-file .tabledrag-toggle-weight-wrapper,
form .form-type-file .file-icon,
form .form-field-type-file .tabledrag-toggle-weight-wrapper,
form .form-field-type-file .file-icon,
form .form-type-managed-file .tabledrag-toggle-weight-wrapper,
form .form-type-managed-file .file-icon {
  display: none;
}

form .form-type-file a.tabledrag-handle,
form .form-field-type-file a.tabledrag-handle,
form .form-type-managed-file a.tabledrag-handle {
  padding: 0;
  margin: 0;
  min-width: auto;
  height: 29px;
}

form .form-type-file a.tabledrag-handle .handle,
form .form-field-type-file a.tabledrag-handle .handle,
form .form-type-managed-file a.tabledrag-handle .handle {
  margin: -2px 5em;
}

form .form-type-file .draggable .form-submit,
form .form-field-type-file .draggable .form-submit,
form .form-type-managed-file .draggable .form-submit {
  width: 30px;
  height: 30px;
  padding: 0;
  background-color: white;
  background-image: url(../img/sprites/Cerrar.png);
  background-repeat: no-repeat;
  background-position: 50%;
  text-indent: -9999px;
  text-align: left;
}

form .form-type-file .draggable a,
form .form-field-type-file .draggable a,
form .form-type-managed-file .draggable a {
  width: auto;
  max-width: none;
}

form .form-type-file div div table::before,
form .form-field-type-file div div table::before,
form .form-type-managed-file div div table::before {
  display: none;
}

form .form-type-file div div table thead th,
form .form-type-file div div table thead td,
form .form-field-type-file div div table thead th,
form .form-field-type-file div div table thead td,
form .form-type-managed-file div div table thead th,
form .form-type-managed-file div div table thead td {
  vertical-align: top;
  text-align: left;
}

form .form-type-file div div table thead th:first-child,
form .form-field-type-file div div table thead th:first-child,
form .form-type-managed-file div div table thead th:first-child {
  text-align: left;
  width: 90%;
}

form .form-type-file div div table thead th:last-child,
form .form-field-type-file div div table thead th:last-child,
form .form-type-managed-file div div table thead th:last-child {
  display: none;
}

form .webform-component-file .form-managed-file {
  position: relative;
  z-index: 10;
  /*
			& + .description {
				margin-top: -75px; // b) Mueve el description arriba del input file

				br:first-child {
					margin-bottom: 60px; // c) Separa la primera línea del description de las siguientes
				}
			}*/
}

form .webform-component-file label {
  cursor: pointer;
  margin: 0;
  padding: 1em 3em 1em 2em;
  background-color: #989a9b;
  font-size: 12px;
  padding: 13px 55px 12px 20px;
  background-size: 15px auto;
}

form .webform-component-file .form-file:focus + label {
  background-color: black;
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

form .webform-component-file .form-submit {
  display: none;
}

form .form-field-type-file .form-wrapper > .fieldset-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
}

form .form-field-type-file .form-wrapper > .fieldset-wrapper > * {
  width: 100%;
}

form .form-field-type-file .form-wrapper > .fieldset-wrapper .fieldset-description {
  order: 10;
  color: #466c9e;
  margin-top: -10px;
  font-size: 12px;
}

.form-type-select .form-select {
  background: url("../img/sprites/Arrow-blue-down.png") no-repeat 97% 50% white;
  appearance: none;
  text-indent: 1px;
  text-overflow: '';
  width: 100%;
  border: 0;
  color: #5a83b6;
  font-size: 1em;
  padding: 0 4px;
  border-radius: 0;
  height: 30px;
}

.form-textarea-wrapper .form-textarea {
  resize: vertical;
}

.form-textarea-wrapper .grippie {
  display: none;
}

.block.block-encabezado {
  color: #a4a4a4;
}

.block.block-encabezado .contextual-links-wrapper {
  right: 100px;
}

.block.block-encabezado .block__title {
  font-family: 'Raleway';
  font-weight: 400;
  font-size: 2.40em;
  line-height: 100%;
  color: #466c9e;
  text-align: left;
}

@media only screen and (max-width: 960px) {
  .block.block-encabezado .block__title {
    font-size: 1.62em;
    padding: 0;
    font-weight: 400;
    line-height: 140%;
  }
}

.block.block-encabezado .field-name-field-title-descripcion {
  line-height: 145%;
  margin-top: -5px;
  margin-bottom: 10px;
  font-size: .9em;
  font-weight: 400;
  color: #a4a4a4;
}

@media only screen and (max-width: 960px) {
  .block.block-encabezado .field-name-field-title-descripcion {
    margin-top: -12px;
  }
}

.block.block-encabezado .header-results {
  margin: -9px 0 8px;
  font-size: .85em;
  font-weight: 300;
}

.block.block-encabezado .field-name-field-link a {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  font-size: 1.1em;
  box-shadow: none;
  color: #5a83b6;
  border: 1px solid #5a83b6;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0;
  font-size: 1em;
  font-weight: 400;
  padding: 0.7em 1.5em;
}

.block.block-encabezado .field-name-field-link a:hover {
  color: #446a99;
  border-color: #446a99;
}

@media only screen and (max-width: 960px) {
  .block.block-encabezado .field-name-field-link {
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

.block.block-encabezado-altern {
  margin-top: 40px;
  padding-top: 22px;
  position: static;
}

@media only screen and (max-width: 960px) {
  .block.block-encabezado-altern {
    padding-top: 10px;
  }
}

@media only screen and (max-width: 480px) {
  .block.block-encabezado-altern {
    padding-top: 9px;
  }
}

.block.block-encabezado-altern .field-name-field-link {
  position: absolute;
  top: 0;
  left: 0;
}

.block .block__title {
  color: #5a83b6;
}

.block.logo-ana .field-name-field-image img {
  max-width: 95px;
  display: block;
  margin: 0 auto;
}

@media only screen and (min-width: 960px) {
  .block.logo-ana .field-name-field-image img {
    max-width: 150px;
  }
}

.block.logos-footer {
  text-align: center;
  padding: 30px 0 20px;
  border-bottom: 1px solid #d8d8d9;
  max-width: 1275px;
  margin: 0 auto;
}

.block.logos-footer .field-item {
  display: inline-block;
  vertical-align: top;
  width: 77px;
  margin: 0 10px 10px;
  vertical-align: middle;
}

.block.logos-footer .field-item .field-item {
  width: auto;
  margin: 0;
}

@media only screen and (min-width: 480px) {
  .block.logos-footer .field-item {
    width: 125px;
    margin: 0 5px 10px;
  }
}

.block.logos-footer img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.block.copyright {
  padding: 13px 0 16px;
}

.block.copyright p {
  text-align: center;
  margin: 0;
  font-size: .85em;
}

.block#block-ana-custom-ana-top {
  font-size: .9em;
}

.block#block-ana-custom-ana-top::before {
  content: '';
  display: table;
}

.block#block-ana-custom-ana-top::after {
  content: '';
  display: table;
  clear: both;
}

@media only screen and (max-width: 800px) {
  .block#block-ana-custom-ana-top.show-button img {
    display: none;
  }
  .block#block-ana-custom-ana-top.show-button .block-encabezado {
    display: block;
  }
}

.block#block-ana-custom-ana-top .logo-era {
  text-align: center;
}

.block#block-ana-custom-ana-top .user_image {
  float: left;
  margin-right: 10px;
}

.block#block-ana-custom-ana-top img {
  max-width: 95px;
}

@media only screen and (min-width: 960px) {
  .block#block-ana-custom-ana-top img {
    max-width: 155px;
    height: auto;
  }
}

.block#block-ana-custom-ana-top .user {
  display: none;
}

@media only screen and (min-width: 960px) {
  .block#block-ana-custom-ana-top .user {
    display: block;
    padding: 20px 30px 0 10px;
  }
}

.block#block-ana-custom-ana-top .username {
  color: white;
}

.block#block-ana-custom-ana-top a {
  color: #e7dd00;
  text-decoration: none;
}

.block#block-ana-custom-ana-top .block-encabezado {
  display: none;
  padding-bottom: 10px;
}

.block#block-ana-custom-ana-top .block-encabezado a {
  border-color: white;
  color: white;
}

.block#block-ana-custom-ana-top .userlogout {
  float: right;
}

.block.block-presentacion {
  background-color: #5a83b6;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-bottom: 25px;
  overflow: hidden;
  background-color: #466c9e;
}

@media only screen and (max-width: 480px) {
  .block.block-presentacion {
    padding-top: 21px;
  }
}

.block.block-presentacion .block-inner {
  margin: 0 auto;
  max-width: 1075px;
}

@media only screen and (max-width: 960px) {
  .block.block-presentacion .block-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.block.block-presentacion .block__title {
  font-size: 1.3em;
  margin-bottom: 12px;
  font-family: 'Raleway';
  font-weight: 400;
  text-align: center;
  color: white;
  text-transform: uppercase;
  position: relative;
  font-weight: 400;
}

@media only screen and (max-width: 480px) {
  .block.block-presentacion .block__title {
    font-size: 1.6em;
    margin-bottom: 18px;
  }
}

.block.block-presentacion .block__title em {
  font-style: normal;
}

.block.block-presentacion p {
  color: white;
  margin: 0;
  font-size: 1.1em;
  line-height: 142%;
  font-weight: 400;
}

.block.block-presentacion p a {
  color: white;
}

@media only screen and (min-width: 666px) {
  .block.block-presentacion {
    padding-top: 45px;
    padding-bottom: 60px;
  }
  .block.block-presentacion .block__title {
    font-size: 1.3em;
    margin-bottom: 12px;
    font-family: 'Raleway';
    font-weight: 400;
    font-family: 'open sans';
    font-weight: 700;
    margin-right: 55px;
    margin-bottom: 0;
    width: 240px;
    float: left;
    font-size: 58px;
    text-align: left;
    line-height: 132%;
    top: auto;
    top: -5px;
  }
  .block.block-presentacion .block__title em {
    font-size: 94px;
  }
  .block.block-presentacion .entity {
    overflow: hidden;
  }
}

@media only screen and (min-width: 960px) {
  .block.block-presentacion p {
    font-size: 1.3em;
    line-height: 145%;
  }
}

.block.block-presentacion.block-title-size-2 {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0;
}

@media only screen and (min-width: 480px) and (max-width: 960px) {
  .block.block-presentacion.block-title-size-2 {
    padding-top: 27px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 960px) {
  .block.block-presentacion.block-title-size-2 .block__title,
  .block.block-presentacion.block-title-size-2 .entity {
    max-width: 590px;
    margin: 0 auto;
  }
}

.block.block-presentacion.block-title-size-2 .block__title {
  font-family: "Raleway";
}

.block.block-presentacion.block-title-size-2 .block__title em {
  font-size: inherit;
}

@media only screen and (min-width: 480px) and (max-width: 960px) {
  .block.block-presentacion.block-title-size-2 .block__title {
    font-size: 2.2em;
    line-height: 125%;
  }
}

@media only screen and (max-width: 960px) {
  .block.block-presentacion.block-title-size-2 .block__title {
    width: auto;
    float: none;
    text-align: center;
  }
}

@media only screen and (min-width: 960px) {
  .block.block-presentacion.block-title-size-2 .block__title {
    width: 390px;
    font-size: 35px;
    line-height: 145%;
  }
  .block.block-presentacion.block-title-size-2 .block__title em {
    font-size: 63px;
  }
}

.block.centered-pictures {
  padding-top: 15px;
}

.block.centered-pictures .view-header {
  text-align: center;
  padding-bottom: 20px;
}

@media only screen and (min-width: 480px) and (max-width: 960px) {
  .block.centered-pictures {
    padding-top: 35px;
  }
}

@media only screen and (min-width: 960px) {
  .block.centered-pictures {
    padding-top: 60px;
  }
}

.block.centered-pictures .view-content {
  text-align: center;
}

@media only screen and (max-width: 480px) {
  .block.centered-pictures .view-content {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.block.centered-pictures .views-row {
  display: inline-block;
  vertical-align: top;
  max-width: 370px;
  width: 100%;
  height: 220px;
  margin-bottom: 10px;
  border: 1px solid #969696;
  position: relative;
}

@media only screen and (min-width: 480px) and (max-width: 960px) {
  .block.centered-pictures .views-row {
    height: 165px;
    max-width: 220px;
    margin: 0 13px 28px;
  }
}

@media only screen and (min-width: 960px) {
  .block.centered-pictures .views-row {
    height: 270px;
    margin: 0 22px 44px;
  }
}

.block.centered-pictures .views-row-inner {
  display: table;
  height: 100%;
  width: 100%;
}

.block.centered-pictures .views-row-inner > * {
  display: table-cell;
  vertical-align: middle;
}

.block.centered-pictures .views-field-field-entity-logo {
  cursor: pointer;
}

.block.centered-pictures .active-js .views-field-field-status {
  opacity: 1;
  visibility: visible;
  bottom: 80%;
}

.block.centered-pictures .views-field-field-status {
  box-shadow: 2px 0px 4px #466c9e;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: 60%;
  left: -1px;
  background-color: #5a83b6;
  border: 1px solid white;
  width: auto;
  padding: 19px 24px 20px;
  transition: .6s ease-out all;
  z-index: 20;
}

@media only screen and (min-width: 480px) {
  .block.centered-pictures .views-field-field-status {
    left: -86px;
    width: 390px;
    padding-top: 24px;
  }
}

@media only screen and (min-width: 960px) {
  .block.centered-pictures .views-field-field-status {
    left: -11px;
  }
}

.block.centered-pictures .views-field-field-status::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100px;
  height: 30px;
  z-index: 20;
  background-color: #5a83b6;
}

.block.centered-pictures .views-field-field-status::after {
  box-shadow: 2px 0px 4px #466c9e;
  content: "";
  width: 30px;
  height: 30px;
  transform: rotate(45deg);
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #5a83b6;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  z-index: 10;
}

.block.centered-pictures .close {
  background-color: #466c9e;
  border: 1px solid white;
  width: 32px;
  height: 0;
  padding-top: 30px;
  overflow: hidden;
  position: absolute;
  top: -16px;
  right: -16px;
  border-radius: 50px;
  color: white;
  text-decoration: none;
}

@media only screen and (max-width: 480px) {
  .block.centered-pictures .close {
    right: 0;
    left: 0;
    margin: auto;
  }
}

.block.centered-pictures .close::before, .block.centered-pictures .close::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 14px;
  width: 2px;
  height: 20px;
  background: white;
  transform: rotate(45deg);
}

.block.centered-pictures .close::after {
  transform: rotate(-45deg);
}

.block.centered-pictures .text {
  color: white;
  text-align: left;
  line-height: 135%;
  margin-bottom: 15px;
  font-size: .95em;
}

.block.centered-pictures .link {
  text-align: center;
  position: relative;
  z-index: 20;
}

.block.centered-pictures .link a {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  font-size: 1.1em;
  box-shadow: none;
  color: #5a83b6;
  border: 1px solid #5a83b6;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0;
  background-color: #5a83b6;
  border: 1px solid white;
  color: white;
}

.block.centered-pictures .link a:hover {
  color: #446a99;
  border-color: #446a99;
}

.block.centered-pictures .link a:hover {
  background-color: #446a99;
  color: white;
  border-color: white;
}

.block.form-requiere-informacion {
  background-color: #5a83b6;
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #466c9e;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0;
}

.block.form-requiere-informacion .block-inner {
  margin: 0 auto;
  max-width: 1075px;
}

@media only screen and (max-width: 960px) {
  .block.form-requiere-informacion .block-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 960px) {
  .block.form-requiere-informacion .block__title,
  .block.form-requiere-informacion .entity {
    width: 367px;
    margin: 0 auto;
    max-width: 590px;
  }
}

.block.form-requiere-informacion .block__title {
  font-family: 'Raleway';
  font-weight: 300;
  font-size: 1.4em;
  color: white;
  margin-bottom: 10px;
}

@media only screen and (min-width: 420px) {
  .block.form-requiere-informacion .block__title br {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .block.form-requiere-informacion .block__title {
    text-align: center;
  }
}

@media only screen and (max-width: 480px) {
  .block.form-requiere-informacion .block__title {
    font-size: 1.4em;
    padding: 0;
  }
}

.block.form-requiere-informacion p {
  color: white;
  font-size: 1em;
  line-height: 100%;
}

@media only screen and (max-width: 480px) {
  .block.form-requiere-informacion p {
    text-align: center;
  }
}

.block#block-ana-suscripcion-block-form-suscripcion {
  background-color: #5a83b6;
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #466c9e;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.block#block-ana-suscripcion-block-form-suscripcion .block-inner {
  margin: 0 auto;
  max-width: 1075px;
  overflow: hidden;
  position: relative;
}

@media only screen and (max-width: 960px) {
  .block#block-ana-suscripcion-block-form-suscripcion .block-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 960px) {
  .block#block-ana-suscripcion-block-form-suscripcion .block-inner {
    width: 367px;
    padding: 0;
    margin: 0 auto;
    max-width: 590px;
  }
}

.block#block-ana-suscripcion-block-form-suscripcion label {
  display: none;
}

.block#block-ana-suscripcion-block-form-suscripcion form {
  max-width: 366px;
  position: relative;
}

.block#block-ana-suscripcion-block-form-suscripcion .form-item-email {
  display: inline-block;
  vertical-align: top;
  max-width: 295px;
  width: 100%;
  margin-right: 1px;
}

.block#block-ana-suscripcion-block-form-suscripcion .form-item-email .form-text {
  height: 31px;
  border-color: white;
  padding: 6px 10px 4px;
}

.block#block-ana-suscripcion-block-form-suscripcion .form-submit {
  display: inline-block;
  vertical-align: top;
  padding: .45em .6em;
  background: white;
  border: 1px solid white;
  font-size: 1.1em;
  color: #5a83b6;
  font-weight: 100;
  line-height: 100%;
  text-decoration: none;
  border: 0;
  border-left: 1px solid #466c9e;
  position: absolute;
  top: 0;
  right: 0;
  height: 31px;
  text-transform: uppercase;
}

.block#block-views-traslado-avaluadores-block-1 .block__title {
  line-height: 145%;
  margin-top: -5px;
  margin-bottom: 10px;
  font-size: .9em;
  font-weight: 400;
  color: #a4a4a4;
}

#block-views-view-groups-home-block .view-header {
  margin: 0 auto;
  max-width: 1240px;
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (min-width: 480px) {
  #block-views-view-groups-home-block .view-header {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.header {
  border-bottom: 2px solid #5a83b6;
}

.header .header-inner {
  margin: 0 auto;
  max-width: 1240px;
  padding-left: 20px;
  padding-right: 20px;
  padding: 20px 15px;
}

@media only screen and (min-width: 480px) {
  .header .header-inner {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.header .header-inner::before {
  content: '';
  display: table;
}

.header .header-inner::after {
  content: '';
  display: table;
  clear: both;
}

@media only screen and (min-width: 480px) {
  .header .header-inner {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.header .header__logo {
  max-width: 100px;
}

@media only screen and (min-width: 480px) {
  .header .header__logo {
    max-width: 140px;
  }
}

@media only screen and (min-width: 960px) {
  .header .header__logo {
    max-width: inherit;
  }
}

.header .header__region {
  float: right;
  clear: none;
}

.header .logo-in-header {
  max-width: 120px;
}

@media only screen and (min-width: 480px) {
  .header .logo-in-header {
    max-width: inherit;
  }
}

.header img {
  max-width: 100%;
  width: 100%;
}

.block-diagonal {
  padding: 20px;
  position: relative;
}

.block-diagonal .block__title {
  color: white;
}

.block-diagonal .content {
  position: relative;
  z-index: 10;
}

.block-diagonal .content p {
  color: white;
}

#block-user-login {
  max-width: 700px;
  margin: 0 auto;
  padding: 23px 20px 38px;
}

@media only screen and (min-width: 800px) {
  #block-user-login {
    padding: 23px 20px 47px;
  }
}

#block-user-login .block-inner {
  max-width: 370px;
  margin: 0 auto;
}

#block-user-login .block__title {
  font-size: 1.3em;
  margin-bottom: 12px;
  font-family: 'Raleway';
  font-weight: 400;
  text-align: center;
  font-weight: 400;
}

#block-user-login .form-item {
  margin-bottom: 17px;
}

#block-user-login .form-item.form-type-password {
  margin-bottom: 15px;
}

#block-user-login .form-text {
  padding: 4px 10px 5px;
}

#block-user-login label {
  font-weight: normal;
  font-size: 1.13em;
}

#block-user-login .form-actions {
  text-align: center;
  margin-bottom: 19px;
}

#block-user-login .item-list ul {
  margin: 0;
  padding: 0;
}

#block-user-login .item-list li {
  list-style: none;
  text-align: center;
}

#block-user-login .item-list li:first-child {
  display: none;
}

#block-user-login .item-list li a {
  font-size: 1em;
  color: #5a83b6;
  color: #466c9e;
  font-size: 1.15em;
  text-decoration: none;
  font-weight: 500;
  padding-bottom: 5px;
  border-bottom: 1px solid #5a83b6;
}

/*#block-ana-custom-ana-validate-avalua---dor{
  margin: 0 auto;
  padding: 23px 0;
  text-align: center;
  .block-inner {
    .block__title{
      font-size: 36px;
      font-weight: 600px;
    }
    form {
      #edit-form{
        border-bottom: 1px solid $blue-linea;
        max-width: 700px;
        margin: 36px auto 8px;
        padding-bottom: 23px;
        input.form-text{
          max-width: 350px;
          border: 1px solid #d8d8da;
          font-size: 14px;
        }
      }
      #result_avaluador{
        max-width: 1100px;
        margin: 0 auto;
        .top,.middle{
          text-align: left;
          margin-top: 16px;
        }
        .top-item{
          display: inline-block;
          width: 33.33%;
          text-align: left;
          font-size: 12px;
          color: $base-blue-soft;
        }
        .sanciones{
          margin-top: 24px;
          .view-content{
            text-align: left;
            .views-row{
              display: inline-block;
              width:50%;
              font-size: 12px;
              .views-field-field-date{
                span.views-label{
                  color: $base-blue-soft;
                }
                .field-content{
                  display: inline-block;
                  color: #919596;
                }
              }
              .views-field-body{
                color: #919596;
              }
            }
          }
        }
      }
    }
  }
}
*/
/*#block-views-view-groups-home-block{
  .views-row{
    position: relative;
    .views-field-field-entity-logo{
      cursor: pointer;
    }
    .views-field-field-status{
      position: absolute;
      display: none;
      top: -30%;
      left: 0px;
      width: 100%;
      text-align: left;
      background: $base-blue-soft;
      color: $white;
      font-size: 14px;
      padding: 18px;
      border: 1px solid $white;
      .close{
          position: absolute;
          top: -16px;
          right: -8px;
          color: $white;
          text-decoration: none;
          border: 1px solid $white;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          background: $base-blue-darker;
          font-size: 17px;
      }
      .link{
        text-align: center;
        a{
          @include custom-button-2;
          margin-top: 18px;
          border: 1px solid $white;
        }
      }
    }
  }
}
*/
.sidebar-menu {
  padding: 0 10px;
  position: relative;
  display: none;
}

@media only screen and (min-width: 960px) {
  .sidebar-menu {
    display: block;
    padding: 10px 30px 0 10px;
  }
}

.sidebar-menu .menu {
  margin: 0;
  padding: 0;
}

.sidebar-menu .menu__item {
  padding: 0;
  list-style: none;
  border-top: 2px solid white;
  position: relative;
}

.sidebar-menu .menu__item:last-child {
  border-bottom: 2px solid white;
}

.sidebar-menu .menu__item:hover::before, .sidebar-menu .menu__item.is-active-trail::before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5.5px 0 5.5px 10px;
  border-color: transparent transparent transparent white;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  margin: auto;
}

.sidebar-menu .menu__link {
  color: white;
  text-transform: uppercase;
  height: 50px;
  font-size: 1em;
  text-decoration: none;
  display: block;
  line-height: 145%;
  vertical-align: middle;
  display: table-cell;
}

.sidebar-menu .menu__link:hover, .sidebar-menu .menu__link.is-active-trail {
  color: #d8d8d9;
}

div div table {
  border: 0;
  padding-top: 5px;
  margin: 0;
  position: relative;
  font-size: 1em;
  color: #a4a4a4;
}

div div table th,
div div table td {
  padding: 0;
  border-right: 1px solid #d8d8d9;
  text-align: center;
}

div div table th:last-child,
div div table td:last-child {
  border-right: 0;
}

div div table thead tr {
  border-bottom: 0;
}

div div table thead th {
  color: #466c9e;
  font-weight: 100;
  text-align: center;
  padding: 5px 10px 10px;
  line-height: 100%;
  border-bottom: 1px solid #797e7f;
}

div div table tbody {
  border: 0;
}

div div table tbody tr {
  background-color: transparent;
  border-bottom: 1px solid #797e7f;
  padding: 0;
}

div div table tbody tr:last-child {
  border: 0;
}

div div table tbody tr.ajax-new-content td {
  text-align: left;
}

div div table tbody td {
  padding: 11px 10px 12px;
  font-weight: 400;
  color: #4a4a4a;
}

div div table a {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  font-size: 1.1em;
  box-shadow: none;
  color: #5a83b6;
  border: 1px solid #5a83b6;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0;
  padding: .1em 2em;
  display: inline-block;
  vertical-align: top;
  font-size: .8em;
  text-decoration: none;
  width: 24%;
  font-weight: 600;
  text-align: center;
  margin-left: 3%;
  margin-right: 3%;
  max-width: 100px;
  min-width: 65px;
  box-sizing: content-box;
}

div div table a:hover {
  color: #446a99;
  border-color: #446a99;
}

@media only screen and (max-width: 800px) {
  div div table a:first-child {
    margin-left: 0;
  }
}

div div table a + a {
  margin-left: 3%;
}

div div table a:visited {
  color: #5a83b6;
}

@media only screen and (max-width: 800px) {
  div div table a {
    padding: 5px 0;
  }
}

div div table a {
  padding: 6px 5px 5px;
}

div div table .views-field-nothing a {
  background-color: white;
}

div div table + .form-actions .form-submit {
  margin-right: 20px;
}

@media only screen and (min-width: 800px) {
  div div table td.table-buttons {
    padding-left: 0;
    padding-right: 0;
  }
}

.view.simple-header .view-filters {
  float: none;
  width: auto;
}

@media only screen and (min-width: 480px) and (max-width: 800px) {
  .view.view-header-half-width .view-header {
    width: 50%;
    float: left;
  }
}

@media only screen and (min-width: 480px) and (max-width: 800px) {
  .view.view-header-half-width .view-filters {
    width: 50%;
    float: right;
  }
}

@media only screen and (min-width: 800px) {
  .view.view-header-half-width .view-header {
    width: 50%;
    float: left;
  }
}

@media only screen and (min-width: 800px) {
  .view.view-header-half-width .view-header + .view-filters {
    width: 50%;
    float: left;
    margin-top: 25px;
  }
}

.view.view-header-half-width .view-filters {
  margin-top: 85px;
  overflow: hidden;
}

@media only screen and (max-width: 960px) {
  .view.view-header-half-width .view-filters {
    margin-top: 63px;
  }
}

@media only screen and (max-width: 480px) {
  .view.view-header-half-width .view-filters {
    margin-top: 7px;
  }
}

.view.view-header-half-width .view-filters form {
  float: right;
  width: 100%;
  position: relative;
}

@media only screen and (min-width: 480px) {
  .view.view-header-half-width .view-filters form {
    max-width: 370px;
  }
}

.view.view-header-half-width .view-filters form .views-widget-filter-field_user_number_value {
  width: 100%;
}

.view .views-exposed-form .views-exposed-widgets {
  margin: 0;
}

.view .views-exposed-form .views-exposed-widget {
  padding: 0;
  float: none;
}

.view .views-exposed-form .views-exposed-widget .form-submit {
  margin: 0;
}

.view.view-header-button .view-header a {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  font-size: 1.1em;
  box-shadow: none;
  color: #5a83b6;
  border: 1px solid #5a83b6;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0;
}

.view.view-header-button .view-header a:hover {
  color: #446a99;
  border-color: #446a99;
}

@media only screen and (min-width: 480px) {
  .view.view-eras .views-field-title {
    text-align: left;
  }
  .view.view-eras .table-buttons {
    width: 110px;
  }
  .view.view-categoria-avaluadores .table-buttons {
    width: 110px;
  }
  .view.view-categoria-avaluadores table .views-field-name {
    width: 65%;
    text-align: left;
  }
  .view.view-categoria-avaluadores table .views-field-field-status {
    width: auto;
  }
}

.view.view-registro-de-sanciones-disciplinarias .form-actions {
  display: none;
}

.table-layout-1 {
  position: relative;
}

.table-layout-1 .view-filters {
  position: relative;
  /*
		@media only screen and (min-width: $mobile) {
			width: 50%;
			float: right;
			text-align: right;
		}*/
}

.table-layout-1 .view-filters label {
  display: none;
}

.table-layout-1 .view-filters .views-widget-filter-field_user_number_value {
  float: left;
  position: relative;
  width: 84%;
}

.table-layout-1 .view-filters .views-widget-filter-field_user_number_value label {
  display: none;
}

.table-layout-1 .view-filters div.form-item .form-text {
  padding: 2px 10px 1px;
  height: 30px;
}

.table-layout-1 .view-filters .views-submit-button {
  float: left;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.table-layout-1 .view-filters .form-submit {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  font-size: 1.1em;
  box-shadow: none;
  color: #5a83b6;
  border: 1px solid #5a83b6;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0;
  background-color: #5a83b6;
  color: white;
  padding: 6px 10px;
  font-size: .8em;
  border: 0;
  height: 30px;
  font-size: 1em;
  /*
			@include custom-button-tiny;
			padding-left: 15px;
			padding-right: 15px;
			padding: 2px 15px 1px;
			font-size: .8em;*/
}

.table-layout-1 .view-filters .form-submit:hover {
  color: #446a99;
  border-color: #446a99;
}

.table-layout-1 .view-filters .form-submit:hover {
  background-color: #446a99;
  color: white;
  border-color: white;
}

.table-layout-1 .view-filters form {
  display: inline-block;
  vertical-align: top;
}

.table-layout-1 .view-header {
  color: #a4a4a4;
  margin-bottom: 0;
  font-weight: 100;
  font-size: 1em;
}

.table-layout-1 .view-header a {
  /*	@include custom-button-1;
			padding: 7px 26px;
			font-weight: 500;*/
}

@media only screen and (max-width: 480px) {
  .table-layout-1 .view-header .button-wrapper {
    text-align: center;
    padding-top: 5px;
    padding-bottom: 15px;
    border-bottom: 1px solid #bdbfc0;
  }
}

.table-layout-1 #edit-actions {
  margin-bottom: 30px;
  position: relative;
  z-index: 20;
}

@media only screen and (max-width: 800px) {
  .table-layout-1 #edit-actions {
    margin-left: -15px;
    margin-right: -15px;
    box-shadow: 0 -2px 2px #ccc;
    clear: both;
  }
}

@media only screen and (max-width: 800px) {
  .table-layout-1 #edit-actions .form-submit {
    max-width: 33.33%;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 800px) {
  .table-layout-1 #edit-actions .form-submit {
    margin-right: 20px;
  }
}

.table-layout-1::before {
  content: '';
  display: table;
}

.table-layout-1::after {
  content: '';
  display: table;
  clear: both;
}

.table-layout-1 .form-table__sticky-header, .table-layout-1 .sticky-header {
  top: 77px !important;
  background-color: white;
  z-index: 20;
}

.table-layout-1 .form-table__sticky-header::before, .table-layout-1 .sticky-header::before {
  display: none;
}

.table-layout-1 .view-header p {
  font-size: 1em;
}

.table-layout-1 .view-content {
  clear: both;
  width: 100%;
  float: left;
}

@media only screen and (min-width: 480px) {
  .table-layout-1 .view-content {
    height: 520px;
  }
}

.table-layout-1 table .appended-th {
  display: none;
}

.table-layout-1 table .appended-th + .form-type-checkbox {
  margin: 0 auto;
  display: block;
}

@media only screen and (min-width: 800px) {
  .table-layout-1 table .views-field-views-row-selector {
    text-align: center;
    min-width: 37px;
  }
  .table-layout-1 table .views-field-views-row-selector .form-item {
    margin: 0 auto;
  }
  .table-layout-1 table .views-field-name {
    width: 24%;
  }
  .table-layout-1 table .views-field-field-user-number {
    width: 21.50%;
  }
  .table-layout-1 table .views-field-field-pro-number {
    width: 21.50%;
  }
  .table-layout-1 table .views-field-field-status {
    width: 15%;
  }
  .table-layout-1 table .views-field-edit-node {
    width: 13%;
    text-align: right;
  }
}

@media only screen and (max-width: 800px) {
  .table-layout-1 table .appended-th {
    color: #466c9e;
    line-height: 100%;
    margin-bottom: 4px;
    display: block;
  }
  .table-layout-1 table .appended-th + a,
  .table-layout-1 table .appended-th + a + a,
  .table-layout-1 table .appended-th + a + a + a {
    margin-top: 10px;
  }
  .table-layout-1 table::before {
    display: none;
  }
  .table-layout-1 table thead {
    display: none;
  }
  .table-layout-1 table tbody tr {
    position: relative;
    display: block;
    padding-left: 0;
    float: left;
    width: 100%;
    box-sizing: border-box;
  }
  .table-layout-1 table tbody td {
    float: left;
    width: 100% !important;
    text-align: left;
    border-bottom: 1px solid #979797;
    padding-left: 10px;
    padding-right: 10px;
    border-right: 1px solid #979797;
  }
  .table-layout-1 table tbody td:last-child {
    border: 0;
    border-right: 1px solid #979797;
    text-align: left;
  }
  .table-layout-1 table .views-field-views-row-selector {
    float: left;
    width: 25px !important;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border: 1px solid #979797;
    border-top: 0;
    border-bottom: 0;
    padding: 0;
    background-color: white;
    z-index: 10;
    border-left-color: white;
  }
  .table-layout-1 table .views-field-views-row-selector + td,
  .table-layout-1 table .views-field-views-row-selector + td + td,
  .table-layout-1 table .views-field-views-row-selector + td + td + td,
  .table-layout-1 table .views-field-views-row-selector + td + td + td + td,
  .table-layout-1 table .views-field-views-row-selector + td + td + td + td + td,
  .table-layout-1 table .views-field-views-row-selector + td + td + td + td + td + td {
    padding-left: 40px;
  }
  .table-layout-1 table .form-type-checkbox {
    display: inline-block;
    vertical-align: top;
    position: relative;
    left: -3px;
    top: 15px;
  }
}

.table-layout-2 {
  /*
	.view-content {
		clear: both;
		width: 100% !important;
		padding-top: 15px;
	}*/
}

.table-layout-2 .view-filters {
  position: relative;
  /*
		@media only screen and (min-width: $mobile) {
			width: 50%;
			float: right;
			text-align: right;
		}*/
}

.table-layout-2 .view-filters label {
  display: none;
}

.table-layout-2 .view-filters .form-item {
  float: left;
  position: relative;
  width: 84%;
}

.table-layout-2 .view-filters .form-item label {
  display: none;
}

.table-layout-2 .view-filters div.form-item .form-text {
  padding: 2px 10px 1px;
  height: 30px;
}

.table-layout-2 .view-filters .views-submit-button {
  float: left;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.table-layout-2 .view-filters .form-submit {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  font-size: 1.1em;
  box-shadow: none;
  color: #5a83b6;
  border: 1px solid #5a83b6;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0;
  background-color: #5a83b6;
  color: white;
  padding: 6px 10px;
  font-size: .8em;
  border: 0;
  height: 30px;
  font-size: 1em;
  /*
			@include custom-button-tiny;
			padding-left: 15px;
			padding-right: 15px;
			padding: 2px 15px 1px;
			font-size: .8em;*/
}

.table-layout-2 .view-filters .form-submit:hover {
  color: #446a99;
  border-color: #446a99;
}

.table-layout-2 .view-filters .form-submit:hover {
  background-color: #446a99;
  color: white;
  border-color: white;
}

.table-layout-2 .view-filters form {
  display: inline-block;
  vertical-align: top;
}

@media only screen and (min-width: 480px) and (max-width: 800px) {
  .table-layout-2 .view-header {
    width: 50%;
    float: left;
  }
}

@media only screen and (min-width: 480px) and (max-width: 800px) {
  .table-layout-2 .view-filters {
    width: 50%;
    float: right;
  }
}

.table-layout-2 #edit-actions {
  margin-bottom: 30px;
  position: relative;
  z-index: 20;
}

@media only screen and (max-width: 800px) {
  .table-layout-2 #edit-actions {
    margin-left: -15px;
    margin-right: -15px;
    box-shadow: 0 -2px 2px #ccc;
    clear: both;
  }
}

@media only screen and (max-width: 800px) {
  .table-layout-2 #edit-actions .form-submit {
    max-width: 33.33%;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 800px) {
  .table-layout-2 #edit-actions .form-submit {
    margin-right: 20px;
  }
}

.table-layout-2::before {
  content: '';
  display: table;
}

.table-layout-2::after {
  content: '';
  display: table;
  clear: both;
}

.table-layout-2 .view-header {
  color: #a4a4a4;
}

.table-layout-2 .view-filters {
  text-align: left;
  max-width: 395px;
}

.table-layout-2 .view-filters form {
  display: block;
}

.table-layout-2 .view-content {
  clear: both;
  width: 100% !important;
  margin-top: 0;
  overflow: auto;
  float: left;
}

.table-layout-2 table {
  position: relative;
  /*
		&::after {
			content: '';
			background: $table-horizontal-color;
			width: 100%;
			height: 1px;
			position: absolute;
			left: 0;
			bottom: -10px;
			display: block;
		}*/
}

.table-layout-2 table thead th {
  border-bottom: 0;
  padding: 0 0 13px;
}

.table-layout-2 table thead th:first-child {
  text-align: left;
}

.table-layout-2 table tbody tr {
  border-bottom: 0;
}

.table-layout-2 table tbody tr:hover {
  background-color: #f1f0f2;
}

@media only screen and (min-width: 800px) {
  .table-layout-2 table tbody td {
    padding: 2px 0;
  }
}

.table-layout-2 table tbody td:first-child {
  text-align: left;
}

.table-layout-2 .views-field-name {
  width: 30%;
  text-align: left;
}

.table-layout-2 .views-field-field-unique-code {
  width: 15%;
}

.table-layout-2 .views-field-field-date {
  width: 20%;
}

.table-layout-2 .views-field-nothing {
  width: 23%;
}

.table-layout-2 .views-field-view-user {
  width: 12%;
}

.table-layout-2 a {
  border: 0;
}

.table-layout-2 a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 800px) {
  .table-layout-2 .jspPane {
    width: 100% !important;
  }
}

.table-layout-2 .review {
  display: none;
}

@media only screen and (max-width: 800px) {
  .table-mobile-layout-1 .view-content {
    width: 100% !important;
  }
}

.table-mobile-layout-1 .view-header {
  margin-bottom: 20px;
}

.table-mobile-layout-1 .view-header a {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  font-size: 1.1em;
  box-shadow: none;
  color: #5a83b6;
  border: 1px solid #5a83b6;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0;
  font-size: 1em;
}

.table-mobile-layout-1 .view-header a:hover {
  color: #446a99;
  border-color: #446a99;
}

.table-mobile-layout-1 table {
  margin-top: 6px;
  table-layout: fixed;
}

.table-mobile-layout-1 table .appended-th {
  display: none;
}

.table-mobile-layout-1 table .appended-th + .form-type-checkbox {
  margin: 0 auto;
  display: block;
}

.table-mobile-layout-1 table::before, .table-mobile-layout-1 table::after {
  content: '';
}

.table-mobile-layout-1 table .views-field-views-row-selector {
  width: 38px;
  padding-left: 0;
  padding-right: 0;
}

@media only screen and (min-width: 800px) {
  .table-mobile-layout-1 table .views-field-views-row-selector {
    text-align: center;
    min-width: 37px;
  }
  .table-mobile-layout-1 table .views-field-views-row-selector .form-item {
    margin: 0 auto;
  }
  .table-mobile-layout-1 table .views-field-name {
    width: 24%;
  }
  .table-mobile-layout-1 table .views-field-field-user-number {
    width: 21.50%;
  }
  .table-mobile-layout-1 table .views-field-field-pro-number {
    width: 21.50%;
  }
  .table-mobile-layout-1 table .views-field-field-status {
    width: 15%;
  }
  .table-mobile-layout-1 table .views-field-edit-node {
    width: 13%;
    text-align: right;
  }
}

@media only screen and (max-width: 800px) {
  .table-mobile-layout-1 table .appended-th {
    color: #466c9e;
    line-height: 100%;
    margin-bottom: 4px;
    display: block;
  }
  .table-mobile-layout-1 table .appended-th + a,
  .table-mobile-layout-1 table .appended-th + a + a,
  .table-mobile-layout-1 table .appended-th + a + a + a {
    margin-top: 10px;
  }
  .table-mobile-layout-1 table::before {
    display: none;
  }
  .table-mobile-layout-1 table thead {
    display: none;
  }
  .table-mobile-layout-1 table tbody tr {
    position: relative;
    display: block;
    padding-left: 0;
    float: left;
    width: 100%;
    box-sizing: border-box;
  }
  .table-mobile-layout-1 table tbody td {
    float: left;
    width: 100% !important;
    text-align: left;
    border-bottom: 1px solid #979797;
    padding-left: 10px;
    padding-right: 10px;
    border-right: 1px solid #979797;
  }
  .table-mobile-layout-1 table tbody td:last-child {
    border: 0;
    border-right: 1px solid #979797;
    text-align: left;
  }
  .table-mobile-layout-1 table .views-field-views-row-selector {
    float: left;
    width: 25px !important;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border: 1px solid #979797;
    border-top: 0;
    border-bottom: 0;
    padding: 0;
    background-color: white;
    z-index: 10;
    border-left-color: white;
  }
  .table-mobile-layout-1 table .views-field-views-row-selector + td,
  .table-mobile-layout-1 table .views-field-views-row-selector + td + td,
  .table-mobile-layout-1 table .views-field-views-row-selector + td + td + td,
  .table-mobile-layout-1 table .views-field-views-row-selector + td + td + td + td,
  .table-mobile-layout-1 table .views-field-views-row-selector + td + td + td + td + td,
  .table-mobile-layout-1 table .views-field-views-row-selector + td + td + td + td + td + td {
    padding-left: 40px;
  }
  .table-mobile-layout-1 table .form-type-checkbox {
    display: inline-block;
    vertical-align: top;
    position: relative;
    left: -3px;
    top: 15px;
  }
}

.table-mobile-layout-2 {
  /*
	.view-header {
		margin-bottom: 10px;

		a {
			@include custom-button-1;
		}
	}
	*/
}

@media only screen and (max-width: 800px) {
  .table-mobile-layout-2 .view-content {
    width: 100% !important;
  }
}

@media only screen and (max-width: 800px) {
  .table-mobile-layout-2 .linea-1 {
    margin-bottom: 10px;
  }
  .table-mobile-layout-2 .linea-2 {
    display: none;
  }
}

.table-mobile-layout-2 table .appended-th {
  display: none;
}

.table-mobile-layout-2 table .appended-th + .form-type-checkbox {
  margin: 0 auto;
  display: block;
}

.table-mobile-layout-2 table .views-field-views-row-selector {
  width: 38px;
  padding-left: 0;
  padding-right: 0;
}

@media only screen and (max-width: 800px) {
  .table-mobile-layout-2 table {
    border-top: 0;
  }
  .table-mobile-layout-2 table .appended-th {
    color: #466c9e;
    line-height: 100%;
    margin-bottom: 4px;
    display: block;
  }
  .table-mobile-layout-2 table .appended-th + a,
  .table-mobile-layout-2 table .appended-th + a + a,
  .table-mobile-layout-2 table .appended-th + a + a + a {
    margin-top: 10px;
  }
  .table-mobile-layout-2 table::before {
    display: none;
  }
  .table-mobile-layout-2 table thead {
    display: none;
  }
  .table-mobile-layout-2 table tbody tr {
    position: relative;
    display: block;
    padding-left: 0;
    float: left;
    width: 100%;
    box-sizing: border-box;
  }
  .table-mobile-layout-2 table tbody td {
    float: left;
    width: 100% !important;
    text-align: left;
    border-bottom: 1px solid #979797;
    padding-left: 10px;
    padding-right: 10px;
    border-right: 1px solid #979797;
  }
  .table-mobile-layout-2 table tbody td:last-child {
    border: 0;
    border-right: 1px solid #979797;
    text-align: left;
  }
  .table-mobile-layout-2 table .views-field-views-row-selector {
    float: left;
    width: 25px !important;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border: 1px solid #979797;
    border-top: 0;
    border-bottom: 0;
    padding: 0;
    background-color: white;
    z-index: 10;
    border-left-color: white;
  }
  .table-mobile-layout-2 table .views-field-views-row-selector + td,
  .table-mobile-layout-2 table .views-field-views-row-selector + td + td,
  .table-mobile-layout-2 table .views-field-views-row-selector + td + td + td,
  .table-mobile-layout-2 table .views-field-views-row-selector + td + td + td + td,
  .table-mobile-layout-2 table .views-field-views-row-selector + td + td + td + td + td,
  .table-mobile-layout-2 table .views-field-views-row-selector + td + td + td + td + td + td {
    padding-left: 40px;
  }
  .table-mobile-layout-2 table .form-type-checkbox {
    display: inline-block;
    vertical-align: top;
    position: relative;
    left: -3px;
    top: 15px;
  }
  .table-mobile-layout-2 table tr {
    background: #f1f0f2;
    padding-left: 0 !important;
    border: 1px solid #979797 !important;
    border-right: 0 !important;
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
  .table-mobile-layout-2 table tr td {
    min-height: 50px;
    display: block;
  }
  .table-mobile-layout-2 table .views-field-nothing,
  .table-mobile-layout-2 table .views-field-view-user,
  .table-mobile-layout-2 table .views-field-field-decision-info {
    text-align: center !important;
  }
  .table-mobile-layout-2 table .views-field-nothing .appended-th,
  .table-mobile-layout-2 table .views-field-view-user .appended-th,
  .table-mobile-layout-2 table .views-field-field-decision-info .appended-th {
    line-height: 100% !important;
  }
  .table-mobile-layout-2 table .views-field-nothing .appended-th + a,
  .table-mobile-layout-2 table .views-field-nothing .appended-th + a + a,
  .table-mobile-layout-2 table .views-field-nothing .appended-th + a + a + a,
  .table-mobile-layout-2 table .views-field-view-user .appended-th + a,
  .table-mobile-layout-2 table .views-field-view-user .appended-th + a + a,
  .table-mobile-layout-2 table .views-field-view-user .appended-th + a + a + a,
  .table-mobile-layout-2 table .views-field-field-decision-info .appended-th + a,
  .table-mobile-layout-2 table .views-field-field-decision-info .appended-th + a + a,
  .table-mobile-layout-2 table .views-field-field-decision-info .appended-th + a + a + a {
    margin-top: 5px;
  }
  .table-mobile-layout-2 table a.simple-link {
    font-size: .8em;
    padding: 0;
    margin: 2px 0 0 !important;
  }
}

.block-ana-custom-ana-validate-avaluador {
  text-align: center;
  padding: 0 20px;
}

.block-ana-custom-ana-validate-avaluador #edit-actions {
  max-width: 740px;
  margin: 0 auto;
  padding: 0 0 20px;
  border-bottom: 1px solid #5a83b6;
}

@media only screen and (min-width: 480px) {
  .block-ana-custom-ana-validate-avaluador #edit-actions {
    padding: 0 0 40px;
  }
}

.block-ana-custom-ana-validate-avaluador #result_avaluador {
  max-width: 1200px;
  margin: 0 auto;
}

.block-ana-custom-ana-validate-avaluador #result_avaluador .form-wrapper .top {
  padding-top: 22px;
}

.block-ana-custom-ana-validate-avaluador .block__title {
  padding-top: 21px;
  margin-bottom: 0;
  font-size: 1.6em;
  font-weight: 400;
}

@media only screen and (min-width: 480px) {
  .block-ana-custom-ana-validate-avaluador .block__title {
    padding-top: 40px;
    font-size: 2.5em;
  }
}

.block-ana-custom-ana-validate-avaluador p {
  margin: 0 auto 13px;
  max-width: 740px;
}

.block-ana-custom-ana-validate-avaluador a {
  text-decoration: none;
  color: #5a83b6;
}

.block-ana-custom-ana-validate-avaluador .form-item {
  margin-bottom: 20px;
}

.block-ana-custom-ana-validate-avaluador .form-text {
  padding: 4px 10px 5px;
  font-size: .9em;
}

.block-ana-custom-ana-validate-avaluador .form-text::placeholder {
  color: #a1a1a1;
}

.block-ana-custom-ana-validate-avaluador .form-item {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}

.block-ana-custom-ana-validate-avaluador .form-text {
  border-color: #d8d8d9;
}

@media only screen and (min-width: 600px) {
  .block-ana-custom-ana-validate-avaluador .top-item {
    width: 33.33%;
    float: left;
  }
}

.block-ana-custom-ana-validate-avaluador .top,
.block-ana-custom-ana-validate-avaluador .middle,
.block-ana-custom-ana-validate-avaluador .sanciones {
  text-align: left;
  overflow: hidden;
  line-height: 120%;
}

.block-ana-custom-ana-validate-avaluador .top,
.block-ana-custom-ana-validate-avaluador .middle {
  color: #5a83b6;
  margin-bottom: 32px;
}

.block-ana-custom-ana-validate-avaluador .sanciones {
  font-size: .85em;
  line-height: 145%;
  color: #a4a4a4;
  padding-bottom: 24px;
  border-bottom: 1px solid #5a83b6;
}

.block-ana-custom-ana-validate-avaluador .sanciones .view-content {
  overflow: hidden;
  margin: 0 -30px;
}

.block-ana-custom-ana-validate-avaluador .sanciones .views-row {
  padding: 0 30px;
}

@media only screen and (min-width: 800px) {
  .block-ana-custom-ana-validate-avaluador .sanciones .views-row {
    width: 50%;
    float: left;
  }
}

.block-ana-custom-ana-validate-avaluador .views-field-field-date,
.block-ana-custom-ana-validate-avaluador .views-field-title {
  margin-bottom: 2px;
}

.block-ana-custom-ana-validate-avaluador .views-field-field-date .views-label {
  color: #5a83b6;
}

.block-ana-custom-ana-validate-avaluador .views-field-field-date .views-label,
.block-ana-custom-ana-validate-avaluador .views-field-field-date .field-content {
  display: inline-block;
  vertical-align: top;
}

.block-ana-custom-ana-validate-avaluador#block-ana-custom-ana-validate-certificado {
  max-width: 740px;
  padding: 0 0 20px;
  margin: 0 auto;
  border-bottom: 1px solid #5a83b6;
}

@media only screen and (min-width: 480px) {
  .block-ana-custom-ana-validate-avaluador#block-ana-custom-ana-validate-certificado {
    padding: 0 0 40px;
  }
}

.block-ana-custom-ana-validate-avaluador#block-ana-custom-ana-validate-certificado .description {
  margin: 10px auto 0;
}

.block-ana-custom-ana-validate-avaluador#block-ana-custom-ana-validate-certificado .form-item {
  max-width: inherit;
}

.block-ana-custom-ana-validate-avaluador#block-ana-custom-ana-validate-certificado .form-text {
  max-width: 350px;
}

.front .footer {
  background-color: #eee;
}

.front #modalContent {
  position: absolute;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
}

.front #modalContent .modal-header {
  padding: 20px 30px 0;
}

@media only screen and (max-width: 480px) {
  .front #modalContent .modal-header {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.front #modalContent .modal-title {
  font-family: 'Raleway';
  font-weight: 400;
  font-size: 2.40em;
  line-height: 100%;
  color: #466c9e;
  text-align: left;
  display: block;
  position: relative;
  z-index: 15;
}

@media only screen and (max-width: 960px) {
  .front #modalContent .modal-title {
    font-size: 1.62em;
    padding: 0;
    font-weight: 400;
    line-height: 140%;
  }
}

.front .ctools-modal-content {
  height: 220px !important;
  width: 100% !important;
  max-width: 400px;
  position: fixed !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  margin: auto;
}

.front .ctools-modal-content .modal-content {
  width: 100% !important;
  height: auto !important;
  padding: 30px !important;
}

@media only screen and (max-width: 480px) {
  .front .ctools-modal-content .modal-content {
    padding: 20px !important;
  }
}

.front .ctools-modal-content .popups-close {
  position: absolute;
  top: 20px;
  right: 30px;
  z-index: 20;
}

@media only screen and (max-width: 480px) {
  .front .ctools-modal-content .popups-close {
    right: 30px;
  }
}

.front #user-pass label {
  font-size: 1.13em;
  font-weight: normal;
}

.front #user-pass .form-text {
  height: 31px;
}

.front #user-pass .form-submit {
  display: block;
  width: 100%;
}

.not-front .header {
  display: none;
}

@media only screen and (max-width: 960px) {
  .not-front.page-gestion-usuarios .layout-3col__right-content {
    padding-top: 75px;
  }
}

.not-front.page-gestion-usuarios .views-field-views-row-selector {
  width: 1%;
}

@media only screen and (max-width: 800px) {
  .not-front.page-gestion-usuarios .views-field-edit-node .appended-th {
    display: none;
  }
  .not-front.page-gestion-usuarios .views-field-edit-node a {
    margin: 0 !important;
  }
}

.not-front.page-avaluadores .subtitle {
  margin-bottom: 0;
}

.page-node-add .node-form {
  margin-top: 25px;
  padding-top: 10px;
  border-top: 1px solid #466c9e;
}

.page-node-add-comité {
  /*@include edit-multiple-table('.form-field-name-field-funcionarios');
	@include edit-multiple-table('.form-field-name-field-avaluadores-comite');
	@include input-text-cms('.form-item-title', '.form-text');
	@include input-text-cms('.form-field-name-field-number-comite', '.form-text');
	@include input-text-datepicker('div.form-field-type-datetime', '.form-text');*/
}

.page-sanciones table::before {
  display: none;
}

@media only screen and (min-width: 800px) {
  .page-sanciones div div table th,
  .page-sanciones div div table td {
    border: 1px solid #979797;
  }
}

.page-sanciones div div table thead th {
  padding: 12px 0;
}

.page-sanciones .views-field-field-unique-code {
  width: 14%;
}

.page-sanciones .views-field-field-date {
  width: 14%;
}

.page-sanciones .views-field-field-type {
  width: 16%;
}

.page-sanciones .views-field-field-end-date {
  width: 19%;
}

.page-sanciones .views-field-view-node {
  width: 8%;
  text-align: center;
}

.page-sanciones .views-field-view-node a {
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  min-width: auto;
  white-space: nowrap;
}

.page-sanciones .views-field-nothing {
  width: 32%;
}

.page-sanciones .views-field-nothing a {
  padding-left: 10px;
  padding-right: 10px;
  min-width: auto;
}

.page-sanciones .views-field-nothing a + a,
.page-sanciones .views-field-nothing a + a + a {
  margin-left: 10px;
}

html.page-era {
  height: 100%;
}

body.page-era {
  height: 100%;
  overflow: hidden;
}

body.page-era .layout-center,
body.page-era .layout-3col,
body.page-era .layout-3col__full,
body.page-era .main-content,
body.page-era .main-content > .row-fluid,
body.page-era .era-content {
  height: 100%;
}

body.page-era .main-content {
  overflow-y: scroll;
}

.page-era p {
  color: white;
  margin-bottom: 20px;
}

.page-era .era-top {
  text-align: center;
  padding: 36px 0 14px;
  box-shadow: 0 5px 10px #111;
  position: relative;
  z-index: 50;
  background-color: white;
}

@media only screen and (max-width: 800px) {
  .page-era .era-top {
    padding: 14px 0 6px;
  }
  .page-era .era-top img {
    max-width: 70px;
    height: auto;
  }
}

.page-era .form-title {
  color: white;
  font-size: 1.25em;
  font-weight: 100;
  letter-spacing: .5px;
  line-height: 100%;
  padding-bottom: 22px;
}

.page-era .container-inline-date::before {
  content: '';
  display: table;
}

.page-era .container-inline-date::after {
  content: '';
  display: table;
  clear: both;
}

.page-era .container-inline-date .date-padding,
.page-era .container-inline-date .form-item .form-item {
  float: none;
  margin: 0;
}

.page-era .container-inline-date .form-item {
  margin-right: 0;
  display: block;
}

.page-era .form-item {
  margin-bottom: 9px;
}

.page-era .form-item label {
  color: white;
  font-weight: 100;
  font-size: 1em;
  line-height: 100%;
  text-align: left;
  margin-bottom: 13px;
}

.page-era .form-item .form-text {
  font-size: 1em;
  font-weight: 400;
  padding: 3px 10px;
  border: 0;
  background-color: white;
  color: #5a83b6;
  height: 30px;
}

.page-era .form-item.form-type-file label {
  color: #5a83b6;
  border: 0;
}

.page-era .field-name-field-imagen-perfil .label,
.page-era .field-name-field-imagen-perfil label {
  display: inline-block;
  vertical-align: top;
  font-size: 14px !important;
}

.page-era .field-name-field-imagen-perfil .form-type-managed-file {
  text-align: right;
  font-size: 0;
}

.page-era .era-content {
  height: 100%;
  padding-top: 27px;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  position: relative;
}

@media only screen and (min-width: 800px) {
  .page-era .era-content {
    padding-top: 60px;
  }
}

.page-era .era-content > div {
  position: relative;
  z-index: 40;
}

.page-era .era-buttons {
  text-align: center;
  margin-bottom: 10px;
  margin: 0 10px 10px;
}

.page-era .era-buttons > div {
  margin: 0 auto 15px;
}

@media only screen and (min-width: 800px) {
  .page-era .era-buttons > div {
    display: inline-block;
    vertical-align: top;
    width: 33%;
  }
}

.page-era .era-buttons a {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  font-size: 1.1em;
  box-shadow: none;
  color: #5a83b6;
  border: 1px solid #5a83b6;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0;
  display: block;
  border-color: white;
  text-transform: none;
  font-weight: 400;
  color: white;
  margin: 0 auto;
  padding: .85em 0;
  font-size: 1.25em;
  max-width: 400px;
  letter-spacing: .5px;
}

.page-era .era-buttons a:hover {
  color: #446a99;
  border-color: #446a99;
}

.page-era .era-buttons a:hover {
  color: white;
  border-color: white;
  background-color: #466c9e;
}

.page-era .era-buttons a.active {
  background-color: #466c9e;
}

.page-era .era-button {
  max-width: 570px;
  margin: 0 auto;
  text-align: center;
  padding: 0 0 20px;
  position: relative;
  border-bottom: 1px solid white;
  margin-bottom: 40px;
}

@media only screen and (max-width: 480px) {
  .page-era .era-button {
    border: 1px solid white;
    border-top: 0;
    margin: 0 10px 10px;
  }
}

@media only screen and (max-width: 480px) {
  .page-era .era-button .era-button-inner {
    border-bottom: 1px solid white;
    position: relative;
    padding-bottom: 17px;
    margin: 0 15px;
  }
}

.page-era .era-button .era-button-inner::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5.5px 9px;
  border-color: transparent transparent white;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.page-era .era-button a {
  font-size: 1.25em;
  text-decoration: none;
  color: white;
}

.page-era #register-section {
  text-align: center;
  padding: 0 10px;
}

.page-era #register-section form {
  display: inline-block;
  vertical-align: top;
  border: 1px solid white;
  max-width: 800px;
  width: 100%;
  padding: 13px 70px 30px;
}

@media only screen and (max-width: 800px) {
  .page-era #register-section form {
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media only screen and (max-width: 480px) {
  .page-era #register-section form {
    border-bottom: 0;
  }
}

.page-era #register-section .label {
  color: white;
  font-weight: 100;
  font-size: 1em;
  line-height: 100%;
}

@media only screen and (max-width: 480px) {
  .page-era #register-section .label {
    width: 89px;
    padding-top: 0;
    text-align: justify;
  }
}

.page-era #register-section .group-column1,
.page-era #register-section .group-column2,
.page-era #register-section .group-column3 {
  display: block;
  width: 100%;
}

.page-era #register-section #edit-profile-avaluador-field-document-type label {
  display: none;
}

.page-era #register-section .field-widget-date-popup {
  max-width: 200px;
  margin-bottom: 10px;
  border: 1px solid #d8d8d9;
}

.page-era #register-section .field-widget-date-popup::before {
  content: '';
  display: table;
}

.page-era #register-section .field-widget-date-popup::after {
  content: '';
  display: table;
  clear: both;
}

.page-era #register-section .field-widget-date-popup .form-item {
  margin: 0;
}

.page-era #register-section .field-widget-date-popup .form-text {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  height: 30px;
  padding: 1px 10px 3px;
  border: 1px solid transparent;
  padding: 2px 0;
}

.page-era #register-section .field-widget-date-popup fieldset,
.page-era #register-section .field-widget-date-popup legend,
.page-era #register-section .field-widget-date-popup fieldset.date-combo .container-inline-date .date-padding {
  padding: 0;
  border: 0;
  border: 0;
  margin: 0;
}

.page-era #register-section .field-widget-date-popup legend {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  height: 30px;
  padding: 1px 10px 3px;
  margin-right: 10px;
  display: block;
  white-space: nowrap;
  float: left;
  padding: 6px 0 2px 10px;
  position: relative;
}

.page-era #register-section .field-widget-date-popup legend .fieldset-legend {
  display: block;
  overflow: hidden;
}

.page-era #register-section .field-widget-date-popup legend::after {
  content: ": ";
  position: absolute;
  top: 6px;
  left: 100%;
}

.page-era #register-section .field-widget-date-popup .fieldset-wrapper {
  float: left;
  width: 80px;
}

.page-era #register-section .field-widget-date-popup label,
.page-era #register-section .field-widget-date-popup .description {
  display: none;
}

.page-era #register-section .field-widget-date-popup .container-inline-date > .form-item {
  margin-right: 0;
  display: block;
}

.page-era #register-section .field-widget-date-popup {
  background-color: white;
  width: 100%;
  max-width: none;
}

.page-era #register-section .field-widget-date-popup legend,
.page-era #register-section .field-widget-date-popup .form-text {
  font-size: 1em;
}

.page-era #register-section .field-widget-date-popup legend {
  font-weight: 400;
}

.page-era #register-section .field-widget-date-popup legend .fieldset-legend {
  width: auto;
}

.page-era #register-section #edit-profile-avaluador {
  max-width: 100%;
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
}

.page-era #register-section #edit-profile-avaluador > legend {
  margin-bottom: 20px;
  font-size: 1.2em;
  font-weight: 300;
  letter-spacing: 1px;
  display: block;
  text-align: center;
  color: white;
  margin: 0;
  padding: 15px 0 25px;
  width: 100%;
}

.page-era #register-section #edit-actions {
  padding-top: 50px;
}

.page-era #register-section #edit-actions .form-submit {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  font-size: 1.1em;
  box-shadow: none;
  color: #5a83b6;
  border: 1px solid #5a83b6;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0;
  background-color: white;
  border: 0;
  font-size: 1.2em;
  padding: 1em 1.3em;
  font-weight: 600;
}

.page-era #register-section #edit-actions .form-submit:hover {
  color: #446a99;
  border-color: #446a99;
}

@media only screen and (max-width: 960px) {
  .page-era #register-section #edit-actions .form-submit {
    display: block;
    width: 100%;
    float: none;
    margin: 0 auto;
  }
}

.page-era #register-section .field-name-field-documentos-adicionales {
  color: #FFF;
  text-align: left;
}

.page-era #register-section .field-name-field-documentos-adicionales .fieldset-description {
  padding: 10px 0;
}

.page-era #register-section .field-name-field-documentos-adicionales .field-type-file label {
  min-width: 75%;
}

.page-era #register-section .field-name-field-documentos-adicionales .field-type-file .file-widget {
  display: block;
  float: right;
}

.page-era div.form-item-files-experience {
  margin-bottom: 20px;
}

.page-era #login-section,
.page-era #register-era {
  text-align: center;
  display: none;
  padding: 0 10px;
}

@media only screen and (max-width: 480px) {
  .page-era #login-section,
  .page-era #register-era {
    margin-bottom: 0 !important;
  }
}

.page-era #login-section label,
.page-era #register-era label {
  color: white;
  text-align: left;
  font-weight: 100;
  font-size: 1.1em;
  line-height: 100%;
  margin-bottom: 14px;
}

.page-era #login-section .form-item,
.page-era #register-era .form-item {
  margin-bottom: 19px;
}

.page-era #login-section form,
.page-era #register-era form {
  display: inline-block;
  vertical-align: top;
  border: 1px solid white;
  width: 100%;
  padding: 12px;
}

@media only screen and (min-width: 480px) {
  .page-era #login-section form,
  .page-era #register-era form {
    max-width: 400px;
  }
}

@media only screen and (max-width: 480px) {
  .page-era #login-section form,
  .page-era #register-era form {
    border-bottom: 0;
  }
}

.page-era #login-section .description,
.page-era #register-era .description {
  color: white;
  font-size: .8em;
  text-align: left;
  display: none;
}

.page-era #login-section .form-actions,
.page-era #register-era .form-actions {
  margin: 30px 0;
}

@media only screen and (max-width: 480px) {
  .page-era #login-section .form-actions,
  .page-era #register-era .form-actions {
    margin-bottom: 20px;
  }
}

.page-era #login-section .form-submit,
.page-era #register-era .form-submit {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  font-size: 1.1em;
  box-shadow: none;
  color: #5a83b6;
  border: 1px solid #5a83b6;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0;
  background-color: #5a83b6;
  border: 1px solid white;
  color: white;
  border-color: white !important;
  color: white;
  font-size: 1.2em;
  padding: .62em 1.8em;
  background: none;
  font-weight: 100;
}

.page-era #login-section .form-submit:hover,
.page-era #register-era .form-submit:hover {
  color: #446a99;
  border-color: #446a99;
}

.page-era #login-section .form-submit:hover,
.page-era #register-era .form-submit:hover {
  background-color: #446a99;
  color: white;
  border-color: white;
}

@media only screen and (min-width: 480px) {
  .page-era #register-section,
  .page-era #login-section,
  .page-era #register-era {
    margin-bottom: 70px;
  }
}

.page-era #edit-profile-avaluador-field-categorias .form-type-select > label,
.page-era #edit-profile-avaluador-field-regimen .form-type-select > label,
.page-era #edit-profile-avaluador-field-certificados-de-experienci-und-0-field-categorias .form-type-select > label,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-objeto-del-certificado .form-type-select > label,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-entidad .form-type-select > label {
  display: none;
}

.page-era #edit-profile-avaluador-field-categorias .SumoSelect,
.page-era #edit-profile-avaluador-field-regimen .SumoSelect,
.page-era #edit-profile-avaluador-field-certificados-de-experienci-und-0-field-categorias .SumoSelect,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-objeto-del-certificado .SumoSelect,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-entidad .SumoSelect {
  width: 100%;
  display: block;
}

.page-era #edit-profile-avaluador-field-categorias .SumoSelect > .CaptionCont,
.page-era #edit-profile-avaluador-field-regimen .SumoSelect > .CaptionCont,
.page-era #edit-profile-avaluador-field-certificados-de-experienci-und-0-field-categorias .SumoSelect > .CaptionCont,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-objeto-del-certificado .SumoSelect > .CaptionCont,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-entidad .SumoSelect > .CaptionCont {
  width: 100%;
  border: 1px solid #5a83b6;
  padding: 1px 10px 3px;
  font-weight: 100;
  border-radius: 0;
  box-shadow: none;
  width: 100%;
  font-size: 1em;
  padding: 4px 10px 2px;
  border: 0;
  border-radius: 0;
  height: 30px;
}

.page-era #edit-profile-avaluador-field-categorias .SumoSelect > .CaptionCont > span,
.page-era #edit-profile-avaluador-field-regimen .SumoSelect > .CaptionCont > span,
.page-era #edit-profile-avaluador-field-certificados-de-experienci-und-0-field-categorias .SumoSelect > .CaptionCont > span,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-objeto-del-certificado .SumoSelect > .CaptionCont > span,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-entidad .SumoSelect > .CaptionCont > span {
  font-size: inherit;
  color: #5a83b6;
  text-align: left;
  font-size: inherit;
  font-weight: 400;
  font-style: normal;
  padding-top: 1px;
  padding-bottom: 1px;
}

.page-era #edit-profile-avaluador-field-categorias .SumoSelect > .CaptionCont > label,
.page-era #edit-profile-avaluador-field-regimen .SumoSelect > .CaptionCont > label,
.page-era #edit-profile-avaluador-field-certificados-de-experienci-und-0-field-categorias .SumoSelect > .CaptionCont > label,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-objeto-del-certificado .SumoSelect > .CaptionCont > label,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-entidad .SumoSelect > .CaptionCont > label {
  margin: 0;
  width: 42px;
}

.page-era #edit-profile-avaluador-field-categorias .SumoSelect > .CaptionCont > label i,
.page-era #edit-profile-avaluador-field-regimen .SumoSelect > .CaptionCont > label i,
.page-era #edit-profile-avaluador-field-certificados-de-experienci-und-0-field-categorias .SumoSelect > .CaptionCont > label i,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-objeto-del-certificado .SumoSelect > .CaptionCont > label i,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-entidad .SumoSelect > .CaptionCont > label i {
  background-image: url("../img/sprites/Arrow-blue-down.png");
  opacity: 1;
  width: 22px;
  height: 23px;
}

.page-era #edit-profile-avaluador-field-categorias .SumoSelect > .optWrapper,
.page-era #edit-profile-avaluador-field-regimen .SumoSelect > .optWrapper,
.page-era #edit-profile-avaluador-field-certificados-de-experienci-und-0-field-categorias .SumoSelect > .optWrapper,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-objeto-del-certificado .SumoSelect > .optWrapper,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-entidad .SumoSelect > .optWrapper {
  background-color: #5a83b6;
  border: 0;
  border-radius: 0;
}

.page-era #edit-profile-avaluador-field-categorias .SumoSelect > .optWrapper.open,
.page-era #edit-profile-avaluador-field-regimen .SumoSelect > .optWrapper.open,
.page-era #edit-profile-avaluador-field-certificados-de-experienci-und-0-field-categorias .SumoSelect > .optWrapper.open,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-objeto-del-certificado .SumoSelect > .optWrapper.open,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-entidad .SumoSelect > .optWrapper.open {
  top: 31px;
}

.page-era #edit-profile-avaluador-field-categorias .SumoSelect > .optWrapper > .options > li,
.page-era #edit-profile-avaluador-field-regimen .SumoSelect > .optWrapper > .options > li,
.page-era #edit-profile-avaluador-field-certificados-de-experienci-und-0-field-categorias .SumoSelect > .optWrapper > .options > li,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-objeto-del-certificado .SumoSelect > .optWrapper > .options > li,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-entidad .SumoSelect > .optWrapper > .options > li {
  border-color: white;
}

.page-era #edit-profile-avaluador-field-categorias .SumoSelect > .optWrapper > .options > li:hover,
.page-era #edit-profile-avaluador-field-regimen .SumoSelect > .optWrapper > .options > li:hover,
.page-era #edit-profile-avaluador-field-certificados-de-experienci-und-0-field-categorias .SumoSelect > .optWrapper > .options > li:hover,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-objeto-del-certificado .SumoSelect > .optWrapper > .options > li:hover,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-entidad .SumoSelect > .optWrapper > .options > li:hover {
  background-color: #466c9e;
}

.page-era #edit-profile-avaluador-field-categorias .SumoSelect > .optWrapper > .options > li label,
.page-era #edit-profile-avaluador-field-regimen .SumoSelect > .optWrapper > .options > li label,
.page-era #edit-profile-avaluador-field-certificados-de-experienci-und-0-field-categorias .SumoSelect > .optWrapper > .options > li label,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-objeto-del-certificado .SumoSelect > .optWrapper > .options > li label,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-entidad .SumoSelect > .optWrapper > .options > li label {
  font-size: 1em;
  margin: 0;
}

.page-era #edit-profile-avaluador-field-categorias .SumoSelect > .optWrapper.multiple > .options > li.selected span i,
.page-era #edit-profile-avaluador-field-categorias .SumoSelect .select-all.selected > span i,
.page-era #edit-profile-avaluador-field-categorias .SumoSelect .select-all.partial > span i,
.page-era #edit-profile-avaluador-field-regimen .SumoSelect > .optWrapper.multiple > .options > li.selected span i,
.page-era #edit-profile-avaluador-field-regimen .SumoSelect .select-all.selected > span i,
.page-era #edit-profile-avaluador-field-regimen .SumoSelect .select-all.partial > span i,
.page-era #edit-profile-avaluador-field-certificados-de-experienci-und-0-field-categorias .SumoSelect > .optWrapper.multiple > .options > li.selected span i,
.page-era #edit-profile-avaluador-field-certificados-de-experienci-und-0-field-categorias .SumoSelect .select-all.selected > span i,
.page-era #edit-profile-avaluador-field-certificados-de-experienci-und-0-field-categorias .SumoSelect .select-all.partial > span i,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-objeto-del-certificado .SumoSelect > .optWrapper.multiple > .options > li.selected span i,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-objeto-del-certificado .SumoSelect .select-all.selected > span i,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-objeto-del-certificado .SumoSelect .select-all.partial > span i,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-entidad .SumoSelect > .optWrapper.multiple > .options > li.selected span i,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-entidad .SumoSelect .select-all.selected > span i,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-entidad .SumoSelect .select-all.partial > span i {
  background-image: url("../img/sprites/Check-box-selected.png");
  border: 1px solid white;
}

.page-era #edit-profile-avaluador-field-categorias .SumoSelect > .optWrapper.multiple > .options > li span i,
.page-era #edit-profile-avaluador-field-categorias .SumoSelect .select-all > span i,
.page-era #edit-profile-avaluador-field-regimen .SumoSelect > .optWrapper.multiple > .options > li span i,
.page-era #edit-profile-avaluador-field-regimen .SumoSelect .select-all > span i,
.page-era #edit-profile-avaluador-field-certificados-de-experienci-und-0-field-categorias .SumoSelect > .optWrapper.multiple > .options > li span i,
.page-era #edit-profile-avaluador-field-certificados-de-experienci-und-0-field-categorias .SumoSelect .select-all > span i,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-objeto-del-certificado .SumoSelect > .optWrapper.multiple > .options > li span i,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-objeto-del-certificado .SumoSelect .select-all > span i,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-entidad .SumoSelect > .optWrapper.multiple > .options > li span i,
.page-era #edit-profile-avaluador-field-certificados-de-personas-und-0-field-entidad .SumoSelect .select-all > span i {
  border: 0;
  border-radius: 0;
}

.page-era .form-type-file {
  text-align: right;
}

.page-era .form-type-managed-file label {
  color: #5a83b6;
}

.page-era .field-name-field-certificados-de-experienci,
.page-era .field-name-field-certificados-de-personas {
  padding-top: 40px;
}

.page-era .field-name-field-certificados-de-experienci table,
.page-era .field-name-field-certificados-de-personas table {
  border: 0;
  width: 100%;
  padding: 0;
  background: none;
}

.page-era .field-name-field-certificados-de-experienci tr,
.page-era .field-name-field-certificados-de-experienci td,
.page-era .field-name-field-certificados-de-experienci th,
.page-era .field-name-field-certificados-de-personas tr,
.page-era .field-name-field-certificados-de-personas td,
.page-era .field-name-field-certificados-de-personas th {
  border: 0;
  width: 100%;
  padding: 0;
  background: none;
  display: block;
}

.page-era .field-name-field-certificados-de-experienci tbody tr,
.page-era .field-name-field-certificados-de-personas tbody tr {
  padding-bottom: 20px;
  border-bottom: 1px solid white;
}

.page-era .field-name-field-certificados-de-experienci th label,
.page-era .field-name-field-certificados-de-personas th label {
  margin-bottom: 20px;
  font-size: 1.2em;
  font-weight: 300;
  letter-spacing: 1px;
}

.page-era .field-name-field-certificados-de-experienci .tabledrag-toggle-weight-wrapper,
.page-era .field-name-field-certificados-de-experienci .field-multiple-drag,
.page-era .field-name-field-certificados-de-personas .tabledrag-toggle-weight-wrapper,
.page-era .field-name-field-certificados-de-personas .field-multiple-drag {
  display: none;
}

.page-era .field-name-field-certificados-de-experienci table,
.page-era .field-name-field-certificados-de-personas table {
  padding: 0 10px;
}

.page-era .field-name-field-categoria-experiencia + .form-submit {
  display: inline-block;
  vertical-align: top;
  padding: .45em .6em;
  background: white;
  border: 1px solid white;
  font-size: 1.1em;
  color: #5a83b6;
  font-weight: 100;
  line-height: 100%;
  text-decoration: none;
}

.page-era .field-name-field-documento-experiencia,
.page-era .field-name-field-documento-personas {
  overflow: hidden;
}

.page-era .field-name-field-documento-experiencia .form-submit,
.page-era .field-name-field-documento-personas .form-submit {
  display: inline-block;
  vertical-align: top;
  padding: .45em .6em;
  background: white;
  border: 1px solid white;
  font-size: 1.1em;
  color: #5a83b6;
  font-weight: 100;
  line-height: 100%;
  text-decoration: none;
}

.page-era .field-name-field-documento-experiencia + .form-submit,
.page-era .field-name-field-documento-personas + .form-submit {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  background: none;
  font-size: 1.1em;
  border: 1px solid white;
  color: white;
  font-weight: 100;
  line-height: 100%;
  text-decoration: none;
  margin-top: 10px;
}

.page-era .field-name-field-documento-experiencia .label,
.page-era .field-name-field-documento-personas .label {
  display: inline-block;
  vertical-align: top;
  padding: .45em .6em;
  background: white;
  border: 1px solid white;
  font-size: 1.1em;
  color: #5a83b6;
  font-weight: 100;
  line-height: 100%;
  text-decoration: none;
  color: white;
  font-weight: 100;
  font-size: 1em;
  line-height: 100%;
  color: white;
  background: none;
  border-color: transparent;
  border-left: 0;
  padding-left: 0;
}

.page-era .field-name-field-documento-experiencia .label,
.page-era .field-name-field-documento-experiencia label,
.page-era .field-name-field-documento-experiencia .file-widget,
.page-era .field-name-field-documento-personas .label,
.page-era .field-name-field-documento-personas label,
.page-era .field-name-field-documento-personas .file-widget {
  float: left;
}

.page-era .field-name-field-documento-experiencia label,
.page-era .field-name-field-documento-personas label {
  display: inline-block;
  vertical-align: top;
  padding: .45em .6em;
  background: white;
  border: 1px solid white;
  font-size: 1.1em;
  color: #5a83b6;
  font-weight: 100;
  line-height: 100%;
  text-decoration: none;
  margin-right: 20px;
  cursor: pointer;
}

.page-era .field-name-field-documento-experiencia .form-type-mfw-managed-file,
.page-era .field-name-field-documento-personas .form-type-mfw-managed-file {
  position: relative;
}

.page-era .field-name-field-documento-experiencia .form-file,
.page-era .field-name-field-documento-personas .form-file {
  opacity: 0;
  height: 30px;
  width: 220px;
  position: absolute;
  left: 0;
  top: 0;
}

.page-era .field-name-field-documento-experiencia .form-file:focus + label,
.page-era .field-name-field-documento-personas .form-file:focus + label {
  background-color: black;
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.page-era .field-name-field-documento-experiencia .description,
.page-era .field-name-field-documento-personas .description {
  color: white;
  font-weight: 100;
  font-size: 1em;
  line-height: 100%;
  float: left;
  width: 100%;
  clear: both;
  color: white;
  font-size: 1em;
  text-align: left;
}

.page-era .field-name-field-documento-experiencia .description br,
.page-era .field-name-field-documento-personas .description br {
  display: none;
}

.page-era .file-widget .file {
  display: block;
  margin-right: 20px;
  float: left;
}

.page-era .file-widget .file-icon,
.page-era .file-widget .file-size {
  display: none;
}

.page-era .file-widget a {
  display: inline-block;
  vertical-align: top;
  width: auto;
  color: white;
  border-color: white;
  margin-left: 0;
  margin-right: 0;
  max-width: none;
  white-space: normal;
  word-wrap: break-word;
}

.page-era .file-widget .file {
  display: block;
}

.page-era .image-widget .image-widget-data {
  display: none;
}

.page-era .form-type-select .form-select {
  background-position: 98.2% 50%;
}

.page-era .field-add-more-submit {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  background: none;
  font-size: 1.1em;
  border: 1px solid white;
  color: white;
  font-weight: 100;
  line-height: 100%;
  text-decoration: none;
}

.page-era .field-name-field-documentos-adicionales .file a {
  color: #5a83b6;
}

.page-reports form,
.page-reports .block-form {
  display: inline-block;
  vertical-align: top;
  position: relative;
  max-width: 268px;
  width: 268px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 60px;
  box-sizing: content-box;
}

@media only screen and (max-width: 960px) {
  .page-reports form,
  .page-reports .block-form {
    margin-bottom: 22px;
  }
}

@media only screen and (max-width: 480px) {
  .page-reports form,
  .page-reports .block-form {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
  }
}

.page-reports form .container-inline-date,
.page-reports .block-form .container-inline-date {
  width: 120px;
  float: left;
  clear: none;
}

.page-reports form .container-inline-date + .container-inline-date,
.page-reports .block-form .container-inline-date + .container-inline-date {
  float: right;
}

.page-reports form > div,
.page-reports .block-form > div {
  position: relative;
}

.page-reports form .form-item,
.page-reports .block-form .form-item {
  margin: 0;
  position: relative;
}

.page-reports form .form-item .form-text,
.page-reports .block-form .form-item .form-text {
  background: none;
  border: 1px solid white;
  margin: 0;
  height: 25px;
  padding-top: 3px;
}

.page-reports form .form-item .form-text::placeholder,
.page-reports .block-form .form-item .form-text::placeholder {
  color: #fff;
}

.page-reports form .form-item .form-select,
.page-reports .block-form .form-item .form-select {
  background-color: transparent;
  background-image: url("../img/sprites/Arrow-white-down.png");
  color: white;
  width: 165px;
  margin: 0 auto 10px;
  display: block;
  text-align: left;
  width: auto;
  padding-right: 21px;
  min-width: 93px;
}

.page-reports form .form-item option,
.page-reports .block-form .form-item option {
  max-width: 200px;
  color: #4a4a4a;
  background: white;
  position: relative;
  z-index: 10;
}

.page-reports form .form-actions,
.page-reports .block-form .form-actions {
  text-align: center;
}

@media only screen and (max-width: 480px) {
  .page-reports form .form-actions,
  .page-reports .block-form .form-actions {
    margin-bottom: 22px;
  }
}

@media only screen and (min-width: 480px) {
  .page-reports form .form-actions,
  .page-reports .block-form .form-actions {
    position: absolute;
    bottom: -60px;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.page-reports form .form-actions .form-submit,
.page-reports .block-form .form-actions .form-submit {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  font-size: 1.1em;
  box-shadow: none;
  color: #5a83b6;
  border: 1px solid #5a83b6;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0;
  background-color: #5a83b6;
  border: 1px solid white;
  color: white;
  padding: 11px 30px 10px;
  min-width: 180px;
  font-weight: 100;
  font-size: 1.1em;
  text-transform: none !important;
  letter-spacing: 0.5px;
  border: 0;
}

.page-reports form .form-actions .form-submit:hover,
.page-reports .block-form .form-actions .form-submit:hover {
  color: #446a99;
  border-color: #446a99;
}

.page-reports form .form-actions .form-submit:hover,
.page-reports .block-form .form-actions .form-submit:hover {
  background-color: #446a99;
  color: white;
  border-color: white;
}

.page-reports form .report-container,
.page-reports .block-form .report-container {
  height: 180px;
  padding: 16px 9px 18px;
  margin-bottom: 58px;
}

.page-reports form .report-container::before,
.page-reports .block-form .report-container::before {
  content: '';
  display: table;
}

.page-reports form .report-container::after,
.page-reports .block-form .report-container::after {
  content: '';
  display: table;
  clear: both;
}

@media only screen and (max-width: 480px) {
  .page-reports form .report-container,
  .page-reports .block-form .report-container {
    margin-bottom: 22px;
  }
}

.page-reports form .report-container span,
.page-reports .block-form .report-container span {
  display: block;
  text-align: center;
  color: white;
  line-height: 100%;
  letter-spacing: 1.5px;
}

.page-reports form .report-container span:nth-of-type(1),
.page-reports .block-form .report-container span:nth-of-type(1) {
  font-size: 2.5em;
  font-weight: 600;
  margin-bottom: 2px;
}

.page-reports form .report-container span:nth-of-type(2),
.page-reports .block-form .report-container span:nth-of-type(2) {
  font-family: 'Raleway';
  font-size: 1.5em;
  font-weight: 300;
  line-height: 135%;
  margin-bottom: 15px;
  padding: 0;
  letter-spacing: .5px;
}

.page-reports form label,
.page-reports form .description,
.page-reports .block-form label,
.page-reports .block-form .description {
  display: none;
}

.page-reports form fieldset,
.page-reports .block-form fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

.page-reports .block-form form {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
}

@media only screen and (max-width: 480px) {
  .page-reports .block.block-encabezado .field-name-field-title-descripcion {
    margin-bottom: 0;
  }
}

.page-reports .form-wrapper {
  min-width: initial;
}

.page-reports .main-content {
  overflow: hidden;
}

.page-reports .main-content .block-encabezado {
  padding-bottom: 20px;
  text-align: left;
}

@media only screen and (max-width: 960px) {
  .page-reports .main-content {
    text-align: center;
  }
}

@media only screen and (min-width: 960px) {
  .page-reports .main-content {
    margin-left: -20px;
    margin-right: -20px;
    text-align: left;
  }
  .page-reports .main-content > h1,
  .page-reports .main-content .block-encabezado {
    padding: 0 20px 40px;
  }
  .page-reports .main-content > h1 {
    padding-bottom: 0;
  }
}

.page-reports .date-padding .form-text {
  color: white;
}

.page-reports #avaluadores-form span:nth-of-type(2) {
  text-transform: uppercase;
  font-size: 1.62em;
}

.page-reports #avaluadores-form .report-container {
  background-color: #df5c58;
}

.page-reports #avaluadores-form .form-select {
  max-width: 160px;
  margin-left: auto;
  margin-right: auto;
}

.page-reports #sancionados-form span:nth-of-type(2) {
  text-transform: uppercase;
  font-size: 1.62em;
}

.page-reports #sancionados-form .report-container {
  background-color: #f2b630;
}

.page-reports #tiempos-form .report-container > span:nth-of-type(1) {
  padding-top: 23px;
  margin-bottom: 12px;
}

.page-reports #tiempos-form .report-container > span:nth-of-type(2) {
  margin-bottom: 13px;
}

.page-reports #tiempos-form span:nth-of-type(1) {
  padding-top: 9px !important;
}

@media only screen and (max-width: 480px) {
  .page-reports #tiempos-form {
    margin-bottom: 22px;
  }
}

.page-reports #tiempos-form .report-container {
  background-color: #4a4a4a;
}

.page-reports #tiempos-form .report-container > span {
  text-transform: none;
}

.page-reports #tiempos-form .report-container > span:nth-of-type(2) {
  font-size: 1.2em;
  line-height: 125%;
}

@media only screen and (max-width: 800px) {
  .page-reports #tiempos-form .report-container {
    margin-bottom: 0;
  }
}

.page-reports #historico-avaluadores-form .report-container > span:nth-of-type(1) {
  padding-top: 23px;
  margin-bottom: 12px;
}

.page-reports #historico-avaluadores-form .report-container > span:nth-of-type(2) {
  margin-bottom: 13px;
}

.page-reports #historico-avaluadores-form span:nth-of-type(2) {
  text-transform: uppercase;
  font-size: 1.62em;
}

.page-reports #historico-avaluadores-form .report-container {
  background-color: #5b83b7;
}

.page-reports #detalle-avaluadores-form .report-container > span:nth-of-type(1) {
  padding-top: 23px;
  margin-bottom: 12px;
}

.page-reports #detalle-avaluadores-form .report-container > span:nth-of-type(2) {
  margin-bottom: 13px;
}

.page-reports #detalle-avaluadores-form span:nth-of-type(2) {
  text-transform: uppercase;
  font-size: 1.62em;
}

@media only screen and (max-width: 800px) {
  .page-reports #detalle-avaluadores-form {
    margin-bottom: 102px;
  }
}

.page-reports #detalle-avaluadores-form .report-container {
  background-color: #456ca3;
  margin-bottom: 0;
  padding-top: 40px;
}

.page-reports #detalle-avaluadores-form .form-item {
  margin-left: -9px;
  margin-right: -9px;
  position: static;
}

.page-reports #detalle-avaluadores-form .form-text {
  position: absolute;
  bottom: -50px;
  left: 0;
  right: auto;
  margin: auto;
  border-color: #456ca3;
  padding: 5px 10px 3px;
  height: 30px;
}

.page-reports #detalle-avaluadores-form .form-actions {
  bottom: -82px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
}

.page-reports #detalle-avaluadores-form .form-actions .form-submit {
  padding-top: 5px;
  padding-bottom: 6px;
  width: 100%;
}

.page-reports #solicitudes-form .report-container > span:nth-of-type(1) {
  padding-top: 23px;
  margin-bottom: 12px;
}

.page-reports #solicitudes-form .report-container > span:nth-of-type(2) {
  margin-bottom: 13px;
}

.page-reports #solicitudes-form span:nth-of-type(2) {
  text-transform: uppercase;
  font-size: 1.62em;
}

.page-reports #solicitudes-form .report-container {
  background-color: #797e7f;
}

.page-reports #solicitudes-form .report-container > span:nth-of-type(1) {
  padding-top: 5px;
}

.page-reports [id*="estado-avaluadores"] .report-container {
  background-color: #5b83b7;
}

.page-reports [id*="estado-avaluadores"] .report-container .form-select {
  border: 1px solid #fff;
}

.page-reports [id*="estado-avaluadores"] .fieldset-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
}

.page-reports [id*="estado-avaluadores"] .fieldset-wrapper > span {
  text-align: center;
  width: 100%;
}

.page-reports [id*="estado-avaluadores"] .form-type-select {
  width: 48%;
}

.page-reports [id*="estado-avaluadores"] .form-type-select::after {
  display: none;
}

.page-reports .report-history {
  max-width: 800px;
  margin: 20px 0 0;
  padding: 20px 0;
  border-top: 1px solid #797E7F;
}

.page-reports .report-history li {
  display: inline-block;
  vertical-align: top;
  list-style: none;
  margin-right: 10px;
}

.page-reports .report-history a {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  font-size: 1.1em;
  box-shadow: none;
  color: #5a83b6;
  border: 1px solid #5a83b6;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0;
}

.page-reports .report-history a:hover {
  color: #446a99;
  border-color: #446a99;
}

.page-raa-reports form,
.page-raa-reports .block-form {
  display: inline-block;
  vertical-align: top;
  position: relative;
  max-width: 268px;
  width: 268px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 60px;
  box-sizing: content-box;
}

@media only screen and (max-width: 960px) {
  .page-raa-reports form,
  .page-raa-reports .block-form {
    margin-bottom: 22px;
  }
}

@media only screen and (max-width: 480px) {
  .page-raa-reports form,
  .page-raa-reports .block-form {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
  }
}

.page-raa-reports form .container-inline-date,
.page-raa-reports .block-form .container-inline-date {
  width: 120px;
  float: left;
  clear: none;
}

.page-raa-reports form .container-inline-date + .container-inline-date,
.page-raa-reports .block-form .container-inline-date + .container-inline-date {
  float: right;
}

.page-raa-reports form > div,
.page-raa-reports .block-form > div {
  position: relative;
}

.page-raa-reports form .form-item,
.page-raa-reports .block-form .form-item {
  margin: 0;
  position: relative;
}

.page-raa-reports form .form-item .form-text,
.page-raa-reports .block-form .form-item .form-text {
  background: none;
  border: 1px solid white;
  margin: 0;
  height: 25px;
  padding-top: 3px;
}

.page-raa-reports form .form-item .form-text::placeholder,
.page-raa-reports .block-form .form-item .form-text::placeholder {
  color: #fff;
}

.page-raa-reports form .form-item .form-select,
.page-raa-reports .block-form .form-item .form-select {
  background-color: transparent;
  background-image: url("../img/sprites/Arrow-white-down.png");
  color: white;
  width: 165px;
  margin: 0 auto 10px;
  display: block;
  text-align: left;
  width: auto;
  padding-right: 21px;
  min-width: 93px;
}

.page-raa-reports form .form-item option,
.page-raa-reports .block-form .form-item option {
  max-width: 200px;
  color: #4a4a4a;
  background: white;
  position: relative;
  z-index: 10;
}

.page-raa-reports form .form-actions,
.page-raa-reports .block-form .form-actions {
  text-align: center;
}

@media only screen and (max-width: 480px) {
  .page-raa-reports form .form-actions,
  .page-raa-reports .block-form .form-actions {
    margin-bottom: 22px;
  }
}

@media only screen and (min-width: 480px) {
  .page-raa-reports form .form-actions,
  .page-raa-reports .block-form .form-actions {
    position: absolute;
    bottom: -60px;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.page-raa-reports form .form-actions .form-submit,
.page-raa-reports .block-form .form-actions .form-submit {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  font-size: 1.1em;
  box-shadow: none;
  color: #5a83b6;
  border: 1px solid #5a83b6;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0;
  background-color: #5a83b6;
  border: 1px solid white;
  color: white;
  padding: 11px 30px 10px;
  min-width: 180px;
  font-weight: 100;
  font-size: 1.1em;
  text-transform: none !important;
  letter-spacing: 0.5px;
  border: 0;
}

.page-raa-reports form .form-actions .form-submit:hover,
.page-raa-reports .block-form .form-actions .form-submit:hover {
  color: #446a99;
  border-color: #446a99;
}

.page-raa-reports form .form-actions .form-submit:hover,
.page-raa-reports .block-form .form-actions .form-submit:hover {
  background-color: #446a99;
  color: white;
  border-color: white;
}

.page-raa-reports form .report-container,
.page-raa-reports .block-form .report-container {
  height: 180px;
  padding: 16px 9px 18px;
  margin-bottom: 58px;
}

.page-raa-reports form .report-container::before,
.page-raa-reports .block-form .report-container::before {
  content: '';
  display: table;
}

.page-raa-reports form .report-container::after,
.page-raa-reports .block-form .report-container::after {
  content: '';
  display: table;
  clear: both;
}

@media only screen and (max-width: 480px) {
  .page-raa-reports form .report-container,
  .page-raa-reports .block-form .report-container {
    margin-bottom: 22px;
  }
}

.page-raa-reports form .report-container span,
.page-raa-reports .block-form .report-container span {
  display: block;
  text-align: center;
  color: white;
  line-height: 100%;
  letter-spacing: 1.5px;
}

.page-raa-reports form .report-container span:nth-of-type(1),
.page-raa-reports .block-form .report-container span:nth-of-type(1) {
  font-size: 2.5em;
  font-weight: 600;
  margin-bottom: 2px;
}

.page-raa-reports form .report-container span:nth-of-type(2),
.page-raa-reports .block-form .report-container span:nth-of-type(2) {
  font-family: 'Raleway';
  font-size: 1.5em;
  font-weight: 300;
  line-height: 135%;
  margin-bottom: 15px;
  padding: 0;
  letter-spacing: .5px;
}

.page-raa-reports form label,
.page-raa-reports form .description,
.page-raa-reports .block-form label,
.page-raa-reports .block-form .description {
  display: none;
}

.page-raa-reports form fieldset,
.page-raa-reports .block-form fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

.page-raa-reports .block-form form {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
}

@media only screen and (max-width: 480px) {
  .page-raa-reports .block.block-encabezado .field-name-field-title-descripcion {
    margin-bottom: 0;
  }
}

.page-raa-reports .form-wrapper {
  min-width: initial;
}

.page-raa-reports .main-content {
  overflow: hidden;
}

.page-raa-reports .main-content .block-encabezado {
  padding-bottom: 20px;
  text-align: left;
}

@media only screen and (max-width: 960px) {
  .page-raa-reports .main-content {
    text-align: center;
  }
}

@media only screen and (min-width: 960px) {
  .page-raa-reports .main-content {
    margin-left: -20px;
    margin-right: -20px;
    text-align: left;
  }
  .page-raa-reports .main-content > h1,
  .page-raa-reports .main-content .block-encabezado {
    padding: 0 20px 40px;
  }
  .page-raa-reports .main-content > h1 {
    padding-bottom: 0;
  }
}

.page-raa-reports .date-padding .form-text {
  color: white;
}

.page-raa-reports #raa-era-form .report-container {
  background-color: #df5b5b;
}

.page-raa-reports #eras-register-wrapper .report-container {
  background-color: #f2b630;
}

.page-raa-reports #avaluadores-wrapper .report-container {
  background-color: #4a4a4a;
}

.page-raa-reports #avaluadores-wrapper .form-type-select {
  display: inline-block;
  vertical-align: top;
  width: 49%;
}

.page-raa-reports #numero-avaluadores-wrapper .report-container {
  background-color: #5c84b3;
}

.page-raa-reports #numero-avaluadores-wrapper .report-container .form-type-select {
  display: inline-block;
  vertical-align: top;
  width: 49%;
}

.page-raa-reports #numero-avaluadores-wrapper .report-container .form-type-select select {
  margin: 0px;
}

.page-raa-reports #tiempo-aprovacion-wrapper span:nth-of-type(2) {
  text-transform: uppercase;
  font-size: 1.62em;
}

.page-raa-reports #tiempo-aprovacion-wrapper .time-average {
  color: white;
  text-align: center;
}

.page-raa-reports #tiempo-aprovacion-wrapper .report-container {
  background-color: #466c9e;
}

.page-raa-reports #tiempo-aprovacion-wrapper .report-container span {
  font-weight: 200;
  font-size: 18px;
  margin-top: 30px;
}

.page-raa-reports #tiempo-aprovacion-wrapper .report-container span:nth-of-type(1) {
  font-size: 1.5em;
  line-height: 135%;
  margin-top: 20px;
}

.page-raa-reports #raa-historico-avaluadores-form .report-container {
  background-color: #797e7f;
}

.page-raa-reports #raa-historico-avaluadores-form .report-container > span:nth-of-type(1) {
  padding-top: 24px;
}

.page-raa-reports #raa-detalle-avaluadores-form .report-container {
  background-color: #df5b5b;
}

.page-raa-reports #solicitud-registro-wrapper .report-container {
  background-color: #f2b630;
}

.page-raa-reports #regimen-avaluadores-wrapper .report-container {
  background-color: #5c84b3;
}

.page-raa-reports [id*="estado-avaluadores"] .report-container {
  background-color: #5b83b7;
}

.page-raa-reports [id*="estado-avaluadores"] .report-container .form-select {
  border: 1px solid #fff;
}

.page-raa-reports [id*="estado-avaluadores"] .fieldset-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
}

.page-raa-reports [id*="estado-avaluadores"] .fieldset-wrapper > span {
  text-align: center;
  width: 100%;
}

.page-raa-reports [id*="estado-avaluadores"] .form-type-select {
  width: 48%;
}

.page-raa-reports [id*="estado-avaluadores"] .form-type-select::after {
  display: none;
}

/*.page-respuestas{
  .views-field-field-decision-info{
    .review{
      display: none;
    }
  }
}
*/
.page-reports-historico-avaluadores .main-content {
  margin-left: 0;
  margin-right: 0;
}

.page-reports-historico-avaluadores .main-content > h1 {
  padding: 0;
}

.page-reports-historico-avaluadores .avaluador {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d8d8d9;
  font-size: .87em;
  color: #a4a4a4;
}

.page-reports-historico-avaluadores .avaluador .label {
  color: #5a83b6;
}

.node-sancion-form .subtitle {
  border-bottom: 1px solid #bdbfc0;
  margin-bottom: 24px;
}

@media only screen and (max-width: 480px) {
  .node-sancion-form .subtitle {
    margin-bottom: 12px;
    border-width: 2px;
  }
}

.node-sancion-form h4 {
  text-align: center;
  color: #6e91c0;
  font-size: 1em;
}

.node-sancion-form div.form-item,
.node-sancion-form div.form-wrapper {
  max-width: 400px !important;
  margin-left: auto;
  margin-right: auto;
}

.node-sancion-form div.form-field-type-datetime {
  max-width: 200px;
  margin-bottom: 10px;
  border: 1px solid #d8d8d9;
}

.node-sancion-form div.form-field-type-datetime::before {
  content: '';
  display: table;
}

.node-sancion-form div.form-field-type-datetime::after {
  content: '';
  display: table;
  clear: both;
}

.node-sancion-form div.form-field-type-datetime .form-item {
  margin: 0;
}

.node-sancion-form div.form-field-type-datetime .form-text {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  height: 30px;
  padding: 1px 10px 3px;
  border: 1px solid transparent;
  padding: 2px 0;
}

.node-sancion-form div.form-field-type-datetime fieldset,
.node-sancion-form div.form-field-type-datetime legend,
.node-sancion-form div.form-field-type-datetime fieldset.date-combo .container-inline-date .date-padding {
  padding: 0;
  border: 0;
  border: 0;
  margin: 0;
}

.node-sancion-form div.form-field-type-datetime legend {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  height: 30px;
  padding: 1px 10px 3px;
  margin-right: 10px;
  display: block;
  white-space: nowrap;
  float: left;
  padding: 6px 0 2px 10px;
  position: relative;
}

.node-sancion-form div.form-field-type-datetime legend .fieldset-legend {
  display: block;
  overflow: hidden;
}

.node-sancion-form div.form-field-type-datetime legend::after {
  content: ": ";
  position: absolute;
  top: 6px;
  left: 100%;
}

.node-sancion-form div.form-field-type-datetime .fieldset-wrapper {
  float: left;
  width: 80px;
}

.node-sancion-form div.form-field-type-datetime label,
.node-sancion-form div.form-field-type-datetime .description {
  display: none;
}

.node-sancion-form div.form-field-type-datetime .container-inline-date > .form-item {
  margin-right: 0;
  display: block;
}

.node-sancion-form .form-field-type-list-text {
  border: 1px solid #d8d8d9;
  margin-bottom: 10px;
  max-width: 300px;
}

.node-sancion-form .form-field-type-list-text .form-item {
  display: table;
  width: 100%;
  margin: 0;
}

.node-sancion-form .form-field-type-list-text label {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  padding-left: 10px;
  margin: 0;
  display: table-cell;
  width: 135px;
}

.node-sancion-form .form-field-type-list-text select {
  display: table-cell;
}

.node-sancion-form #edit-body .form-item {
  border: 1px solid #d8d8d9;
  margin-bottom: 10px;
}

.node-sancion-form #edit-body .form-item label {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  padding: 6px 0 0 10px;
  margin: 0 0 2px;
}

.node-sancion-form #edit-body .form-item textarea {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  border: 0;
  padding: 0 10px;
  min-height: 64px;
  height: 64px;
}

@media only screen and (max-width: 480px) {
  .node-sancion-form #edit-body .form-item textarea {
    min-height: 67px;
    height: 67px;
  }
}

.node-sancion-form .form-field-name-field-avaluador {
  max-width: 100%;
  margin-bottom: 10px;
}

.node-sancion-form .form-field-name-field-avaluador label {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  margin-bottom: 8px;
}

.node-sancion-form .form-field-name-field-avaluador .form-text {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  height: 30px;
  padding: 1px 10px 3px;
  border: 1px solid #d8d8d9;
}

.node-sancion-form .form-item-title {
  max-width: 100%;
  margin-bottom: 10px;
}

.node-sancion-form .form-item-title label {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  margin-bottom: 8px;
}

.node-sancion-form .form-item-title .form-text {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  height: 30px;
  padding: 1px 10px 3px;
  border: 1px solid #d8d8d9;
}

.node-sancion-form #edit-actions {
  padding: 15px 0 30px;
  text-align: center;
}

.node-sancion-form #edit-actions .form-submit {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  font-size: 1.1em;
  box-shadow: none;
  color: #5a83b6;
  border: 1px solid #5a83b6;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0;
  background-color: #5a83b6;
  border: 1px solid white;
  color: white;
}

.node-sancion-form #edit-actions .form-submit:hover {
  color: #446a99;
  border-color: #446a99;
}

.node-sancion-form #edit-actions .form-submit:hover {
  background-color: #446a99;
  color: white;
  border-color: white;
}

.node-sancion-form div.form-field-type-datetime {
  max-width: none;
}

.node-comite-form div.form-item-title {
  max-width: 200px;
  margin-bottom: 10px;
}

.node-comite-form div.form-item-title label {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  margin-bottom: 8px;
}

.node-comite-form div.form-item-title .form-text {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  height: 30px;
  padding: 1px 10px 3px;
  border: 1px solid #d8d8d9;
}

.node-comite-form .form-field-name-field-number-comite {
  max-width: 200px;
  margin-bottom: 10px;
}

.node-comite-form .form-field-name-field-number-comite label {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  margin-bottom: 8px;
}

.node-comite-form .form-field-name-field-number-comite .form-text {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  height: 30px;
  padding: 1px 10px 3px;
  border: 1px solid #d8d8d9;
}

.node-comite-form div.form-field-type-datetime {
  max-width: 300px;
  margin-bottom: 10px;
  border: 1px solid #d8d8d9;
}

.node-comite-form div.form-field-type-datetime::before {
  content: '';
  display: table;
}

.node-comite-form div.form-field-type-datetime::after {
  content: '';
  display: table;
  clear: both;
}

.node-comite-form div.form-field-type-datetime .form-item {
  margin: 0;
}

.node-comite-form div.form-field-type-datetime .form-text {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  height: 30px;
  padding: 1px 10px 3px;
  border: 1px solid transparent;
  padding: 2px 0;
}

.node-comite-form div.form-field-type-datetime fieldset,
.node-comite-form div.form-field-type-datetime legend,
.node-comite-form div.form-field-type-datetime fieldset.date-combo .container-inline-date .date-padding {
  padding: 0;
  border: 0;
  border: 0;
  margin: 0;
}

.node-comite-form div.form-field-type-datetime legend {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  height: 30px;
  padding: 1px 10px 3px;
  margin-right: 10px;
  display: block;
  white-space: nowrap;
  float: left;
  padding: 6px 0 2px 10px;
  position: relative;
}

.node-comite-form div.form-field-type-datetime legend .fieldset-legend {
  display: block;
  overflow: hidden;
}

.node-comite-form div.form-field-type-datetime legend::after {
  content: ": ";
  position: absolute;
  top: 6px;
  left: 100%;
}

.node-comite-form div.form-field-type-datetime .fieldset-wrapper {
  float: left;
  width: 80px;
}

.node-comite-form div.form-field-type-datetime label,
.node-comite-form div.form-field-type-datetime .description {
  display: none;
}

.node-comite-form div.form-field-type-datetime .container-inline-date > .form-item {
  margin-right: 0;
  display: block;
}

.node-comite-form .form-type-managed-file label {
  height: 30px;
  line-height: 100%;
  padding: 9px 8px 8px;
}

.node-comite-form .form-managed-file .form-submit {
  font-size: 0.857em;
  text-transform: uppercase;
  padding: 6px 10px;
  line-height: inherit;
}

.node-comite-form .form-field-name-field-funcionarios {
  width: 100%;
  padding-top: 25px;
}

.node-comite-form .form-field-name-field-funcionarios .tabledrag-toggle-weight-wrapper {
  display: none;
}

.node-comite-form .form-field-name-field-funcionarios .field-label {
  text-align: left;
  padding: 0;
  border: 0;
  position: relative;
  display: block;
}

.node-comite-form .form-field-name-field-funcionarios .field-label > label {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  margin: 0;
  position: absolute;
  bottom: 7px;
  left: 0;
  display: block;
}

.node-comite-form .form-field-name-field-funcionarios .field-label > label > .form-required {
  position: absolute;
  left: 101%;
  top: 0;
}

.node-comite-form .form-field-name-field-funcionarios .field-multiple-table {
  max-width: 400px;
  float: left;
  margin-right: 25px;
}

.node-comite-form .form-field-name-field-funcionarios .field-multiple-table + .clearfix {
  display: block;
  clear: none;
}

.node-comite-form .form-field-name-field-funcionarios .field-multiple-table::before {
  display: none;
}

.node-comite-form .form-field-name-field-funcionarios .form-text {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  height: 30px;
  padding: 1px 10px 3px;
  border: 1px solid #d8d8d9;
  max-width: 365px;
  width: 100%;
}

.node-comite-form .form-field-name-field-funcionarios .form-submit {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  font-size: 1.1em;
  box-shadow: none;
  color: #5a83b6;
  border: 1px solid #5a83b6;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0;
  line-height: 100%;
  font-size: 0.857em;
  padding: 8px;
  text-transform: none;
}

.node-comite-form .form-field-name-field-funcionarios .form-submit:hover {
  color: #446a99;
  border-color: #446a99;
}

.node-comite-form .form-field-name-field-funcionarios tr,
.node-comite-form .form-field-name-field-funcionarios th,
.node-comite-form .form-field-name-field-funcionarios td {
  border: 0;
  padding: 0;
  vertical-align: top;
}

.node-comite-form .form-field-name-field-funcionarios tbody tr {
  display: block;
  margin-bottom: 5px;
}

.node-comite-form .form-field-name-field-funcionarios .field-add-more-submit {
  margin: 0;
}

@media only screen and (max-width: 480px) {
  .node-comite-form .form-field-name-field-funcionarios .field-add-more-submit {
    display: block;
    margin: 0 auto;
    float: none;
  }
}

.node-comite-form .form-field-name-field-funcionarios .form-item {
  margin-bottom: 0;
  position: relative;
}

.node-comite-form .form-field-name-field-funcionarios .form-item > label {
  margin: 0;
  position: absolute;
  top: 0;
  left: 102%;
}

.node-comite-form .form-field-name-field-funcionarios .form-item > .form-text {
  padding-top: 2px;
  padding-bottom: 2px;
}

.node-comite-form .form-field-name-field-funcionarios .field-multiple-drag {
  width: 30px;
  padding-left: 0;
  padding-right: 10px;
}

.node-comite-form .form-field-name-field-funcionarios .field-multiple-drag .tabledrag-handle {
  display: block;
  min-width: inherit;
  width: 27px;
  padding: 0;
  margin: 0;
  height: auto;
  border-color: #d8d8d9;
}

.node-comite-form .form-field-name-field-funcionarios .field-multiple-drag .tabledrag-handle .handle {
  width: 27px;
  height: 28px;
  margin: 0;
  padding: 0;
  background-position: 7px 8px;
  border-color: #d8d8d9;
}

.node-comite-form .search-container {
  position: relative;
  padding-top: 13px;
}

.node-comite-form .search-container label {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  margin-bottom: 8px;
}

.node-comite-form .search-container .form-wrapper {
  position: relative;
  max-width: 370px;
}

.node-comite-form .search-container .form-text {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  height: 30px;
  padding: 1px 10px 3px;
  border: 1px solid #d8d8d9;
  max-width: 88%;
  position: relative;
  z-index: 3;
}

.node-comite-form .search-container .form-submit {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  font-size: 1.1em;
  box-shadow: none;
  color: #5a83b6;
  border: 1px solid #5a83b6;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0;
  background-color: #5a83b6;
  color: white;
  padding: 6px 10px;
  font-size: .8em;
  border: 0;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
}

.node-comite-form .search-container .form-submit:hover {
  color: #446a99;
  border-color: #446a99;
}

.node-comite-form .search-container .form-submit:hover {
  background-color: #446a99;
  color: white;
  border-color: white;
}

.node-comite-form #edit-ana-avaluadores-agregados {
  padding-top: 8px;
}

.node-comite-form #edit-ana-avaluadores-agregados label {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  margin-bottom: 8px;
}

.node-comite-form #edit-ana-avaluadores-agregados th {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  text-align: center;
}

.node-comite-form #edit-ana-avaluadores-agregados th:nth-of-type(1) {
  text-align: left;
}

.node-comite-form #edit-actions {
  padding: 15px 0 30px;
  text-align: center;
}

.node-comite-form #edit-actions .form-submit {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  font-size: 1.1em;
  box-shadow: none;
  color: #5a83b6;
  border: 1px solid #5a83b6;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0;
  background-color: #5a83b6;
  border: 1px solid white;
  color: white;
}

.node-comite-form #edit-actions .form-submit:hover {
  color: #446a99;
  border-color: #446a99;
}

.node-comite-form #edit-actions .form-submit:hover {
  background-color: #446a99;
  color: white;
  border-color: white;
}

.node-comite-form .subtitle, .node-comite-form .form-field-name-field-funcionarios, .node-comite-form .form-field-name-field-decision, .node-comite-form .search-container {
  padding-bottom: 16px;
  border-bottom: 1px solid #797e7f;
}

.node-comite-form .form-field-name-field-decision {
  display: none;
}

.ff.page-raa-reports .block-form {
  overflow: hidden;
}

.ff.page-raa-reports .form-type-select {
  position: relative;
}

.ff.page-raa-reports .form-type-select::after {
  content: "";
  width: 27px;
  height: 12px;
  position: absolute;
  top: 9px;
  right: -1px;
  z-index: 20;
  background: url("../img/sprites/Arrow-white-down.png") no-repeat 100% 50%;
}

.ff.page-raa-reports .fieldset-wrapper {
  max-width: 250px;
}

.ff.page-raa-reports .fieldset-wrapper select {
  box-sizing: content-box;
  padding-right: 0 !important;
  cursor: pointer;
}

.ff.page-raa-reports .fieldset-wrapper option {
  max-width: none;
  background-color: white;
}

.ff.page-raa-reports .fieldset-wrapper .form-type-select {
  position: relative;
  z-index: 20;
}

.ff.page-raa-reports .fieldset-wrapper .form-type-select + .form-type-select {
  z-index: 10;
}

.ff.page-raa-reports #avaluadores-wrapper .form-type-select::after {
  background-color: #4a4a4a;
}

.ff.page-raa-reports #avaluadores-wrapper .form-item-categorias select {
  padding-left: 260px;
  margin-left: -260px;
}

.ff.page-raa-reports #avaluadores-wrapper .form-item-categorias option {
  width: 520px;
}

.ff.page-raa-reports #avaluadores-wrapper .form-item-era select {
  padding-left: 210px;
  margin-left: -210px;
}

.ff.page-raa-reports #avaluadores-wrapper .form-item-era option {
  width: 420px;
}

.ff.page-raa-reports #numero-avaluadores-wrapper .form-type-select::after {
  background-color: #5c84b3;
}

.ff.page-raa-reports #numero-avaluadores-wrapper .form-item-era select {
  padding-left: 210px;
  margin-left: -210px;
}

.ff.page-raa-reports #numero-avaluadores-wrapper .form-item-era option {
  width: 420px;
}

.ff.page-raa-reports #numero-avaluadores-wrapper .form-item-estado select {
  padding-left: 120px;
  margin-left: -120px;
}

.ff.page-raa-reports #numero-avaluadores-wrapper .form-item-estado option {
  width: 240px;
}

.ff.page-raa-reports #tiempo-aprovacion-wrapper .form-type-select::after {
  background-color: #466c9e;
}

.ff.page-raa-reports #solicitud-registro-wrapper .form-type-select::after {
  background-color: #f2b630;
}

.ff.page-raa-reports #regimen-avaluadores-wrapper .form-type-select::after {
  background-color: #5c84b3;
}

.ff.page-raa-reports #regimen-avaluadores-wrapper .form-type-select::after {
  right: 10px;
}

.ff.page-raa-reports #regimen-avaluadores-wrapper select {
  padding-left: 200px;
  margin-left: -185px;
  background-color: transparent;
  width: 90%;
  max-width: none;
  padding-right: 0;
  box-sizing: content-box;
  background-image: none;
}

@media only screen and (max-width: 610px) {
  .ff.page-raa-reports #regimen-avaluadores-wrapper select {
    padding-left: 40px;
    margin-left: -25px;
  }
}

.ff.page-raa-reports #regimen-avaluadores-wrapper option {
  background-color: white;
  width: 600px;
  max-width: none;
}

@media only screen and (max-width: 610px) {
  .ff.page-raa-reports #regimen-avaluadores-wrapper option {
    width: 300px;
  }
}

.ff.page-reports form .form-item .form-select {
  min-width: 100px;
}

.ff.page-reports #avaluadores-form .form-select {
  max-width: 170px;
}

.chrome {
  /**/
}

.ie.page-raa-reports #regimen-avaluadores-wrapper select {
  padding-left: 200px;
  margin-left: -185px;
  background-color: transparent;
  width: 90%;
  max-width: none;
  padding-right: 0;
  box-sizing: content-box;
}

@media only screen and (max-width: 610px) {
  .ie.page-raa-reports #regimen-avaluadores-wrapper select {
    padding-left: 40px;
    margin-left: -25px;
  }
}

.ie.page-raa-reports #regimen-avaluadores-wrapper option {
  background-color: white;
  width: 600px;
  max-width: none;
}

@media only screen and (max-width: 610px) {
  .ie.page-raa-reports #regimen-avaluadores-wrapper option {
    width: 300px;
  }
}

.page-group-add #dhtml_menu-670 a,
.page-group-edit #dhtml_menu-670 a {
  color: #d8d8d9 !important;
}

.page-group-add .form-type-textfield,
.page-group-edit .form-type-textfield {
  max-width: 200px;
  margin-bottom: 10px;
}

.page-group-add .form-type-textfield label,
.page-group-edit .form-type-textfield label {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  margin-bottom: 8px;
}

.page-group-add .form-type-textfield .form-text,
.page-group-edit .form-type-textfield .form-text {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  height: 30px;
  padding: 1px 10px 3px;
  border: 1px solid #d8d8d9;
}

.page-group-add .field-widget-options-select,
.page-group-edit .field-widget-options-select {
  max-width: 200px;
  margin-bottom: 10px;
}

.page-group-add .field-widget-options-select label,
.page-group-edit .field-widget-options-select label {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  margin-bottom: 8px;
}

.page-group-add .field-widget-options-select .form-select,
.page-group-edit .field-widget-options-select .form-select {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  height: 30px;
  padding: 1px 10px 3px;
  border: 1px solid #d8d8d9;
}

.page-group-add .field-type-datetime,
.page-group-edit .field-type-datetime {
  max-width: 200px;
  margin-bottom: 10px;
}

.page-group-add .field-type-datetime legend,
.page-group-add .field-type-datetime .form-item,
.page-group-add .field-type-datetime fieldset,
.page-group-add .field-type-datetime .form-item .date-padding,
.page-group-edit .field-type-datetime legend,
.page-group-edit .field-type-datetime .form-item,
.page-group-edit .field-type-datetime fieldset,
.page-group-edit .field-type-datetime .form-item .date-padding {
  border: 0;
  padding: 0;
  margin: 0;
}

.page-group-add .field-type-datetime .form-item .date-padding,
.page-group-add .field-type-datetime .date-no-float,
.page-group-edit .field-type-datetime .form-item .date-padding,
.page-group-edit .field-type-datetime .date-no-float {
  width: 100%;
}

.page-group-add .field-type-datetime legend,
.page-group-edit .field-type-datetime legend {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  margin-bottom: 8px;
  line-height: 100%;
}

.page-group-add .field-type-datetime .form-text,
.page-group-edit .field-type-datetime .form-text {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  height: 30px;
  padding: 1px 10px 3px;
  border: 1px solid #d8d8d9;
  margin-right: 0;
  width: 100%;
}

.page-group-add .field-type-datetime .form-item,
.page-group-edit .field-type-datetime .form-item {
  width: 100%;
}

.page-group-add .field-type-datetime .form-item label,
.page-group-edit .field-type-datetime .form-item label {
  display: none;
}

.page-group-add .field-type-datetime .description,
.page-group-edit .field-type-datetime .description {
  display: none;
}

.page-group-add .form-actions,
.page-group-edit .form-actions {
  padding: 15px 0 30px;
  text-align: center;
}

.page-group-add .form-actions .form-submit,
.page-group-edit .form-actions .form-submit {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  font-size: 1.1em;
  box-shadow: none;
  color: #5a83b6;
  border: 1px solid #5a83b6;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0;
  background-color: #5a83b6;
  border: 1px solid white;
  color: white;
}

.page-group-add .form-actions .form-submit:hover,
.page-group-edit .form-actions .form-submit:hover {
  color: #446a99;
  border-color: #446a99;
}

.page-group-add .form-actions .form-submit:hover,
.page-group-edit .form-actions .form-submit:hover {
  background-color: #446a99;
  color: white;
  border-color: white;
}

.page-group-add form,
.page-group-edit form {
  clear: both;
  width: 100%;
  border-top: 1px solid #797E7F;
  margin-top: 10px;
  padding-top: 10px;
}

.page-taxonomy-term-edit #dhtml_menu-671 a, .page-admin-structure-taxonomy-categorias-avaluadores #dhtml_menu-671 a {
  color: #d8d8d9 !important;
}

.page-taxonomy-term-edit .form-type-textfield, .page-admin-structure-taxonomy-categorias-avaluadores .form-type-textfield {
  max-width: 200px;
  margin-bottom: 10px;
}

.page-taxonomy-term-edit .form-type-textfield label, .page-admin-structure-taxonomy-categorias-avaluadores .form-type-textfield label {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  margin-bottom: 8px;
}

.page-taxonomy-term-edit .form-type-textfield .form-text, .page-admin-structure-taxonomy-categorias-avaluadores .form-type-textfield .form-text {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  height: 30px;
  padding: 1px 10px 3px;
  border: 1px solid #d8d8d9;
}

.page-taxonomy-term-edit .form-actions, .page-admin-structure-taxonomy-categorias-avaluadores .form-actions {
  padding: 15px 0 30px;
  text-align: center;
}

.page-taxonomy-term-edit .form-actions .form-submit, .page-admin-structure-taxonomy-categorias-avaluadores .form-actions .form-submit {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  font-size: 1.1em;
  box-shadow: none;
  color: #5a83b6;
  border: 1px solid #5a83b6;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0;
  background-color: #5a83b6;
  border: 1px solid white;
  color: white;
}

.page-taxonomy-term-edit .form-actions .form-submit:hover, .page-admin-structure-taxonomy-categorias-avaluadores .form-actions .form-submit:hover {
  color: #446a99;
  border-color: #446a99;
}

.page-taxonomy-term-edit .form-actions .form-submit:hover, .page-admin-structure-taxonomy-categorias-avaluadores .form-actions .form-submit:hover {
  background-color: #446a99;
  color: white;
  border-color: white;
}

.page-taxonomy-term-edit #taxonomy-form-term, .page-admin-structure-taxonomy-categorias-avaluadores #taxonomy-form-term {
  clear: both;
  width: 100%;
  border-top: 1px solid #797E7F;
  margin-top: 10px;
  padding-top: 10px;
}

.page-taxonomy-term-edit .form-type-textfield, .page-admin-structure-taxonomy-categorias-avaluadores .form-type-textfield,
.page-taxonomy-term-edit .form-actions, .page-admin-structure-taxonomy-categorias-avaluadores .form-actions {
  float: left;
  margin-top: 30px;
  padding: 0;
  position: relative;
}

.page-taxonomy-term-edit .form-type-textfield label, .page-admin-structure-taxonomy-categorias-avaluadores .form-type-textfield label,
.page-taxonomy-term-edit .form-actions label, .page-admin-structure-taxonomy-categorias-avaluadores .form-actions label {
  position: absolute;
  bottom: 100%;
  left: 0;
}

.page-taxonomy-term-edit .form-actions, .page-admin-structure-taxonomy-categorias-avaluadores .form-actions {
  clear: both;
}

.page-admin-structure-taxonomy-categorias-avaluadores #dhtml_menu-671 a {
  color: #d8d8d9 !important;
}

.page-admin-people-create #dhtml_menu-733 a, .page-user-edit #dhtml_menu-733 a {
  color: #d8d8d9 !important;
}

.page-admin-people-create .form-type-textfield, .page-user-edit .form-type-textfield {
  margin-bottom: 10px;
  overflow: hidden;
}

.page-admin-people-create .form-type-textfield label, .page-user-edit .form-type-textfield label {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  margin-bottom: 8px;
}

.page-admin-people-create .form-type-textfield .form-text, .page-user-edit .form-type-textfield .form-text {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  height: 30px;
  padding: 1px 10px 3px;
  border: 1px solid #d8d8d9;
  max-width: 200px;
}

@media only screen and (min-width: 480px) {
  .page-admin-people-create .form-type-textfield .form-text, .page-user-edit .form-type-textfield .form-text {
    margin-right: 10px;
    float: left;
  }
}

.page-admin-people-create .form-type-password, .page-user-edit .form-type-password {
  margin-bottom: 10px;
  overflow: hidden;
}

.page-admin-people-create .form-type-password label, .page-user-edit .form-type-password label {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  margin-bottom: 8px;
}

.page-admin-people-create .form-type-password .form-text, .page-user-edit .form-type-password .form-text {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  height: 30px;
  padding: 1px 10px 3px;
  border: 1px solid #d8d8d9;
  max-width: 200px;
}

@media only screen and (min-width: 480px) {
  .page-admin-people-create .form-type-password .form-text, .page-user-edit .form-type-password .form-text {
    margin-right: 10px;
    float: left;
  }
}

.page-admin-people-create .form-type-password, .page-user-edit .form-type-password {
  margin-bottom: 10px;
  overflow: hidden;
}

.page-admin-people-create .form-type-password label, .page-user-edit .form-type-password label {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  margin-bottom: 8px;
}

.page-admin-people-create .form-type-password .form-text, .page-user-edit .form-type-password .form-text {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  height: 30px;
  padding: 1px 10px 3px;
  border: 1px solid #d8d8d9;
  max-width: 200px;
}

@media only screen and (min-width: 480px) {
  .page-admin-people-create .form-type-password .form-text, .page-user-edit .form-type-password .form-text {
    margin-right: 10px;
    float: left;
  }
}

.page-admin-people-create .form-actions, .page-user-edit .form-actions {
  padding: 15px 0 30px;
  text-align: center;
}

.page-admin-people-create .form-actions .form-submit, .page-user-edit .form-actions .form-submit {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  font-size: 1.1em;
  box-shadow: none;
  color: #5a83b6;
  border: 1px solid #5a83b6;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0;
  background-color: #5a83b6;
  border: 1px solid white;
  color: white;
}

.page-admin-people-create .form-actions .form-submit:hover, .page-user-edit .form-actions .form-submit:hover {
  color: #446a99;
  border-color: #446a99;
}

.page-admin-people-create .form-actions .form-submit:hover, .page-user-edit .form-actions .form-submit:hover {
  background-color: #446a99;
  color: white;
  border-color: white;
}

.page-admin-people-create div.field-widget-date-popup, .page-user-edit div.field-widget-date-popup {
  max-width: 200px;
  margin-bottom: 10px;
}

.page-admin-people-create div.field-widget-date-popup legend, .page-user-edit div.field-widget-date-popup legend,
.page-admin-people-create div.field-widget-date-popup .form-item, .page-user-edit div.field-widget-date-popup .form-item,
.page-admin-people-create div.field-widget-date-popup fieldset, .page-user-edit div.field-widget-date-popup fieldset,
.page-admin-people-create div.field-widget-date-popup .form-item .date-padding, .page-user-edit div.field-widget-date-popup .form-item .date-padding {
  border: 0;
  padding: 0;
  margin: 0;
}

.page-admin-people-create div.field-widget-date-popup .form-item .date-padding, .page-user-edit div.field-widget-date-popup .form-item .date-padding,
.page-admin-people-create div.field-widget-date-popup .date-no-float, .page-user-edit div.field-widget-date-popup .date-no-float {
  width: 100%;
}

.page-admin-people-create div.field-widget-date-popup legend, .page-user-edit div.field-widget-date-popup legend {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  margin-bottom: 8px;
  line-height: 100%;
}

.page-admin-people-create div.field-widget-date-popup .form-text, .page-user-edit div.field-widget-date-popup .form-text {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  height: 30px;
  padding: 1px 10px 3px;
  border: 1px solid #d8d8d9;
  margin-right: 0;
  width: 100%;
}

.page-admin-people-create div.field-widget-date-popup .form-item, .page-user-edit div.field-widget-date-popup .form-item {
  width: 100%;
}

.page-admin-people-create div.field-widget-date-popup .form-item label, .page-user-edit div.field-widget-date-popup .form-item label {
  display: none;
}

.page-admin-people-create div.field-widget-date-popup .description, .page-user-edit div.field-widget-date-popup .description {
  display: none;
}

.page-admin-people-create form, .page-user-edit form {
  clear: both;
  width: 100%;
  border-top: 1px solid #797E7F;
  margin-top: 10px;
  padding-top: 10px;
}

.page-admin-people-create .form-type-password, .page-user-edit .form-type-password {
  position: relative;
}

.page-admin-people-create div.password-suggestions ul, .page-user-edit div.password-suggestions ul {
  margin: 0;
}

.page-admin-people-create .confirm-parent, .page-user-edit .confirm-parent, .page-admin-people-create .password-parent, .page-user-edit .password-parent {
  width: 100%;
}

@media only screen and (min-width: 480px) {
  .page-admin-people-create .password-strength, .page-user-edit .password-strength,
  .page-admin-people-create div.password-confirm, .page-user-edit div.password-confirm {
    width: auto;
    position: absolute;
    left: 210px;
  }
}

.page-admin-people-create .form-type-checkbox label, .page-user-edit .form-type-checkbox label {
  display: inline-block;
  vertical-align: middle;
}

.page-admin-people-create .form-type-checkbox label, .page-user-edit .form-type-checkbox label {
  margin: 0;
}

.page-user-edit #block-menu-menu-eras-menu .menu__item.last a {
  color: #d8d8d9 !important;
}

.page-user-edit #edit-picture {
  margin-bottom: 10px;
}

.page-certificados .search-container {
  position: relative;
  padding-top: 13px;
}

.page-certificados .search-container label {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  margin-bottom: 8px;
}

.page-certificados .search-container .form-wrapper {
  position: relative;
  max-width: 370px;
}

.page-certificados .search-container .form-text {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  height: 30px;
  padding: 1px 10px 3px;
  border: 1px solid #d8d8d9;
  max-width: 88%;
  position: relative;
  z-index: 3;
}

.page-certificados .search-container .form-submit {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  font-size: 1.1em;
  box-shadow: none;
  color: #5a83b6;
  border: 1px solid #5a83b6;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0;
  background-color: #5a83b6;
  color: white;
  padding: 6px 10px;
  font-size: .8em;
  border: 0;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
}

.page-certificados .search-container .form-submit:hover {
  color: #446a99;
  border-color: #446a99;
}

.page-certificados .search-container .form-submit:hover {
  background-color: #446a99;
  color: white;
  border-color: white;
}

.page-certificados #ana-custom-certificate-form {
  position: relative;
}

@media only screen and (min-width: 480px) and (max-width: 800px) {
  .page-certificados #edit-search-container {
    position: absolute;
    bottom: 15px;
    right: 0;
    width: 50%;
  }
  .page-certificados #edit-search-container .form-item {
    margin: 0;
  }
}

.page-certificados .search-container {
  padding: 0;
}

@media only screen and (max-width: 800px) {
  .page-certificados .search-container {
    border-bottom: 1px solid #979797;
  }
}

.page-certificados .search-container label {
  line-height: 145%;
  margin-top: -5px;
  margin-bottom: 10px;
  font-size: .9em;
  font-weight: 400;
  color: #a4a4a4;
  /*
			color: $base-gray-dark;
			padding-bottom: 9px;*/
}

.page-certificados .search-container .form-text {
  border-color: #5a83b6;
}

.page-certificados .search-container .form-submit {
  font-size: .9em;
}

.page-certificados #result_certificate .form-wrapper {
  padding: 15px 0 30px;
  text-align: center;
}

.page-certificados #result_certificate .form-wrapper .form-submit {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  font-size: 1.1em;
  box-shadow: none;
  color: #5a83b6;
  border: 1px solid #5a83b6;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0;
  background-color: #5a83b6;
  border: 1px solid white;
  color: white;
}

.page-certificados #result_certificate .form-wrapper .form-submit:hover {
  color: #446a99;
  border-color: #446a99;
}

.page-certificados #result_certificate .form-wrapper .form-submit:hover {
  background-color: #446a99;
  color: white;
  border-color: white;
}

@media only screen and (min-width: 800px) {
  .page-certificados table {
    border-top: 1px solid #979797;
  }
  .page-certificados table tr {
    border-color: #d8d8d9;
  }
  .page-certificados table td {
    text-align: left;
    padding: 7px 10px 7px 20px;
  }
  .page-certificados table td:first-child {
    width: 22%;
    padding-left: 10px;
    color: #5a83b6;
    border-left: 1px solid #d8d8d9;
  }
}

@media only screen and (max-width: 800px) {
  .page-certificados table {
    display: block;
    margin-bottom: 20px;
    padding: 0;
  }
  .page-certificados table tbody {
    border: 1px solid #979797;
    display: block;
  }
  .page-certificados table tr {
    background: #f1f0f2 !important;
    padding: 12px 10px !important;
    border-bottom: 1px solid #979797;
    display: block;
    width: 100%;
  }
  .page-certificados table td {
    padding: 0;
    display: block;
    text-align: left;
    line-height: 125%;
    border: 0;
  }
  .page-certificados table td:first-child {
    color: #466c9e;
  }
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas {
  font-weight: 100;
  margin-bottom: 15px;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas th,
.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas td {
  border: 0;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas thead th {
  text-align: left;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas > * > .form-item {
  display: block;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas .tabledrag-toggle-weight-wrapper {
  display: none;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas .field-multiple-drag {
  width: 30px;
  padding-left: 0;
  padding-right: 10px;
  border-right: 1px solid #d8d8d9;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas .field-multiple-drag .tabledrag-handle {
  display: block;
  min-width: inherit;
  width: 27px;
  padding: 0;
  margin: 0;
  height: auto;
  border-color: #d8d8d9;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas .field-multiple-drag .tabledrag-handle .handle {
  width: 27px;
  height: 28px;
  margin: 0;
  padding: 0;
  background-position: 7px 8px;
  border-color: #d8d8d9;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas td {
  text-align: left;
  vertical-align: top;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas td > * {
  margin-bottom: 15px;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas td > .ajax-new-content {
  margin-bottom: 0;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas td > .ajax-new-content > * {
  margin-bottom: 15px;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas td .tabledrag-handle {
  width: auto;
  min-width: auto;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas .field-widget-options-select {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  border: 1px solid #d8d8d9;
  padding-left: 5px;
  padding-right: 5px;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas .field-widget-options-select .form-select {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  width: auto;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas .field-widget-options-select label {
  color: #466c9e;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas div.field-widget-date-popup {
  width: auto;
  max-width: none;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas label,
.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas .SumoSelect,
.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas legend,
.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas .fieldset-wrapper,
.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas .date-padding,
.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas .form-item,
.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas .form-text {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  width: auto;
  float: none;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas .SumoSelect > .CaptionCont {
  border: 0;
  color: #5a83b6;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas .SumoSelect > .CaptionCont > label > i {
  width: 24px;
  left: auto;
  background: white url("../img/sprites/Arrow-blue-down.png") no-repeat 0 0;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas .file-icon,
.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas .file-size {
  display: none;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas .file a {
  max-width: none;
  width: auto;
  margin: 0;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-personas .file-widget .form-submit {
  background-color: #466c9e;
  color: white;
}

.page-user-edit-avaluador .form-submit {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  font-size: 1.1em;
  box-shadow: none;
  color: #5a83b6;
  border: 1px solid #5a83b6;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0;
  line-height: 100%;
  font-size: 0.857em;
  padding: 9px;
  text-transform: none;
}

.page-user-edit-avaluador .form-submit:hover {
  color: #446a99;
  border-color: #446a99;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci {
  font-weight: 100;
  margin-bottom: 15px;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci th,
.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci td {
  border: 0;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci thead th {
  text-align: left;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci > * > .form-item {
  display: block;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci .tabledrag-toggle-weight-wrapper {
  display: none;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci .field-multiple-drag {
  width: 30px;
  padding-left: 0;
  padding-right: 10px;
  border-right: 1px solid #d8d8d9;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci .field-multiple-drag .tabledrag-handle {
  display: block;
  min-width: inherit;
  width: 27px;
  padding: 0;
  margin: 0;
  height: auto;
  border-color: #d8d8d9;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci .field-multiple-drag .tabledrag-handle .handle {
  width: 27px;
  height: 28px;
  margin: 0;
  padding: 0;
  background-position: 7px 8px;
  border-color: #d8d8d9;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci td {
  text-align: left;
  vertical-align: top;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci td > * {
  margin-bottom: 15px;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci td > .ajax-new-content {
  margin-bottom: 0;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci td > .ajax-new-content > * {
  margin-bottom: 15px;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci td .tabledrag-handle {
  width: auto;
  min-width: auto;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci .field-widget-options-select {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  border: 1px solid #d8d8d9;
  padding-left: 5px;
  padding-right: 5px;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci .field-widget-options-select .form-select {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  width: auto;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci .field-widget-options-select label {
  color: #466c9e;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci div.field-widget-date-popup {
  width: auto;
  max-width: none;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci label,
.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci .SumoSelect,
.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci legend,
.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci .fieldset-wrapper,
.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci .date-padding,
.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci .form-item,
.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci .form-text {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  width: auto;
  float: none;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci .SumoSelect > .CaptionCont {
  border: 0;
  color: #5a83b6;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci .SumoSelect > .CaptionCont > label > i {
  width: 24px;
  left: auto;
  background: white url("../img/sprites/Arrow-blue-down.png") no-repeat 0 0;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci .file-icon,
.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci .file-size {
  display: none;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci .file a {
  max-width: none;
  width: auto;
  margin: 0;
}

.page-user-edit-avaluador #edit-profile-avaluador-field-certificados-de-experienci .file-widget .form-submit {
  background-color: #466c9e;
  color: white;
}

.page-user-edit-avaluador .form-submit {
  display: inline-block;
  vertical-align: top;
  padding: 0.62em 2em;
  font-size: 1.1em;
  box-shadow: none;
  color: #5a83b6;
  border: 1px solid #5a83b6;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0;
  line-height: 100%;
  font-size: 0.857em;
  padding: 9px;
  text-transform: none;
}

.page-user-edit-avaluador .form-submit:hover {
  color: #446a99;
  border-color: #446a99;
}

.page-user-edit-avaluador div.field-name-field-traslado-date {
  max-width: 300px;
  margin-bottom: 10px;
  border: 1px solid #d8d8d9;
}

.page-user-edit-avaluador div.field-name-field-traslado-date::before {
  content: '';
  display: table;
}

.page-user-edit-avaluador div.field-name-field-traslado-date::after {
  content: '';
  display: table;
  clear: both;
}

.page-user-edit-avaluador div.field-name-field-traslado-date .form-item {
  margin: 0;
}

.page-user-edit-avaluador div.field-name-field-traslado-date .form-text {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  height: 30px;
  padding: 1px 10px 3px;
  border: 1px solid transparent;
  padding: 2px 0;
}

.page-user-edit-avaluador div.field-name-field-traslado-date fieldset,
.page-user-edit-avaluador div.field-name-field-traslado-date legend,
.page-user-edit-avaluador div.field-name-field-traslado-date fieldset.date-combo .container-inline-date .date-padding {
  padding: 0;
  border: 0;
  border: 0;
  margin: 0;
}

.page-user-edit-avaluador div.field-name-field-traslado-date legend {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  height: 30px;
  padding: 1px 10px 3px;
  margin-right: 10px;
  display: block;
  white-space: nowrap;
  float: left;
  padding: 6px 0 2px 10px;
  position: relative;
}

.page-user-edit-avaluador div.field-name-field-traslado-date legend .fieldset-legend {
  display: block;
  overflow: hidden;
}

.page-user-edit-avaluador div.field-name-field-traslado-date legend::after {
  content: ": ";
  position: absolute;
  top: 6px;
  left: 100%;
}

.page-user-edit-avaluador div.field-name-field-traslado-date .fieldset-wrapper {
  float: left;
  width: 80px;
}

.page-user-edit-avaluador div.field-name-field-traslado-date label,
.page-user-edit-avaluador div.field-name-field-traslado-date .description {
  display: none;
}

.page-user-edit-avaluador div.field-name-field-traslado-date .container-inline-date > .form-item {
  margin-right: 0;
  display: block;
}

.page-user-edit-avaluador div.field-name-field-aprobacion-date {
  max-width: 350px;
  margin-bottom: 10px;
  border: 1px solid #d8d8d9;
}

.page-user-edit-avaluador div.field-name-field-aprobacion-date::before {
  content: '';
  display: table;
}

.page-user-edit-avaluador div.field-name-field-aprobacion-date::after {
  content: '';
  display: table;
  clear: both;
}

.page-user-edit-avaluador div.field-name-field-aprobacion-date .form-item {
  margin: 0;
}

.page-user-edit-avaluador div.field-name-field-aprobacion-date .form-text {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  height: 30px;
  padding: 1px 10px 3px;
  border: 1px solid transparent;
  padding: 2px 0;
}

.page-user-edit-avaluador div.field-name-field-aprobacion-date fieldset,
.page-user-edit-avaluador div.field-name-field-aprobacion-date legend,
.page-user-edit-avaluador div.field-name-field-aprobacion-date fieldset.date-combo .container-inline-date .date-padding {
  padding: 0;
  border: 0;
  border: 0;
  margin: 0;
}

.page-user-edit-avaluador div.field-name-field-aprobacion-date legend {
  font-weight: 400;
  color: #6e91c0;
  font-size: 14px;
  line-height: 135%;
  height: 30px;
  padding: 1px 10px 3px;
  margin-right: 10px;
  display: block;
  white-space: nowrap;
  float: left;
  padding: 6px 0 2px 10px;
  position: relative;
}

.page-user-edit-avaluador div.field-name-field-aprobacion-date legend .fieldset-legend {
  display: block;
  overflow: hidden;
}

.page-user-edit-avaluador div.field-name-field-aprobacion-date legend::after {
  content: ": ";
  position: absolute;
  top: 6px;
  left: 100%;
}

.page-user-edit-avaluador div.field-name-field-aprobacion-date .fieldset-wrapper {
  float: left;
  width: 80px;
}

.page-user-edit-avaluador div.field-name-field-aprobacion-date label,
.page-user-edit-avaluador div.field-name-field-aprobacion-date .description {
  display: none;
}

.page-user-edit-avaluador div.field-name-field-aprobacion-date .container-inline-date > .form-item {
  margin-right: 0;
  display: block;
}

.page-user-edit-avaluador div.field-name-field-aprobacion-date .fieldset-wrapper {
  width: auto;
}

.page-user-edit-avaluador div.field-name-field-aprobacion-date .form-item {
  display: inline-block;
  vertical-align: top;
  width: auto;
}

.page-user-edit-avaluador div.field-name-field-aprobacion-date .form-item .form-text {
  width: 84px;
}

.page-user-edit-avaluador div.field-name-field-aprobacion-date .form-item + .form-item .form-text {
  width: 60px;
}

.page-user-edit-avaluador .SumoSelect {
  width: 100%;
}

.page-user-edit-avaluador .field-name-field-documentos-adicionales {
  text-align: left;
}

.page-user-edit-avaluador .field-name-field-documentos-adicionales .fieldset-description {
  padding: 10px 0;
}

.page-user-edit-avaluador .field-name-field-documentos-adicionales .field-type-file label {
  min-width: 75%;
}

.page-user-edit-avaluador .field-name-field-documentos-adicionales .field-type-file .file-widget {
  display: block;
  float: right;
}

.page-admin-create-user .form-type-select select {
  border: 1px solid #466c9e;
}

.page-admin-create-user .form-type-select .SumoSelect {
  width: 100%;
}

.page-admin-create-user .form-type-select .SumoSelect .CaptionCont {
  width: 100%;
  border: 1px solid #466c9e;
}

.page-admin-create-user .form-type-select .SumoSelect .CaptionCont label {
  margin: 5px 0;
}

.page-admin-create-user .form-text {
  padding: 5px 10px;
}

.page-admin-create-user .form-item {
  margin-bottom: 20px;
}

.page-admin-create-user .field-type-field-collection .field-multiple-table td {
  text-align: left;
}

.page-admin-create-user .field-name-field-documentos-adicionales {
  text-align: left;
}

.page-admin-create-user .field-name-field-documentos-adicionales .fieldset-description {
  padding: 10px 0;
}

.page-admin-create-user .field-name-field-documentos-adicionales .field-type-file label {
  min-width: 75%;
}

.page-admin-create-user .field-name-field-documentos-adicionales .field-type-file .file-widget {
  display: block;
  float: right;
}

@media only screen and (min-width: 800px) {
  /*	
	.page-traslado-avaluadores{
		.view-content {
			width: 100% !important;
		}
		
	  #block-views-traslado-avaluadores-block-1{
	    overflow: hidden;
	    h2.block__title{
	      color: #a4a4a4;
	      font-size: 14px;
	      font-weight: 300;
	    }
	  }
	  .view-id-traslado_avaluadores.view-display-id-page{
	    clear:both;
	    margin-top: 24px;
	    .view-header{
	      h3{
	        color:#5c84B3;
	        font-weight: normal;
	        font-size: 16px;
	        margin-bottom: 16px;
	      }
	    }
	    table{
	      border: 1px solid;
	      &:before{
	        display: none;
	      }
	      .views-field-field-firstname{
	        text-align: left;
	        padding-left: 8px;
	      }
	    }
	  }
	}
*/
}

/*#ana-custom-certificate-form------------{
  .search-container{ 
    label{
      color: #a4a4a4;
      font-size: 14px;
      font-weight: 300;
    }
    #edit-search-container{
      font-size: 0px;
      .form-item,.form-submit{
        display: inline-block;
        vertical-align: top;
        font-size: 16px;
        max-width: 240px;
      }
      .form-submit{
        @include custom-button-2();
        padding: 0.18em 1.35em;
        font-size: 12px;
      }
    }
  }
  #result_certificate{
    table{
      &:before{
        display: none;
      }
      border:1px solid #797e7f;
      tr{
        td{
          text-align: left;
          padding: 8px;
          &:first-child{
            color:#5c84B3;
          }
        }
      }
    }
    .form-submit{
      @include custom-button-2();
      padding: 0.18em 1.35em;
      font-size: 12px;
      margin-top: 24px;
    }
  }
}
*/
.page-user .entity-profile2 > div {
  display: flex;
  flex-wrap: wrap;
}

.page-user .entity-profile2 > div .group-general-dates {
  width: 70%;
}

.page-user .entity-profile2 > div .group-foto {
  width: 30%;
}

.page-user .entity-profile2 > div .group-foto .field-group-format-wrapper {
  padding: 0;
}

.page-user .entity-profile2 > div .group-foto .field-group-format-wrapper img {
  height: 220px;
  width: auto;
}

.page-user .entity-profile2 > div .group-documents-add,
.page-user .entity-profile2 > div .group-dates-o {
  width: 100%;
}

.page-user .entity-profile2 > div .action-links-field-collection-add,
.page-user .entity-profile2 > div .field-collection-view-links {
  display: none;
}

.page-user .entity-profile2 > div .field-group-format-wrapper {
  padding: 1rem 2rem;
}

.box {
  margin-bottom: 1.5rem;
  border: 5px solid #cccccc;
  padding: 1em;
}

.box__title {
  margin: 0;
}

.box:focus, .box:hover, .box.is-focus, .box--is-focus {
  border-color: #000;
}

.box--highlight {
  border-color: #0072b9;
}

.clearfix::before,
.header::before,
.tabs::before {
  content: '';
  display: table;
}

.clearfix::after,
.header::after,
.tabs::after {
  content: '';
  display: table;
  clear: both;
}

.comment__section,
.comments {
  margin: 1.5rem 0;
}

.comment__title {
  margin: 0;
}

.comment__permalink {
  text-transform: uppercase;
  font-size: 75%;
}

.comment--preview,
.comment-preview {
  background-color: #fffadb;
}

.comment--nested,
.indented {
  margin-left: 32px;
}

[dir="rtl"] .comment--nested,
[dir="rtl"] .indented {
  margin-left: 0;
  margin-right: 32px;
}

.header__logo {
  float: left;
  margin: 0 10px 0 0;
  padding: 0;
}

[dir="rtl"] .header__logo {
  float: right;
  margin: 0 0 0 10px;
}

.header__logo-image {
  vertical-align: bottom;
}

.header__name-and-slogan {
  float: left;
}

.header__site-name {
  margin: 0;
}

.header__site-link:link, .header__site-link:visited {
  color: #000;
  text-decoration: none;
}

.header__site-link:hover, .header__site-link:focus {
  text-decoration: underline;
}

.header__site-slogan {
  margin: 0;
}

.header__secondary-menu {
  float: right;
}

[dir="rtl"] .header__secondary-menu {
  float: left;
}

.header__region {
  clear: both;
}

.hidden,
html.js .js-hidden,
html.js .element-hidden,
html.js .js-hide {
  display: none;
}

.highlight-mark,
.new,
.update {
  color: #c00;
  background-color: transparent;
}

.inline-links,
.inline.links {
  padding: 0;
}

.inline-links__item,
.inline.links li {
  display: inline;
  list-style-type: none;
  padding: 0 1em 0 0;
}

[dir="rtl"] .inline-links__item,
[dir="rtl"] .inline.links li,
.inline.links [dir="rtl"] li {
  display: inline-block;
  padding: 0 0 0 1em;
}

.inline-sibling,
.field-label-inline .field-label,
span.field-label {
  display: inline;
  margin-right: 10px;
}

[dir="rtl"] .inline-sibling,
[dir="rtl"] .field-label-inline .field-label,
.field-label-inline [dir="rtl"] .field-label,
[dir="rtl"] span.field-label {
  margin-right: 0;
  margin-left: 10px;
}

.inline-sibling__child,
.inline-sibling *, .field-label-inline .field-label *, span.field-label * {
  display: inline;
}

.inline-sibling__adjacent,
.inline-sibling + *,
.inline-sibling + * > :first-child,
.inline-sibling + * > :first-child > :first-child, .field-label-inline .field-label + *, span.field-label + *, .field-label-inline .field-label + * > :first-child, span.field-label + * > :first-child, .field-label-inline .field-label + * > :first-child > :first-child, span.field-label + * > :first-child > :first-child {
  display: inline;
}

.field-label-inline .field-label,
.field-label-inline .field-items {
  float: none;
}

.messages,
.messages--status {
  margin: 1.5rem 0;
  position: relative;
  padding: 10px 10px 10px 44px;
  border: 1px solid #0072b9;
}

[dir="rtl"] .messages,
[dir="rtl"] .messages--status {
  padding: 10px 44px 10px 10px;
  background-position: 99% 8px;
}

.messages__icon {
  position: absolute;
  top: 50%;
  left: 10px;
  height: 24px;
  width: 24px;
  margin-top: -12px;
  line-height: 1;
}

[dir="rtl"] .messages__icon {
  left: auto;
  right: 0;
}

.messages__icon path {
  fill: #0072b9;
}

.messages__highlight,
.messages--error .error,
.messages.error .error {
  color: #000;
}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

.messages--ok-color,
.messages,
.messages--status,
.ok,
.revision-current {
  background-color: #f6fcff;
  color: #0072b9;
}

.messages--warning-color,
.messages--warning,
.messages.warning,
.warning {
  background-color: #fffce6;
  color: #000;
}

.messages--error-color,
.messages--error,
.messages.error,
.error {
  background-color: #fff0f0;
  color: #c00;
}

.messages--warning,
.messages.warning {
  border-color: #fd0;
}

.messages--warning path, .messages.warning path {
  fill: #fd0;
}

.messages--error,
.messages.error {
  border-color: #c00;
}

.messages--error path, .messages.error path {
  fill: #c00;
}

@media print {
  .print-none,
  .toolbar,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .feed-icons {
    display: none;
  }
}

.responsive-video,
.media-youtube-video,
.media-vimeo-preview-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.responsive-video__embed,
.responsive-video iframe,
.media-youtube-video iframe,
.media-vimeo-preview-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-video--4-3 {
  padding-bottom: 75%;
}

.visually-hidden,
.element-invisible,
.element-focusable,
.breadcrumb__title,
.main-navigation .block-menu .block__title,
.main-navigation .block-menu-block .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.visually-hidden--off,
.visually-hidden--focusable:active,
.visually-hidden--focusable:focus,
.element-focusable:active,
.element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: visible;
}

.watermark {
  font-family: Impact, "Arial Narrow", Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  height: 0;
  overflow: visible;
  background-color: transparent;
  color: #eeeeee;
  font-size: 75px;
  line-height: 1;
  text-align: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
}

@media print {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb__list {
  margin: 0;
  padding: 0;
}

.breadcrumb__item {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.more-link,
.more-help-link {
  text-align: right;
}

[dir="rtl"] .more-link,
[dir="rtl"] .more-help-link {
  text-align: left;
}

.more-link__help-icon,
.more-help-link a {
  padding: 1px 0 1px 20px;
  background-image: url(../sass/navigation/more-link/more-link__help-icon.svg);
  background-position: 0 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .more-link__help-icon,
[dir="rtl"] .more-help-link a,
.more-help-link [dir="rtl"] a {
  padding: 1px 20px 1px 0;
  background-position: 100% 50%;
}

.nav-menu__item,
.menu__item {
  list-style-image: url(../sass/navigation/nav-menu/leaf.svg);
  list-style-type: square;
}

.nav-menu__item.is-expanded, .nav-menu__item--is-expanded,
.is-expanded.menu__item,
.menu__item.is-expanded {
  list-style-image: url(../sass/navigation/nav-menu/expanded.svg);
  list-style-type: circle;
}

.nav-menu__item.is-collapsed, .nav-menu__item--is-collapsed,
.is-collapsed.menu__item,
.menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed.svg);
  list-style-type: disc;
}

[dir="rtl"] .nav-menu__item.is-collapsed, [dir="rtl"] .nav-menu__item--is-collapsed,
[dir="rtl"] .is-collapsed.menu__item,
[dir="rtl"] .menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed-rtl.svg);
}

.nav-menu__link.is-active, .nav-menu__link--is-active,
.menu a.active {
  color: #000;
}

.navbar,
.main-navigation .links,
.main-navigation .menu {
  margin: 0;
  padding: 0;
  text-align: left;
}

[dir="rtl"] .navbar,
[dir="rtl"] .main-navigation .links,
.main-navigation [dir="rtl"] .links,
[dir="rtl"]
.main-navigation .menu,
.main-navigation [dir="rtl"] .menu {
  text-align: right;
}

.navbar__item,
.navbar li, .main-navigation .links li,
.main-navigation .menu li {
  float: left;
  padding: 0 10px 0 0;
  list-style-type: none;
  list-style-image: none;
}

[dir="rtl"] .navbar__item, [dir="rtl"]
.navbar li, [dir="rtl"] .main-navigation .links li, .main-navigation [dir="rtl"] .links li, [dir="rtl"]
.main-navigation .menu li,
.main-navigation [dir="rtl"] .menu li {
  float: right;
  padding: 0 0 0 10px;
}

.pager {
  clear: both;
  padding: 0;
  text-align: center;
}

@media print {
  .pager {
    display: none;
  }
}

.pager__item,
.pager__current-item,
.pager-current,
.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
}

.pager__current-item,
.pager-current {
  font-weight: bold;
}

.skip-link {
  display: block;
  padding: 2px 0 3px;
  text-align: center;
}

.skip-link:link, .skip-link:visited {
  background-color: #666666;
  color: #fff;
}

.skip-link__wrapper {
  margin: 0;
}

@media print {
  .skip-link__wrapper {
    display: none;
  }
}

.tabs {
  margin: 1.125rem 0 0;
  line-height: 1.875rem;
  border-bottom: 1px solid #cccccc \0/ie;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
  padding: 0 2px;
  list-style: none;
  white-space: nowrap;
}

@media print {
  .tabs {
    display: none;
  }
}

.tabs__tab {
  float: left;
  margin: 0 3px;
  border: 1px solid #cccccc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-color: transparent;
  border-bottom: 0 \0/ie;
  overflow: hidden;
  background: #dddddd;
}

[dir="rtl"] .tabs__tab {
  float: right;
}

.tabs__tab.is-active {
  border-bottom-color: #fff;
}

.tabs__tab-link {
  padding: 0 1.5rem;
  display: block;
  text-decoration: none;
  transition: color .3s, background .3s, border .3s;
  text-shadow: #fff 0 1px 0;
  color: #000;
  background: #dddddd;
  letter-spacing: 1px;
}

.tabs__tab-link:focus, .tabs__tab-link:hover {
  background: #eaeaea;
}

.tabs__tab-link:active, .tabs__tab-link.is-active, .tabs__tab-link--is-active {
  background: #fff;
  text-shadow: none;
}

.tabs--off,
.views-displays .secondary {
  margin: 0;
  border-bottom: 0;
  padding: 0;
  background-image: none;
}

.tabs--secondary {
  margin-top: 1.5rem;
  font-size: 0.875rem;
  background-image: none;
}

.tabs + .tabs--secondary {
  margin-top: 0;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
}

.tabs--secondary .tabs__tab {
  margin: 0.5625rem 0.1875rem;
  border: 0;
  background: transparent;
}

[dir="rtl"] .tabs--secondary .tabs__tab {
  float: right;
}

.tabs--secondary .tabs__tab.is-active {
  border-bottom-color: transparent;
}

.tabs--secondary .tabs__tab-link {
  border: 1px solid #cccccc;
  border-radius: 24px;
  color: #666666;
  background: #f1f1f1;
  letter-spacing: normal;
}

.tabs--secondary .tabs__tab-link:focus, .tabs--secondary .tabs__tab-link:hover {
  color: #333333;
  background: #dddddd;
  border-color: #999999;
}

.tabs--secondary .tabs__tab-link:active, .tabs--secondary .tabs__tab-link.is-active, .tabs--secondary .tabs__tab-link--is-active {
  color: white;
  text-shadow: #333333 0 1px 0;
  background: #666666;
  border-color: black;
}

.autocomplete,
.form-autocomplete {
  background-image: url(../sass/forms/autocomplete/throbber-inactive.png);
  background-position: 100% center;
  background-repeat: no-repeat;
}

[dir="rtl"] .autocomplete,
[dir="rtl"] .form-autocomplete {
  background-position: 0% center;
}

.autocomplete__list-wrapper,
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

.autocomplete__list,
#autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

.autocomplete__list-item,
#autocomplete li {
  background: #fff;
  color: #000;
  cursor: default;
  white-space: pre;
}

.autocomplete__list-item.is-selected, .autocomplete__list-item--is-selected,
#autocomplete li.is-selected,
#autocomplete .selected {
  background: #0072b9;
  color: #fff;
}

.autocomplete.is-throbbing, .autocomplete--is-throbbing,
.is-throbbing.form-autocomplete,
.form-autocomplete.throbbing {
  background-image: url(../sass/forms/autocomplete/throbber-active.gif);
}

.collapsible-fieldset,
.collapsible {
  position: relative;
}

.collapsible-fieldset__legend,
.collapsible .fieldset-legend {
  display: block;
  padding-left: 15px;
  background-image: url(../sass/forms/collapsible-fieldset/expanded.svg);
  background-position: 4px 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .collapsible-fieldset__legend,
[dir="rtl"] .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .fieldset-legend {
  padding-left: 0;
  padding-right: 15px;
  background-position: right 4px top 50%;
}

.collapsible-fieldset__summary,
.collapsible .fieldset-legend .summary {
  color: #999999;
  font-size: .9em;
  margin-left: .5em;
}

.collapsible-fieldset.is-collapsed, .collapsible-fieldset--is-collapsed,
.is-collapsed.collapsible,
.collapsible.collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__wrapper, .collapsible-fieldset--is-collapsed__wrapper, .is-collapsed.collapsible .collapsible-fieldset__wrapper,
.collapsible.collapsed .fieldset-wrapper {
  display: none;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, .collapsible-fieldset--is-collapsed__legend, .is-collapsed.collapsible .collapsible-fieldset__legend,
.collapsible.collapsed .fieldset-legend,
.collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible .collapsible-fieldset.is-collapsed .fieldset-legend,
.is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed.svg);
  background-position: 4px 50%;
}

[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, [dir="rtl"] .collapsible-fieldset--is-collapsed__legend, [dir="rtl"] .is-collapsed.collapsible .collapsible-fieldset__legend,
[dir="rtl"] .collapsible.collapsed .fieldset-legend,
.collapsible.collapsed [dir="rtl"] .fieldset-legend,
[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .collapsible-fieldset.is-collapsed .fieldset-legend,
[dir="rtl"] .is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed-rtl.svg);
  background-position: right 4px top 50%;
}

.form-item {
  margin: 1.5rem 0;
}

.form-item__required,
.form-required {
  color: #c00;
}

.form-item__description,
.form-item .description {
  font-size: 0.875rem;
}

.form-item--inline div,
.form-item--inline label, .container-inline .form-item div, .container-inline .form-item label {
  display: inline;
}

.form-item--inline__exception,
.container-inline .fieldset-wrapper {
  display: block;
}

.form-item--tight,
.form-item--radio,
.form-type-radio,
.form-type-checkbox,
.password-parent,
.confirm-parent,
table .form-item {
  margin: 0;
}

.form-item--radio .form-item__label, .form-item--radio__label, .form-type-radio .form-item__label,
.form-type-checkbox .form-item__label,
label.option {
  display: inline;
  font-weight: normal;
}

.form-item--radio .form-item__description, .form-item--radio__description, .form-type-radio .form-item__description,
.form-type-checkbox .form-item__description,
.form-type-radio .description,
.form-type-checkbox .description,
.form-item--radio .form-item .description,
.form-item .form-item--radio .description,
.form-type-radio .form-item .description,
.form-item .form-type-radio .description,
.form-type-checkbox .form-item .description,
.form-item
.form-type-checkbox .description {
  margin-left: 1.4em;
}

.form-item.is-error .form-item__widget, .form-item--is-error__widget,
.form-item.is-error input,
.form-item.is-error textarea,
.form-item.is-error select,
.form-item--is-error input,
.form-item--is-error textarea,
.form-item--is-error select,
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #c00;
}

.form-table__sticky-header,
.sticky-header {
  position: fixed;
  visibility: hidden;
  margin-top: 0;
  background-color: #fff;
}

.form-table__sticky-header.is-sticky, .form-table__sticky-header--is-sticky,
.is-sticky.sticky-header {
  visibility: visible;
}

.form-table__header,
.form-table th,
form table th {
  border-bottom: 3px solid #cccccc;
  padding-right: 1em;
  text-align: left;
}

[dir="rtl"] .form-table__header,
[dir="rtl"] .form-table th,
.form-table [dir="rtl"] th,
[dir="rtl"] form table th,
form table [dir="rtl"] th {
  text-align: right;
  padding-left: 1em;
  padding-right: 0;
}

.form-table__body,
.form-table tbody,
form table tbody {
  border-top: 1px solid #cccccc;
}

.form-table__row,
.form-table tbody tr,
form table tbody tr {
  padding: .1em .6em;
  border-bottom: 1px solid #cccccc;
  background-color: #eeeeee;
}

.form-table__row:nth-child(even),
.form-table tbody tr:nth-child(even),
form table tbody tr:nth-child(even) {
  background-color: #fff;
}

.form-table__row.is-active, .form-table__row--is-active,
.form-table tbody tr.is-active,
form table tbody tr.is-active,
td.active {
  background-color: #dddddd;
}

.form-table__row.is-disabled, .form-table__row--is-disabled,
.form-table tbody tr.is-disabled,
form table tbody tr.is-disabled,
td.menu-disabled {
  background: #cccccc;
}

.form-table__row.is-selected, .form-table__row--is-selected,
.form-table tbody tr.is-selected,
form table tbody tr.is-selected,
tr.selected td {
  background: #fffdf0;
}

.form-table__list,
.form-table ul,
form table ul {
  margin: 0;
}

.form-table__narrow-column,
.form-table th.form-table__narrow-column,
form table th.form-table__narrow-column,
td .checkbox,
th .checkbox {
  width: -moz-min-content;
  width: -webkit-min-content;
  text-align: center;
}

.progress-bar,
.progress {
  font-weight: bold;
}

.progress-bar__bar,
.progress .bar {
  border-radius: 3px;
  margin: 0 .2em;
  border: 1px solid #666666;
  background-color: #cccccc;
}

.progress-bar__fill,
.progress .filled {
  height: 1.5em;
  width: 5px;
  background: #0072b9 url(../sass/forms/progress-bar/progress-bar.gif) repeat 0 0;
}

.progress-bar__percentage,
.progress .percentage {
  float: right;
}

[dir="rtl"] .progress-bar__percentage,
[dir="rtl"] .progress .percentage,
.progress [dir="rtl"] .percentage {
  float: left;
}

.progress-bar--inline,
.ajax-progress-bar {
  width: 16em;
  display: inline-block;
}

[dir="rtl"] .progress-bar--inline,
[dir="rtl"] .ajax-progress-bar {
  float: right;
}

.progress-throbber,
.ajax-progress {
  display: inline-block;
}

[dir="rtl"] .progress-throbber,
[dir="rtl"] .ajax-progress {
  float: right;
}

.progress-throbber__widget,
.ajax-progress .throbber {
  background: url(../sass/forms/progress-throbber/progress-throbber.gif) no-repeat 0 -18px transparent;
  float: left;
  height: 15px;
  margin: 2px;
  width: 15px;
}

[dir="rtl"] .progress-throbber__widget,
[dir="rtl"] .ajax-progress .throbber,
.ajax-progress [dir="rtl"] .throbber {
  float: right;
}

.progress-throbber__widget-in-tr,
tr .ajax-progress .throbber {
  margin: 0 2px;
}

.progress-throbber__message,
.ajax-progress .message {
  padding-left: 20px;
}

.resizable-textarea,
.resizable-textarea textarea {
  width: 100%;
  vertical-align: bottom;
}

.resizable-textarea__grippie,
.resizable-textarea .grippie {
  background: url(../sass/forms/resizable-textarea/grippie.png) no-repeat center 2px #eeeeee;
  border: 1px solid #cccccc;
  border-top-width: 0;
  cursor: s-resize;
  height: 9px;
  overflow: hidden;
}

.table-drag__wrapper,
body.drag {
  cursor: move;
}

.table-drag__item,
tr.drag {
  background-color: #fffadb;
}

.table-drag__item-previous,
tr.drag-previous {
  background-color: #fff7c2;
}

.table-drag__handle,
.tabledrag-handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none;
  font-size: 12px;
}

[dir="rtl"] .table-drag__handle,
[dir="rtl"] .tabledrag-handle {
  float: right;
  margin-right: -1em;
  margin-left: 0;
}

.table-drag__handle:focus, .table-drag__handle:hover,
.tabledrag-handle:focus,
.tabledrag-handle:hover {
  text-decoration: none;
}

.table-drag__handle-icon,
.tabledrag-handle .handle {
  box-sizing: content-box;
  background: url(../sass/forms/table-drag/handle-icon.png) no-repeat 6px 9px;
  height: 13px;
  margin: -.4em .5em;
  padding: .42em .5em;
  width: 13px;
}

.table-drag__handle.is-hover .table-drag__handle-icon, .table-drag__handle-icon--is-hover, .is-hover.tabledrag-handle .table-drag__handle-icon,
.tabledrag-handle-hover .handle,
.table-drag__handle.is-hover .tabledrag-handle .handle,
.tabledrag-handle .table-drag__handle.is-hover .handle,
.is-hover.tabledrag-handle .handle {
  background-position: 6px -11px;
}

.table-drag__toggle-weight-wrapper,
.tabledrag-toggle-weight-wrapper {
  text-align: right;
}

[dir="rtl"] .table-drag__toggle-weight-wrapper,
[dir="rtl"] .tabledrag-toggle-weight-wrapper {
  text-align: left;
}

.table-drag__toggle-weight,
.tabledrag-toggle-weight {
  font-size: .9em;
}

.table-drag__indentation,
.indentation {
  float: left;
  height: 1.7em;
  margin: -.4em .2em -.4em -.4em;
  padding: .42em 0 .42em .6em;
  width: 20px;
}

[dir="rtl"] .table-drag__indentation,
[dir="rtl"] .indentation {
  float: right;
  margin: -.4em -.4em -.4em .2em;
  padding: .42em .6em .42em 0;
}

.table-drag__tree-child,
.table-drag__tree-child-last,
.tree-child-last,
.table-drag__tree-child-horizontal,
.tree-child-horizontal,
.tree-child {
  background: url(../sass/forms/table-drag/tree-child.png) no-repeat 11px center;
}

[dir="rtl"] .table-drag__tree-child,
[dir="rtl"] .table-drag__tree-child-last,
[dir="rtl"] .tree-child-last,
[dir="rtl"] .table-drag__tree-child-horizontal,
[dir="rtl"] .tree-child-horizontal,
[dir="rtl"] .tree-child {
  background-position: -65px center;
}

.table-drag__tree-child-last,
.tree-child-last {
  background-image: url(../sass/forms/table-drag/tree-child-last.png);
}

.table-drag__tree-child-horizontal,
.tree-child-horizontal {
  background-position: -11px center;
}
