

$mobile: 480px;
$tablet: 800px; //768px;
$desktop: 960px; //960px;
$desktop-large: 1024px;
$desktop-xlarge: 1240px;

$base-gray-soft: #f1f0f2; // rgba(241, 240, 242, 1)
$base-gray-soft-2: #d8d8d9; // rgba(216, 216, 217, 1) // input fields
$base-gray-half: #797e7f; // rgba(121, 126, 127, 100)
$base-gray-dark: #a4a4a4; // rgba(74, 74, 74, 1)
$base-blue-soft: #5a83b6; //#5c84B3; // rgba(92, 132, 179, 100)
$texto-tabla: #4a4a4a;
$base-blue-dark: #466c9e; // rgba(70, 108, 158, 100)
$base-yellow: #e7dd00;
$base-blue-darker :#466c9d;
$dark: $base-gray-dark; // usado en párrafos

$subtitle-bottom: #bdbfc0; // preguntar si está bien este color
$table-horizontal-color: $base-gray-half;
$table-vertical-color: $base-gray-soft-2;

$blue-linea: #708092; // en diseño linea del home

$white: #fff;

$mobile-site-padding: 15px;
$small-text: .857em;
$small-text-1: .95em;

// Web layout
$sidebar-desktop-width: 28%; //365px;
$content-desktop-width: 72%;

$table-border-color: #979797;
$input-text-color: #6e91c0; //$base-blue-soft;
$linea-color: #797E7F;