/*#block-views-view-groups-home-block{
  .views-row{
    position: relative;
    .views-field-field-entity-logo{
      cursor: pointer;
    }
    .views-field-field-status{
      position: absolute;
      display: none;
      top: -30%;
      left: 0px;
      width: 100%;
      text-align: left;
      background: $base-blue-soft;
      color: $white;
      font-size: 14px;
      padding: 18px;
      border: 1px solid $white;
      .close{
          position: absolute;
          top: -16px;
          right: -8px;
          color: $white;
          text-decoration: none;
          border: 1px solid $white;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          background: $base-blue-darker;
          font-size: 17px;
      }
      .link{
        text-align: center;
        a{
          @include custom-button-2;
          margin-top: 18px;
          border: 1px solid $white;
        }
      }
    }
  }
}
*/
