/*#block-ana-custom-ana-validate-avalua---dor{
  margin: 0 auto;
  padding: 23px 0;
  text-align: center;
  .block-inner {
    .block__title{
      font-size: 36px;
      font-weight: 600px;
    }
    form {
      #edit-form{
        border-bottom: 1px solid $blue-linea;
        max-width: 700px;
        margin: 36px auto 8px;
        padding-bottom: 23px;
        input.form-text{
          max-width: 350px;
          border: 1px solid #d8d8da;
          font-size: 14px;
        }
      }
      #result_avaluador{
        max-width: 1100px;
        margin: 0 auto;
        .top,.middle{
          text-align: left;
          margin-top: 16px;
        }
        .top-item{
          display: inline-block;
          width: 33.33%;
          text-align: left;
          font-size: 12px;
          color: $base-blue-soft;
        }
        .sanciones{
          margin-top: 24px;
          .view-content{
            text-align: left;
            .views-row{
              display: inline-block;
              width:50%;
              font-size: 12px;
              .views-field-field-date{
                span.views-label{
                  color: $base-blue-soft;
                }
                .field-content{
                  display: inline-block;
                  color: #919596;
                }
              }
              .views-field-body{
                color: #919596;
              }
            }
          }
        }
      }
    }
  }
}
*/
