.front {
	.footer {
		background-color: #eee;
	}

	#modalContent {
		position: absolute;
		top: 0 !important;
		bottom: 0 !important;
		left: 0 !important;
		right: 0 !important;

		.modal-header {
			padding: 20px 30px 0;

			@media only screen and (max-width: $mobile) {
				padding-left: 20px;
				padding-right: 20px;
			}
		}

		.modal-title {
			@include page-title;
			display: block;
			position: relative;
			z-index: 15;
		}
	}

	.ctools-modal-content {
		height: 220px !important;
		width: 100% !important;
		max-width: 400px;
		position: fixed !important;
		top: 0 !important;
		bottom: 0 !important;
		left: 0 !important;
		right: 0 !important;
		margin: auto;

		.modal-content {
			width: 100% !important;
			height: auto !important;
			padding: 30px !important;

			@media only screen and (max-width: $mobile) {
				padding: 20px !important;
			}
		}

		.popups-close {
			position: absolute;
			top: 20px;
			right: 30px;
			z-index: 20;

			@media only screen and (max-width: $mobile) {
				right: 30px;
			}
		}
	}

	#user-pass {
		label {
			font-size: 1.13em;
			font-weight: normal;
		}

		.form-text {
			height: 31px;
		}

		.form-submit {
			display: block;
			width: 100%;
		}
	}
}
