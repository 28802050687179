// REFERENCIA: /sites/all/themes/zen_ana/img/diseno/mixins-form-comite.jpg
//        URL: http://ana.seedlabs.co/taxonomy/term/1/edit
.page-taxonomy-term-edit {
	@include manual-active-sidebar-item('#dhtml_menu-671');
	@include cms-input-text('.form-type-textfield', 'label', '.form-text', 200px);
	@include cms-centered-submit('.form-actions', '.form-submit');

	#taxonomy-form-term {
		@include bordered-top;
	}

	.form-type-textfield,
	.form-actions {
		float: left;
		margin-top: 30px;
		padding: 0;
		position: relative;

		label {
			position: absolute;
			bottom: 100%;
			left: 0;
		}
	}

	.form-actions {
		clear: both;

		.form-submit {
		//	padding-top: 2px;
		//	height: 30px;
		}
	}
}

