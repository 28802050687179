.page-sanciones {
	table {
		&::before {
			display: none;
		}
	}

	div div table th,
	div div table td {
		@media only screen and (min-width: $tablet) {
			border: 1px solid $table-border-color;
		}
	}

	div div table thead th {
		padding: 12px 0;
	}

	// Código único
	.views-field-field-unique-code {
		width: 14%; //16.5%;
	}

	// Fecha de sanción
	.views-field-field-date {
		width: 14%;
	}

	// Tipo de sanción
	.views-field-field-type {
		width: 16%;
	}

	// Fecha de finalización de la sanción
	.views-field-field-end-date {
		width: 19%; //18%;
	}

	// Detalle
	.views-field-view-node {
		width: 8%; //65px;
		text-align: center;

		a {
			border: 0;
			padding-top: 0;
			padding-bottom: 0;
			min-width: auto;
			white-space: nowrap;
		}
	}

	// Acciones
	.views-field-nothing {
		width: 32%; //28%;
		//min-width: 260px;

		a {
			padding-left: 10px;
			padding-right: 10px;
			min-width: auto;

			& + a,
			& + a + a {
				margin-left: 10px;
			}
		}
	}
}
