// For IE
.ie {
	&.page-raa-reports {
		#regimen-avaluadores-wrapper {
			select {
				padding-left: 200px;
				margin-left: -185px;
				background-color: transparent;
				width: 90%;
				max-width: none;
				padding-right: 0;
				box-sizing: content-box;

				@media only screen and (max-width: 610px) {
					padding-left: 40px;
					margin-left: -25px;
				}
			}

			option {
				background-color: white;
				width: 600px; //555px;
				max-width: none;

				@media only screen and (max-width: 610px) {
					width: 300px
				}
			}
		}
	}
}
