.page-certificados {
	@include cms-input-text-button('.search-container', 'label', '.form-text', '.form-submit');

	.main-content > h1 {
		@media only screen and (min-width: $mobile) and (max-width: $tablet) {
		//	width: 50%;
		//	float: left;
		}
	}

	#ana-custom-certificate-form {
		position: relative;

		@media only screen and (min-width: $mobile) and (max-width: $tablet) {
		//	margin-top: -13px;
		}
	}

	#edit-search-container {
		@media only screen and (min-width: $mobile) and (max-width: $tablet) {
			position: absolute;
			bottom: 15px;
			right: 0;
			width: 50%;

			.form-item {
				margin: 0;
			}
		}
	}

	.search-container {
		padding: 0;

		@media only screen and (max-width: $tablet) {
			border-bottom: 1px solid $table-border-color;
		}

		label {
			@include page-subtitle;
			/*
			color: $base-gray-dark;
			padding-bottom: 9px;*/
		}

		.form-text {
			border-color: $base-blue-soft;
		}

		.form-submit {
			font-size: .9em;
		}
	}

	#result_certificate {
		@include cms-centered-submit('.form-wrapper', '.form-submit');
	}

	table {
		@media only screen and (min-width: $tablet) {
			border-top: 1px solid $table-border-color;

			tr {
				border-color: $base-gray-soft-2;
			}

			td {
				text-align: left;
				padding: 7px 10px 7px 20px;

				&:first-child {
					width: 22%;
					padding-left: 10px;
					color: $base-blue-soft;
					border-left: 1px solid $base-gray-soft-2;
				}
			}
		}

		@media only screen and (max-width: $tablet) {
			display: block;
			margin-bottom: 20px;
			padding: 0;

			tbody {
				border: 1px solid $table-border-color;
				display: block;
			}

			tr {
				background: $base-gray-soft !important;
				padding: 12px 10px !important;
				border-bottom: 1px solid $table-border-color;
				display: block;
				width: 100%;
			}

			td {
				padding: 0;
				display: block;
				text-align: left;
				line-height: 125%;
				border: 0;

				&:first-child {
					color: $base-blue-dark;
				}
			}
		}
	}
}
