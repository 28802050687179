.jspScrollable,
.jspContainer {
	width: 100% !important;
}

.jspPane {
	width: calc(100% - 24px) !important;
}

.jspVerticalBar {
	width: 5px;
}

.jspTrack {
	background: #f1f0f2;
}

.jspDrag {
	background: #5b83b7;
}

// Se va en mobile
@media only screen and (max-width: $mobile) {
	.jspContainer {
		height: auto !important;
	}

	.jspPane {
		top: auto !important;
		position: relative !important;
		width: 100% !important;
	}

	.jspVerticalBar {
		display: none !important;
	}
}

.container-inline-date .form-item, .container-inline-date .form-item input {
	background: none;
}

.field-collection-container .field-items .field-item {
	margin: 0;
}

.field-collection-view {
	border: 0;
}

.container-inline-date .form-item .form-item,
.container-inline-date .date-padding,
.field-collection-view .entity-field-collection-item {
	float: none;
	width: auto;
	display: block;
}

.container-inline-date .form-item, .container-inline-date .form-item input {
	display: block;
}

.field-collection-container,
.field-collection-view {
	border: 0;
	margin: 0;
	padding: 0;
}

#autologout-cache-check {
	margin: 0;
	display: block;
}