.node-sancion-form {
	.subtitle {
		border-bottom: 1px solid $subtitle-bottom;
		margin-bottom: 24px;

		@media only screen and (max-width: $mobile) {
			margin-bottom: 12px;
			border-width: 2px;
		}
	}

	h4 {
		text-align: center;
		color: $input-text-color; //$base-blue-dark;
		font-size: 1em;
	}

	div.form-item,
	div.form-wrapper {
		max-width: 400px !important;
		margin-left: auto;
		margin-right: auto;
	}

	@include cms-input-text-datepicker('div.form-field-type-datetime', '.form-text', 200px);
	@include cms-select('.form-field-type-list-text', '.form-item', 'label', 'select', 300px, 135px); // le metí el 300px sin ver 
	@include cms-textarea('#edit-body .form-item', 'label', 64px);
	@include cms-input-text('.form-field-name-field-avaluador', 'label', '.form-text', 100%);
	@include cms-input-text('.form-item-title', 'label', '.form-text', 100%);
	//@include cms-file-upload-multiple('#edit-field-documentation', 'legend');
	@include cms-centered-submit('#edit-actions', '.form-submit');

	div.form-field-type-datetime {
		max-width: none;
	}
}
