	// /*==============================
// =          $artifacts          =
// --------------------------------
// *
// *
// * Global mixins, functions, classes and objects accesible from custom files.
// *
// *
// ==============================*\


// /**
// *
// * scope: WHERE DOES IT OPERATE?
// * type: SILENT CLASS, OBJECT, MIXIN?
// * desc: DESCRIPTION
// *
// **\
//@mixin YOUR-ARTIFACT {
//}

// /**
// *
// * scope: GLOBAL
// * type: MIXIN
// * desc: VERTICALLY ALIGNS TWO ELEMENTS TO TOP, INSTEAD OF USING FLOATS
// *
// **\
@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}

// /**
// *
// * scope: GLOBAL
// * type: FUNCTION
// * desc: DEFAULT DARKEN PROCESS FOR HOVERS
// *
// **\
@function darken-hover($color) {
	@return darken($color, 10%);
}

// /**
// *
// * scope: GLOBAL
// * type: MIXIN
// * desc: BUTTON BORDERED NO BACKGROUND
// *
// **\
@mixin custom-button-1 {
	@include inline-block;
	padding: 0.62em 2em;
	font-size: 1.1em;
	box-shadow: none;
	color: $base-blue-soft;
	border: 1px solid $base-blue-soft;
	background-color: transparent;
	text-transform: uppercase;
	font-weight: 400;
	text-decoration: none;
	border-radius: 0;

	&:hover {
		color: darken($base-blue-soft, 10%);
		border-color: darken($base-blue-soft, 10%);
	}
}

// /**
// *
// * scope: GLOBAL
// * type: MIXIN
// * desc: BLUE BUTTON BACKGROUND, COLOR WHITE
// *
// **\
@mixin custom-button-2 {
	@include custom-button-1;
	background-color: $base-blue-soft;
	border: 1px solid white;
	color: white;

	&:hover {
		background-color: darken($base-blue-soft, 10%);
		color: white;
		border-color: white;
	}
}

// pages/page-reports
@mixin custom-button-3 {
	@include custom-button-2;
	padding: 11px 30px 10px;
	min-width: 180px;
	font-weight: 100;
	font-size: 1.1em;
	text-transform: none !important;
	letter-spacing: 0.5px;
}

@mixin custom-button-4 {
	@include custom-button-1;
	background-color: $base-blue-soft;
	color: white;
	padding: 6px 10px;
	font-size: .8em;
	border: 0;
	height: 30px;

	&:hover {
		background-color: darken($base-blue-soft, 10%);
		color: white;
		border-color: white;
	}
}

// Botón sin fondo, color y delineado blanco
@mixin custom-button-5 {
	@include inline-block;
	padding: 0.62em 2em;
	background: none;
	font-size: 1.1em;
	border: 1px solid white;
	color: white;
	font-weight: 100;
	line-height: 100%;
	text-decoration: none;
}

// Botón fondo blanco, color azul
@mixin custom-button-6 {
	@include inline-block;
	padding: .45em .6em;
	background: white;
	border: 1px solid white;
	font-size: 1.1em;
	color: $base-blue-soft;
	font-weight: 100;
	line-height: 100%;
	text-decoration: none;
}

@mixin custom-button-tiny {
	padding: .1em 2em;
}

// /**
// *
// * scope: GLOBAL
// * type: MIXIN
// * desc: VERTICAL ALIGNS TO TOP
// *
// **\
@mixin vertical-align-text {
	display: table;
	height: 100%;
	width: 100%;

	& > * {
		display: table-cell;
		vertical-align: middle;
	}
}

// /**
// *
// * scope: GLOBAL
// * type: MIXIN
// * desc: just a full width
// *
// **\
@mixin full-width {
	width: 100%;
	float: left;
	clear: left;
}

// /**
// *
// * scope: WHERE DOES IT OPERATE?
// * type: SILENT CLASS, OBJECT, MIXIN?
// * desc: DESCRIPTION
// *
// **\
@function darken-hover($color) {
	@return darken($color, 10%);
}

// /**
// *
// * scope: global
// * type: MIXIN
// * desc: Wrapper
// *
// **\
@mixin wrapper-content {
	margin: 0 auto;
	max-width: 1240px;
	padding-left: 20px;
	padding-right: 20px;

	@media only screen and (min-width: $mobile) {
		& {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}

// /**
// *
// * scope: global
// * type: MIXIN
// * desc: Hamburger mobile button
// *
// **\
@mixin hamburger-button {
	.hamburger-button {
		$local-color: white;
		width: 40px;
		height: 39px;
		position: absolute;
		//top: 15px;
		//right: 10px;
		overflow: hidden;
		margin: 0 0 0 10px;
		transform: rotate(0deg);
		transition: .5s ease-in-out;
		cursor: pointer;
		border: 1px solid $local-color;
		z-index: 10;

		@media only screen and (min-width: $desktop) {
			display: none;
		}

		span {
			display: block;
			position: absolute;
			width: 24px;
			height: 4px;
			background: $local-color;
			opacity: 1;
			left: 7px;
			transform: rotate(0deg);
			transition: .25s ease-in-out;
		}
	}

	.hamburger-button {
		span {
			&:nth-child(1) {
		  		top: 8px;
			}

			&:nth-child(2) {
		  		top: 17px;
			}

			&:nth-child(3) {
		  		top: 26px;
			}

			@media only screen and (min-width: $mobile) and (max-width: $tablet) {
				/*width: 30px;

				&:nth-child(1) {
			  		top: 14px;
				}

				&:nth-child(2) {
			  		top: 24px;
				}

				&:nth-child(3) {
			  		top: 34px;
				}*/
			}

			@media only screen and (max-width: $mobile) {
				/*&:nth-child(1) {
			  		top: 11px;
				}

				&:nth-child(2) {
			  		top: 21px;
				}

				&:nth-child(3) {
			  		top: 31px;
				}*/
			}
		}

		&.open {
			span {
				&:nth-child(1) {
			  		top: 17px;
					transform: rotate(135deg);
				}

				&:nth-child(2) {
				  opacity: 0;
				  left: -60px;
				}

				&:nth-child(3) {
				  top: 17px;
				  transform: rotate(-135deg);
				}
			}
		}
	}
}

@mixin diagonal-background($first, $second) {
	background: linear-gradient(to right bottom, $first 50%, $second 50%);
}

@mixin form-text {
	width: 100%;
	border: 1px solid $base-blue-soft;
	padding: 1px 10px 3px;
	font-weight: 100;
	border-radius: 0;
	box-shadow: none;
}

// /**
// *
// * scope: GLOBAL
// * type: MIXIN
// * desc: block title
// *
// **\
@mixin block-title {
	font-size: 1em;
}

// /**
// *
// * scope: GLOBAL
// * type: MIXIN
// * desc: link style
// *
// **\
@mixin default-link {
	font-size: 1em;
	color: $base-blue-soft;
}

// *
// * scope: GLOBAL
// * type: MIXIN
// * desc: FONTS
// *
// **\
@mixin raleway-regular {
	font-family: 'Raleway';
	font-weight: 300;
}

@mixin raleway-medium {
	font-family: 'Raleway';
	font-weight: 400;
}

@mixin opensans-light {
	font-family: 'Open Sans';
	font-weight: 300;
}

@mixin opensans {
	font-family: 'Open Sans';
	font-weight: 400;
}

@mixin opensans-regular {
	font-family: 'Open Sans';
	font-weight: 600;
}

@mixin opensans-semibold {
	font-family: 'Open Sans';
	font-weight: 700;
}

@mixin opensans-bold {
	font-family: 'Open Sans';
	font-weight: 800;
}

@mixin block-title {
	font-size: 1.3em;
	margin-bottom: 12px;
	font-family: 'Raleway';
	font-weight: 400; //100;
}

// Usado en .block.block-presentacion (desktop)
@mixin paragraph-xxl {
	font-size: 1.3em;
	line-height: 145%;
}

@mixin paragraph-xl {
	font-size: 1.1em;
	line-height: 145%;
}

// /**
// *
// * scope: GLOBAL MOBILE
// * type: MIXIN
// * desc: DEFAULT MOBILE PADDING
// *
// **\
@mixin site-mobile-padding {
	@media only screen and (max-width: $desktop) {
		// 15px usado en .block.block-presentacion
		padding-left: $mobile-site-padding;
		padding-right: $mobile-site-padding;
	}
}

// /**
// *
// * scope: blocks/block
// * type: MIXIN
// * desc: BLUE BLOCK DEFAULT STYLES
// *
// **\
@mixin block-default {
	background-color: $base-blue-soft;
	padding-top: 25px;
	padding-bottom: 25px;
}

// /**
// *
// * scope:
// * type: MIXIN
// * desc: Zen: (content) - sidebar
// *
// **\
@mixin zen-content($sidebar-width, $content-width) {
	width: 100%;
	float: left;
	clear: left;
	margin-right: -100%;
	padding-left: 15px;
	padding-right: 15px;

	@media only screen and (max-width: $mobile) {
		overflow: hidden;
	}

	// Tablet
	@media only screen and (min-width: $mobile) and (max-width: $desktop) {
		width: $content-width;
		margin-left: $sidebar-width;
		padding-left: 30px;
		padding-right: 30px;
	}

	// Desktop
	@media only screen and (min-width: $desktop) {
		width: $content-desktop-width;
		margin-left: $sidebar-desktop-width;
		padding-left: 40px;
		padding-right: 40px;
	}
}

// /**
// *
// * scope: pages/
// * type: MIXIN
// * desc: Zen: content - (sidebar)
// *
// **\
@mixin zen-sidebar($sidebar-width, $content-width) {
	//display: none;
	float: right;
	clear: right;
	padding-left: 0;
	padding-right: 0;
	margin-left: -100%;

	// Tablet
	@media only screen and (min-width: $mobile) and (max-width: $desktop) {
		width: $sidebar-width;
		margin-right: $content-width;
		display: block;
	}

	// Desktop
	@media only screen and (min-width: $desktop) {
		width: $sidebar-desktop-width;
		margin-right: $content-desktop-width;
		display: block;
	}
}

// /**
// *
// * scope: blocks/layout-1
// * type: MIXIN
// * desc: ESTILO DE TABLAS MOBILE
// *
// **\
@mixin tableMobileType1 {
	//border-top: 1px solid $table-border-color;

	// JS appended div from scripts.js - function tableMobileType1
	.appended-th {
		color: $base-blue-dark;
		//font-size: $small-text-1;
		line-height: 100%; //50%;
		//margin-top: 4px;
		margin-bottom: 4px;
		display: block;

		& + a,
		& + a + a,
		& + a + a + a {
			margin-top: 10px;
		}
	}

	&::before {
		display: none;
	}

	thead {
		display: none;
	}

	// Deben estar alineados verticalmente
	tbody {
		tr {
			position: relative;
			display: block;
			padding-left: 0; //26px;
			float: left;
			width: 100%;
			box-sizing: border-box;
		}

		td {
			float: left;
			width: 100% !important;
			text-align: left;
			border-bottom: 1px solid $table-border-color;
			padding-left: 10px;
			padding-right: 10px;
			border-right: 1px solid $table-border-color;

			&:last-child {
				border: 0;
				border-right: 1px solid $table-border-color;
				text-align: left;
			}
		}
	}

	// Contiene el checkbox
	// Cuando existe este checkbox, todos los td siguientes tienen padding left
	.views-field-views-row-selector {
		float: left;
		width: 25px !important;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		border: 1px solid $table-border-color;
		border-top: 0;
		border-bottom: 0;
		padding: 0;
		background-color: white;
		z-index: 10;
		border-left-color: white;

		& + td,
		& + td + td,
		& + td + td + td,
		& + td + td + td + td,
		& + td + td + td + td + td,
		& + td + td + td + td + td + td {
			padding-left: 40px;
		}
	}

	.form-type-checkbox {
		@include inline-block;
		position: relative;
		left: -3px;
		top: 15px;
	}
}


// /**
// *
// * scope: blocks/sidebar-menu
// * type: MIXIN
// * desc: estilos de tabla en mobile
// * REFERENCIA: /sites/all/themes/zen_ana/img/diseno/table-mobile.jpg
// * EJEMPLO: 
// * table {
// * 	@media only screen and (max-width: $tablet) {
// * 		@include tableMobileType2;
// * 	}
// * }
// *
// **\
@mixin tableMobileType2 {
	@include tableMobileType1;
	border-top: 0;

	tr {
		background: $base-gray-soft;
		padding-left: 0 !important;
		border: 1px solid #979797 !important;
		border-right: 0 !important;
		display: block;
		width: 100%;
		margin-bottom: 10px;

		td {
			min-height: 50px;
			display: block;
		}
	}
}

@mixin table-view-title-header-filters {
	.view-header {
		@media only screen and (min-width: $mobile) and (max-width: $tablet) {
			width: 50%;
			float: left;
		}
	}

	.view-filters {
		@media only screen and (min-width: $mobile) and (max-width: $tablet) {
			width: 50%;
			float: right;
		}
	}
}
@mixin table-layout-view-header {
	.view-header {
		color: $base-gray-dark;
		margin-bottom: 0; //5px; // estorba en sanciones
		font-weight: 100;
		//margin-top: -10px;
		font-size: 1em;
		//margin-top: -17px;  // es necesario en http://ana.seedlabs.co/sanciones

		@media only screen and (max-width: $mobile) {
		//	margin-top: -12px; lo saqué por http://ana.seedlabs.co/gestion-usuarios
		}

		a {
		/*	@include custom-button-1;
			padding: 7px 26px;
			font-weight: 500;*/
		}

		.button-wrapper {
			@media only screen and (max-width: $mobile) {
				text-align: center;
				padding-top: 5px;
				padding-bottom: 15px;
				border-bottom: 1px solid $subtitle-bottom;
			}
		}
	}
}

@mixin table-layout-view-filter($className) {
	.view-filters {
		position: relative;

		label {
			display: none; // http://ana.seedlabs.co/avaluadores
		}

		/*
		@media only screen and (min-width: $mobile) {
			width: 50%;
			float: right;
			text-align: right;
		}*/

		#{$className} {
			float: left;
			position: relative;
			width: 84%;

			label {
				display: none;
			}
		}

		div.form-item .form-text {
			padding: 2px 10px 1px;
			height: 30px;
		}

		.views-submit-button {
			float: left;
			padding: 0;
			position: absolute;
			top: 0;
			right: 0; //2px;
		}

		.form-submit {
			@include custom-button-4;
			font-size: 1em;
			/*
			@include custom-button-tiny;
			padding-left: 15px;
			padding-right: 15px;
			padding: 2px 15px 1px;
			font-size: .8em;*/
		}

		form {
			@include inline-block;
		}
	}
}

// /**
// *
// * scope: blocks/table-layout-1, table-layout-2
// * type: MIXIN
// * desc: Acomoda en mobile los botones: Eliminar, Inactivar, Eliminar
// *
// **\
@mixin table-edit-actions {
	#edit-actions {
		margin-bottom: 30px;
		position: relative;
		z-index: 20;

		@media only screen and (max-width: $tablet) {
			margin-left: -15px;
			margin-right: -15px;
			box-shadow: 0 -2px 2px #ccc;
			clear: both;
		}

		.form-submit {
			@media only screen and (max-width: $tablet) {
				max-width: 33.33%;
				width: 100%;
				padding-left: 0;
				padding-right: 0;
				
			}

			@media only screen and (min-width: $tablet) {
				margin-right: 20px;
			}
		}
	}
}

// /**
// *
// * scope: GLOBAL
// * type: MIXIN
// * desc: triangle css, http://apps.eky.hk/css-triangle-generator/
// *
// **\
@mixin triangle-right($color) {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 5.5px 0 5.5px 10px;
	border-color: transparent transparent transparent $color;
	//border-width: 0 12.5px 12px 12.5px;
	//border-color: transparent transparent $color;
}

// /**
// *
// * scope: blocks/sidebar-menu
// * type: MIXIN
// * desc: triangle css
// *
// **\
@mixin sidebar-button-hover {
	@include triangle-right(white);
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 100%;
	margin: auto;
}

// /**
// *
// * scope: _page-admin-structure-taxonomy-categorias-avaluadores.scss
// * type: MIXIN
// * desc: muchas páginas internas no respetan la ruta de los url, por lo que los menú padre no se muestran activos
// *
// **\
@mixin manual-active-sidebar-item($element) {
	#{$element} {
		a {
			color: $base-gray-soft-2 !important;
		}
	}
}


// http://ana.seedlabs.co/group/8/node/add/comite
@mixin cms-input-file {
	.form-type-managed-file {
		label {
			height: 30px;
			line-height: 100%;
			padding: 9px 8px 8px;
		}
	}

	.form-managed-file {
		.form-submit {
			font-size: $small-text;
			text-transform: uppercase;
			padding: 6px 10px;
			line-height: inherit;
		}
	}
}

@mixin draggable-button {
	width: 30px;
	padding-left: 0;
	padding-right: 10px;

	.tabledrag-handle {
		display: block;
		min-width: inherit;
		width: 27px;
		padding: 0;
		margin: 0;
		height: auto;
		border-color: $base-gray-soft-2;

		.handle {
			width: 27px;
			height: 28px;
			margin: 0;
			padding: 0;
			background-position: 7px 8px;
			border-color: $base-gray-soft-2;
		}
	}
}

// /**
// *
// * scope: pages/page-node-add-comite
// * type: MIXIN
// * desc: MULTIPLE FIELD
// *
// **\
@mixin cms-multiple-table($selector) {
	#{$selector} {
		$local-input-text-width: 365px;
		width: 100%;
		padding-top: 25px;

		.tabledrag-toggle-weight-wrapper {
			display: none;
		}

		// th.field-label
		.field-label {
			text-align: left;
			padding: 0;
			border: 0;
			position: relative;
			display: block;

			> label {
				@include input-placeholder;
				margin: 0;
				position: absolute;
				bottom: 7px; //13px; //100%;
				left: 0;
				display: block;

				> .form-required {
					position: absolute;
					left: 101%;
					top: 0;
				}
			}
		}

		.field-multiple-table {
			max-width: $local-input-text-width + 35;
			//width: 100%;
			float: left;
			margin-right: 25px;

			// El submit es anónimo, está dentro de un div con clase clearfix
			& + .clearfix {
				display: block;
				clear: none;
			}

			// Borro el before global que le puse
			&::before {
				display: none;
			}
		}

		.form-text {
			@include input-text-default;
			max-width: $local-input-text-width;
			width: 100%;
		}

		.form-submit {
			@include custom-button-1;
			line-height: 100%;
			font-size: $small-text;
			padding: 8px;
			text-transform: none;
		}

		tr,
		th,
		td {
			border: 0;
			padding: 0;
			vertical-align: top;
		}

		tbody {
			tr {
				display: block;
				margin-bottom: 5px;
			}
		}

		.field-add-more-submit {
			margin: 0;
			
			@media only screen and (max-width: $mobile) {
				display: block;
				margin: 0 auto;
				float: none;
			}
		}

		// td .form-item
		.form-item {
			margin-bottom: 0;
			position: relative;

			> label {
				margin: 0;
				position: absolute;
				top: 0;
				left: 102%;
			}

			> .form-text {
				padding-top: 2px;
				padding-bottom: 2px;
			}
		}

		// td.field-multiple-drag
		.field-multiple-drag {
			@include draggable-button;
		}
	}
}

// http://ana.seedlabs.co/user/309/edit/avaluador
@mixin cms-monster-fields($parent, $width: 200px) {
	#{$parent} {
		font-weight: 100;
		margin-bottom: 15px;

		th,
		td {
			border: 0;
		}

		thead {
			th {
				text-align: left;
			}
		}
		
		> * > .form-item {
			display: block;
		}

		.tabledrag-toggle-weight-wrapper {
			display: none;
		}

		.field-multiple-drag {
			@include draggable-button;
			border-right: 1px solid $base-gray-soft-2;
		}

		td {
			text-align: left;
			vertical-align: top;

			> * {
				margin-bottom: 15px;
			}
			
			> .ajax-new-content {
				margin-bottom: 0;

				> * {
					margin-bottom: 15px;
				}
			}

			.tabledrag-handle {
				width: auto;
				min-width: auto;
			}
		}
		

		.field-widget-options-select {
			@include inline-block;
			//width: $width;
			text-align: left;
			border: 1px solid $base-gray-soft-2;
			padding-left: 5px;
			padding-right: 5px;

			.form-select {
				display: inline-block;
				vertical-align: middle;
				margin: 0;
				width: auto;
			}

			label {
				color: $base-blue-dark;
			}
		}

		div.field-widget-date-popup {
			width: auto;
			max-width: none;
		}

		label,
		.SumoSelect,
		legend,
		.fieldset-wrapper,
		.date-padding,
		.form-item,
		.form-text {
			display: inline-block;
			vertical-align: middle;
			margin: 0;
			width: auto;
			float: none;
		}
		
		.SumoSelect > .CaptionCont {
			border: 0;
			color: $base-blue-soft;

			> label > i {
				width: 24px;
				left: auto;
				background: white url("../img/sprites/Arrow-blue-down.png") no-repeat 0 0;
			}
		}

		.file-icon,
		.file-size {
			display: none;
		}

		.file {
			a {
				max-width: none;
				width: auto;
				margin: 0;
			}
		}
		
		.file-widget .form-submit {
			background-color: $base-blue-dark;
			color: white;
		}
	}

	.form-submit {
		@include custom-button-1;
		line-height: 100%;
		font-size: $small-text;
		padding: 9px;
		text-transform: none;
	}
}

@mixin input-placeholder {
	font-weight: 400; //100;
	color: $input-text-color;
	font-size: 14px; //em; //$small-text; //1em;
	line-height: 135%;
}

@mixin input-size {
	height: 30px;
	padding: 1px 10px 3px;
}

@mixin input-text-default {
	@include input-placeholder;
	@include input-size;
	border: 1px solid $base-gray-soft-2;
}


@mixin cms-form-item-border-bottom($form-item) {
	#{$form-item} {
		padding-bottom: 16px;
		border-bottom: 1px solid $base-gray-half;
	}
}

// /**
// *
// * scope: blocks/sidebar-menu
// * type: MIXIN
// * desc: triangle css
// * REFERENCIA: /sites/all/themes/zen_ana/img/diseno/mixins-form-comite.jpg
// *
// **\
@mixin cms-input-text($form-item, $label, $form-text, $formWidth) {
	#{$form-item} {
		max-width: $formWidth;
		margin-bottom: 10px;

		#{$label} {
			@include input-placeholder;
			margin-bottom: 8px;
		}

		#{$form-text} {
			@include input-text-default;
		}
	}
}

@mixin cms-input-text-date($form-item, $label, $form-text, $formWidth) {
	#{$form-item} {
		max-width: $formWidth;
		margin-bottom: 10px;

		legend,
		.form-item,
		fieldset,
		.form-item .date-padding {
			border: 0;
			padding: 0;
			margin: 0;
		}

		.form-item .date-padding,
		.date-no-float {
			width: 100%;
		}

		#{$label} {
			@include input-placeholder;
			margin-bottom: 8px;
			line-height: 100%;
		}

		#{$form-text} {
			@include input-text-default;
			margin-right: 0;
			width: 100%;
		}

		.form-item {
			width: 100%;

			label {
				display: none;
			}
		}

		.description {
			display: none;
		}
	}
}

// Lo mismo pero con descripción
@mixin cms-input-text-description($form-item, $label, $form-text, $formWidth) {
	#{$form-item} {
		margin-bottom: 10px;
		overflow: hidden;

		#{$label} {
			@include input-placeholder;
			margin-bottom: 8px;
		}

		#{$form-text} {
			@include input-text-default;
			max-width: $formWidth;

			@media only screen and (min-width: $mobile) {
				margin-right: 10px;
				float: left;
			}
		}
	}
}

// * REFERENCIA: /sites/all/themes/zen_ana/img/diseno/mixins-form-comite.jpg
@mixin cms-input-text-datepicker($form-item, $form-text, $width) {
	#{$form-item} {
		@include clearfix;
		max-width: $width;
		margin-bottom: 10px;
		border: 1px solid $base-gray-soft-2;

		.form-item {
			margin: 0;
		}

		#{$form-text} {
			@include input-placeholder;
			@include input-size;
			border: 1px solid transparent;
			padding: 2px 0;
		}

		fieldset,
		legend,
		fieldset.date-combo .container-inline-date .date-padding {
			padding: 0;
			border: 0;
			border: 0;
			margin: 0;
		}

		legend {
			@include input-placeholder;
			@include input-size;
			margin-right: 10px;
			display: block;
			white-space: nowrap;
			float: left;
			padding: 6px 0 2px 10px;
			position: relative;
			
			.fieldset-legend {
				display: block;
				//width: 35px; //40px;
				overflow: hidden;
			}

			&::after {
				content: ": ";
				position: absolute;
				top: 6px;
				left: 100%;
			}
		}

		.fieldset-wrapper {
			float: left;
			width: 80px;
		}

		label,
		.description {
			display: none;
		}

		.container-inline-date > .form-item {
			margin-right: 0;
			display: block;
		}
	}
}

// /**
// *
// * scope: pages/page-certificados
// * type: MIXIN
// * desc: MULTIPLE FIELD
// * REFERENCIA: /sites/all/themes/zen_ana/img/diseno/mixins-form-comite.jpg
// *
// **\
@mixin cms-input-text-button($selector, $label, $input, $submit) {
	#{$selector} {
		position: relative;
		padding-top: 13px; // 8px

		#{$label} {
			@include input-placeholder;
			margin-bottom: 8px;
		}

		.form-wrapper {
			position: relative;
			max-width: 370px;
		}

		#{$input} {
			@include input-text-default;
			max-width: 88%;
			position: relative;
			z-index: 3;
		}

		#{$submit} {
			@include custom-button-4;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 5;
		}
	}
	
}

// * REFERENCIA: /sites/all/themes/zen_ana/img/diseno/mixins-form-comite.jpg
@mixin cms-table-lista-agregados($selector, $label) {
	#{$selector} {
		padding-top: 8px;

		#{$label} {
			@include input-placeholder;
			margin-bottom: 8px;
		}

		th {
			@include input-placeholder;
			text-align: center;

			&:nth-of-type(1) {
				text-align: left;
			}
		}
		
	}
}

// * REFERENCIA: /sites/all/themes/zen_ana/img/diseno/mixins-form-comite.jpg
@mixin cms-centered-submit($selector, $submit) {
	#{$selector} {
		padding: 15px 0 30px;
		text-align: center;

		#{$submit} {
			@include custom-button-2;
		}
	}
}

// SCOPE: pages/node-sancion-form
// URL: group/1/node/add/sancion
// <div class="$selector">
//   <div class="$innerdiv">
//      <$label></$label><$select><option></$select>    <----- tags
// </div></div>
@mixin cms-select($selector, $innerdiv, $label, $select, $fieldwidth, $labelwidth) {
	#{$selector} {
		border: 1px solid $base-gray-soft-2;
		margin-bottom: 10px;
		max-width: $fieldwidth;

		#{$innerdiv} {
			display: table;
			width: 100%;
			margin: 0;
		}

		#{$label} {
			@include input-placeholder;
			padding-left: 10px;
			margin: 0;
			display: table-cell;
			width: $labelwidth;
		}

		#{$select} {
			display: table-cell;
		}
	}
}

// SCOPE: pages/node-sancion-form
// URL: group/1/node/add/sancion
@mixin cms-textarea($selector, $label, $textareaHeight) {
	#{$selector} {
		border: 1px solid $base-gray-soft-2;
		margin-bottom: 10px;

		#{$label} {
			@include input-placeholder;
			padding: 6px 0 0 10px;
			margin: 0 0 2px;
		}

		textarea {
			@include input-placeholder;
			border: 0;
			padding: 0 10px;
			min-height: $textareaHeight;
			height: $textareaHeight;

			@media only screen and (max-width: $mobile) {
				min-height: 67px;
				height: 67px;
			}
		}
	}
}

@mixin cms-file-upload-single($selector) {
	#{$selector} {
		position: relative;

		.description {
			display: none;
		}

		// css class
		.label {
			@include inline-block;
			color: $base-blue-soft;
			font-weight: 100;
			font-size: 1em;
			line-height: 100%;
			padding: 10px 10px 10px 0;
		}

		label {
			@include input-text-default;
			@include inline-block;
			cursor: pointer;
			margin: 2px 0 0;
			background-color: white;
			//padding: 9px 10px 8px;
			background-size: 15px auto;
			color: $base-blue-soft;
			padding-top: 6px;
			//font-size: 1em;
			//line-height: 100%;
			text-align: left;
			//line-height: 100%;
			//font-weight: 400;
			border: 1px solid $base-blue-soft;
		}

		.form-file {			
			opacity: 0;
			height: 1px;
			width: 1px;
			position: absolute;
			
			&:focus + label {
				background-color: black;
				outline: 1px dotted #000;
				outline: -webkit-focus-ring-color auto 5px;
			}
		}

		.file-widget {
			@include inline-block;
		}

		.form-submit {
			@include input-text-default;
			@include custom-button-2;
			text-transform: none;
			font-size: 1em;
			line-height: 100%;
			border: 0;
			margin: 2px 0 0;
		}
	}

	.webform-component-file {
		//padding-top: 22px; // a) por el description con margen top negativo
		
		.form-managed-file {
			position: relative;
			z-index: 10;
			
			/*
			& + .description {
				margin-top: -75px; // b) Mueve el description arriba del input file
				
				br:first-child {
					margin-bottom: 60px; // c) Separa la primera línea del description de las siguientes
				}
			}*/
		}
		
		label {
			cursor: pointer;
			margin: 0;
			padding: 1em 3em 1em 2em;
			// agregando otros estilos
			background-color: #989a9b;
			font-size: 12px;
			padding: 13px 55px 12px 20px;
			background-size: 15px auto;
		}
		
		.form-file {			
			&:focus + label {
				background-color: black;
				outline: 1px dotted #000;
				outline: -webkit-focus-ring-color auto 5px;
			}
		}
		
		.form-submit {
			display: none;
		}
	}
}


@mixin cms-file-upload-multiple($selector, $label) {
	#{$selector} {
		position: relative;

		#{$label} {
			@include input-placeholder;
		}

		.description {
			display: none;
		}

		// css class
		.label {
			@include inline-block;
			color: $base-blue-soft;
			font-weight: 100;
			font-size: 1em;
			line-height: 100%;
			padding: 10px 10px 10px 0;
		}

		label {
			@include input-text-default;
			@include inline-block;
			cursor: pointer;
			margin: 2px 0 0;
			background-color: white;
			//padding: 9px 10px 8px;
			background-size: 15px auto;
			color: $base-blue-soft;
			padding-top: 6px;
			//font-size: 1em;
			//line-height: 100%;
			text-align: left;
			//line-height: 100%;
			//font-weight: 400;
			border: 1px solid $base-blue-soft;
		}

		.form-file {			
			opacity: 0;
			height: 1px;
			width: 1px;
			position: absolute;
			
			&:focus + label {
				background-color: black;
				outline: 1px dotted #000;
				outline: -webkit-focus-ring-color auto 5px;
			}
		}
	}

	.webform-component-file {
		//padding-top: 22px; // a) por el description con margen top negativo
		
		.form-managed-file {
			position: relative;
			z-index: 10;
			
			/*
			& + .description {
				margin-top: -75px; // b) Mueve el description arriba del input file
				
				br:first-child {
					margin-bottom: 60px; // c) Separa la primera línea del description de las siguientes
				}
			}*/
		}
		
		label {
			cursor: pointer;
			margin: 0;
			padding: 1em 3em 1em 2em;
			// agregando otros estilos
			background-color: #989a9b;
			font-size: 12px;
			padding: 13px 55px 12px 20px;
			background-size: 15px auto;
		}
		
		.form-file {			
			&:focus + label {
				background-color: black;
				outline: 1px dotted #000;
				outline: -webkit-focus-ring-color auto 5px;
			}
		}
		
		.form-submit {
			display: none;
		}
	}
}

// /**
// *
// * scope: pages/page-reports
// * type: MIXIN
// * desc: caja coloreada
// *
// **\
@mixin caja-coloreada-box($box) {
	@include inline-block;

	#{$box} {

	}
}

@mixin caja-coloreada-background($box, $bg) {
	@include inline-block;

	#{$box} {
		.report-container {
			background-color: #{$bg};
		}
	}
}

// /**
// *
// * scope: layout, blocks/block
// * type: MIXIN
// * desc: page title
// *
// **\
@mixin page-title {
	font-family: 'Raleway';
	font-weight: 400;
	font-size: 2.40em;
	line-height: 100%;
	color: $base-blue-dark; //$base-blue-soft;
	text-align: left;

	@media only screen and (max-width: $desktop) {
		font-size: 1.62em;
		padding: 0; // 10px;
		font-weight: 400;
		line-height: 140%;
	}
}

@mixin page-subtitle {
	line-height: 145%;
	margin-top: -5px;
	margin-bottom: 10px;
	font-size: .9em;
	font-weight: 400;
	color: $base-gray-dark;
}

@mixin table-line {
	@media only screen and (max-width: $desktop) {
		margin: 17px 0 0;
	}

	@media only screen and (max-width: $mobile) {
		margin: 10px 0 0;
	}
}

@mixin report-page {
	form,
	.block-form {
		@include report-block;
	}

	.block-form {
		form {
			padding-left: 0;
			padding-right: 0;
			margin-bottom: 0;
		}
	}

	.block.block-encabezado .field-name-field-title-descripcion {
		@media only screen and (max-width: $mobile) {
			margin-bottom: 0;
		}
	}

	.form-wrapper {
		min-width: initial;
	}

	.main-content {
		overflow: hidden;

		.layout-3col__right-content {
			@media only screen and (min-width: $desktop) {
			//	padding-right: 0;
			}
		}

		.block-encabezado {
			padding-bottom: 20px;
			text-align: left;
		}

		// Mobile and tablet
		@media only screen and (max-width: $desktop) {
			text-align: center; // Esto centra los forms con inline block en mobile y tablet
		}

		@media only screen and (min-width: $desktop) {
			margin-left: -20px;
			margin-right: -20px;
			text-align: left;

			> h1,
			.block-encabezado {
				padding: 0 20px 40px;
			}

			> h1 {
				padding-bottom: 0;
			}
		}
	}

	.form-item {
		.form-text {
			//color: white;
		}
	}
	
	.date-padding {
		.form-text {
			color: white;
		}
	}
}

@mixin report-page-uppercase {
	span:nth-of-type(2) {
		text-transform: uppercase;
		font-size: 1.62em;
	}
}

@mixin report-page-less-padding {
	span:nth-of-type(1) {
		padding-top: 9px !important;
	}
}

@mixin report-formato-textual {
	.report-container {
		> span {
			&:nth-of-type(1) {
				padding-top: 23px;
				margin-bottom: 12px; //16px;
			}

			&:nth-of-type(2) {
				margin-bottom: 13px;
			}
		}
	}
}

// /**
// *
// * scope: pages/page-reports
// * type: MIXIN
// * desc: bloque cuadrícula
// *
// **\
@mixin report-block {
	@include inline-block;
	position: relative;
	max-width: 268px;
	width: 268px; //265px;
	padding-left: 20px; //11px;
	padding-right: 20px; //11px;
	margin-bottom: 60px;
	box-sizing: content-box;

	@media only screen and (max-width: $desktop) {
		margin-bottom: 22px;
	}

	@media only screen and (max-width: $mobile) {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		margin-bottom: 0;
	}

	// Campos de fecha
	.container-inline-date {
		width: 120px;
		float: left;
		clear: none;

		& + .container-inline-date {
			float: right;
		}
	}

	> div {
		position: relative;
	}

	// Selector
	.form-item {
		margin: 0;
		position: relative;

		// Dentro de fecha
		.form-text {
			background: none;
			border: 1px solid white;
			margin: 0;
			height: 25px;
			padding-top: 3px;

			&::placeholder {
			  color: #fff
			}
		}

		.form-select {
			background-color: transparent;
			background-image: url('../img/sprites/Arrow-white-down.png');
			color: white;
			width: 165px;
			margin: 0 auto 10px;
			display: block;
			text-align: left; //center;
			width: auto;
			padding-right: 21px;
			min-width: 93px;
		}

		option {
			max-width: 200px; //252px;
			color: $texto-tabla;
			background: white;
			position: relative;
			z-index: 10;
		}
	}

	.form-actions {
		text-align: center;

		@media only screen and (max-width: $mobile) {
			margin-bottom: 22px;
		}

		@media only screen and (min-width: $mobile) {
			position: absolute;
			bottom: -60px;
			left: 0;
			right: 0;
			margin: auto;
		}

		.form-submit {
			@include custom-button-3;
			border: 0;
		}
	}

	.report-container {
		@include clearfix;
		height: 180px; //177px;
		padding: 16px 9px 18px;
		margin-bottom: 58px; //18px;

		@media only screen and (max-width: $mobile) {
			margin-bottom: 22px;
		}

		// Textos numéricos, ej: 100 avaluadores
		span {
			display: block;
			text-align: center;
			color: white;
			line-height: 100%;
			letter-spacing: 1.5px;
			//text-transform: uppercase;

			// Número
			&:nth-of-type(1) {
				font-size: 2.5em;
				font-weight: 600;
				margin-bottom: 2px; //5px;
			}

			// Texto
			&:nth-of-type(2) {
				font-family: 'Raleway';
				font-size: 1.5em;
				font-weight: 300;
				line-height: 135%;
				margin-bottom: 15px;
				padding: 0; // 0 16px;
				letter-spacing: .5px; //2.5px;
				//white-space: nowrap;
			}
		}
	}

	label,
	.description {
		display: none;
	}

	fieldset {
		padding: 0;
		margin: 0;
		border: 0;
	}
}

// /**
// *
// * scope: blocks/block
// * type: MIXIN
// * desc: sombra de bloques hover del home
// *
// **\
@mixin custom-shadow-1 {
	box-shadow: 2px 0px 4px $base-blue-dark;
}

// /**
// *
// * scope: blocks/block
// * type: MIXIN
// * desc: triángulo inferior
// *
// **\
@mixin triangle-bottom($color) {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 20px 24px 0 24px;
	border-color: $color transparent transparent transparent;
}

// blocks/table-layout-2
@mixin simple-link {
	font-size: .8em;
	padding: 0;
	margin: 2px 0 0 !important;
}

// pages/page-era
@mixin form-era-title {
	margin-bottom: 20px;
	font-size: 1.2em;
	font-weight: 300;
	letter-spacing: 1px;
}

// pages/page-era
@mixin form-era-label {
	color: white;
	font-weight: 100;
	font-size: 1em;
	line-height: 100%;
}

// pages/page-taxonomy-term
@mixin bordered-top {
	clear: both;
	width: 100%;
	border-top: 1px solid $linea-color;
	margin-top: 10px;
	padding-top: 10px;
}