// Estilos generales de la view
.view {
	&.simple-header {
		.view-filters {
			float: none;
			width: auto;
		}
	}

	&.view-header-half-width {
		@include table-view-title-header-filters;

		.view-header {
			@media only screen and (min-width: $tablet) {
				width: 50%;
				float: left;
			}

			& + .view-filters {
				@media only screen and (min-width: $tablet) {
					width: 50%;
					float: left;
					margin-top: 25px;
				}
			}
		}

		.view-filters {
			margin-top: 85px;
			overflow: hidden;

			// http://ana.seedlabs.co/gestion-usuarios
			@media only screen and (max-width: $desktop) {
				margin-top: 63px;
			}

			// http://ana.seedlabs.co/gestion-usuarios
			@media only screen and (max-width: $mobile) {
				margin-top: 7px;
			}

			form {
				float: right;
				width: 100%;
				position: relative;

				@media only screen and (min-width: $mobile) {
					max-width: 370px;
				}

				.views-widget-filter-field_user_number_value {
					width: 100%;
				}
			}
		}
	}

	.views-exposed-form {
		.views-exposed-widgets {
			margin: 0;
		}

		.views-exposed-widget {
			padding: 0;
			float: none;

			.form-submit {
				margin: 0;
			}
		}
	}

	&.view-header-button {
		.view-header {
			a {
				@include custom-button-1;
			}
		}
	}

	@media only screen and (min-width: $mobile) {
		// http://ana.seedlabs.co/eras
		&.view-eras {
			.views-field-title {
				text-align: left;
			}

			.table-buttons {
				width: 110px;
			}
		}

		// http://ana.seedlabs.co/categorias-avaluadores
		&.view-categoria-avaluadores {
			.table-buttons {
				width: 110px;
			}

			table {
				.views-field-name {
					width: 65%;
					text-align: left;
				}

				.views-field-field-status {
					width: auto;
				}
			}
		}
	}
	//Styles in to view sanciones diciplinarias
	&.view-registro-de-sanciones-disciplinarias{
		//Oculto submit en la vista
      .form-actions{
		  display: none;
	  }
	}
}

