.node-comite-form {
	// Mixins que construyen los elementos del form
	// URL: group/1/node/add/comite
	// REFERENCIA: /sites/all/themes/zen_ana/img/diseno/mixins-form-comite.jpg
	@include cms-input-text('div.form-item-title', 'label', '.form-text', 200px);
	@include cms-input-text('.form-field-name-field-number-comite', 'label', '.form-text', 200px);
	@include cms-input-text-datepicker('div.form-field-type-datetime', '.form-text', 300px);
	//@include cms-file-upload-single('#edit-field-archivo-pdf');
	@include cms-input-file;
	@include cms-multiple-table('.form-field-name-field-funcionarios');
	@include cms-input-text-button('.search-container', 'label', '.form-text', '.form-submit');
	@include cms-table-lista-agregados('#edit-ana-avaluadores-agregados', 'label');
	@include cms-centered-submit('#edit-actions', '.form-submit');
	@include cms-form-item-border-bottom('.subtitle, .form-field-name-field-funcionarios, .form-field-name-field-decision, .search-container');

	.form-field-name-field-decision {
		display: none;
	}
}
