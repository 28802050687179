// http://ana.seedlabs.co/raa/reports
.page-raa-reports {
	@include report-page;

	#raa-era-form {
		.report-container {
			background-color: #df5b5b;
		}
	}

	#eras-register-wrapper {
		.report-container {
			background-color: #f2b630;
		}
	}

	#avaluadores-wrapper {
		.report-container {
			background-color: #4a4a4a;
		}

		.form-type-select {
			@include inline-block;
			width: 49%;
		}
	}

	#numero-avaluadores-wrapper {
		.report-container {
			background-color: #5c84b3;

			.form-type-select {
				display: inline-block;
				vertical-align: top;
				width: 49%;

				select {
					margin: 0px;
				}
			}
		}
	}

	#tiempo-aprovacion-wrapper {
		@include report-page-uppercase;

		.time-average {
			color: white;
			text-align: center;
		}

		.report-container {
			background-color: #466c9e;

			span {
				font-weight: 200;
				font-size: 18px;
				margin-top: 30px;

				&:nth-of-type(1) {
					font-size: 1.5em;
					line-height: 135%;
					margin-top: 20px;
				}
			}
		}
	}

	#raa-historico-avaluadores-form {
		.report-container {
			background-color: #797e7f;

			> span {
				&:nth-of-type(1) {
					padding-top: 24px;
				}
			}
		}
	}

	#raa-detalle-avaluadores-form {
		.report-container {
			background-color: #df5b5b;
		}
	}

	#solicitud-registro-wrapper {
		.report-container {
			background-color: #f2b630;
		}
	}
	
	#regimen-avaluadores-wrapper {
		.report-container {
			background-color: #5c84b3;
		}
	}

	[id*="estado-avaluadores"] {
		.report-container {
			background-color: #5b83b7;

			.form-select {
				border: 1px solid #fff;
			}
		}
		
		.fieldset-wrapper {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			text-align: center;

			> span {
				text-align: center;
				width: 100%;
			}
		}

		.form-type-select {
			width: 48%;

			&::after {
				display: none;
			}
		}
	}
}
