.table-mobile-layout-2 {
	@media only screen and (max-width: $tablet) {
		.view-content {
			width: 100% !important;
		}
	}

/*
	.view-header {
		margin-bottom: 10px;

		a {
			@include custom-button-1;
		}
	}
	*/

	@media only screen and (max-width: $tablet) {
		.linea-1 {
			// estorba en http://ana.seedlabs.co/respuestas
		//	background: none;
			margin-bottom: 10px; // es necesario en respuestas
		}

		.linea-2 {
			display: none;
		}
	}

	table {
		//margin-bottom: 20px;

		// JS appended div from scripts.js - function tableMobileType1 
		.appended-th {
			display: none;

			& + .form-type-checkbox {
				margin: 0 auto;
				display: block;
			}
		}

		.views-field-views-row-selector {
			width: 38px;
			padding-left: 0;
			padding-right: 0;
		}

		@media only screen and (max-width: $tablet) {
			@include tableMobileType2;
			
			.views-field-nothing, // http://ana.seedlabs.co/comites
			.views-field-view-user,
			.views-field-field-decision-info  // http://ana.seedlabs.co/respuestas
			{
				text-align: center !important;

				.appended-th {
					line-height: 100% !important;

					& + a,
					& + a + a,
					& + a + a + a {
						margin-top: 5px;
					}
				}
			}

			// http://ana.seedlabs.co/respuestas
			a.simple-link {
				@include simple-link;
			}
		}
	}
}
