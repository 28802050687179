@include hamburger-button;

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Raleway';
	font-weight: 400;
	margin: 0 0 10px;
	line-height: 125%;
}

h1 {
	@include page-title;
}

p {
	margin: 0 0 10px;
	font-size: 1em;
}

/*
html{
	min-height:100%;
	height:100%;
}
*/

body {
	@include opensans-light;
	font-size: 14px;
	line-height: 135%;
	//min-height:100%;
	//height:100%;
	letter-spacing: .5px;
	color: $texto-tabla;
	background-size: cover, cover;
	

	&  > .layout-center {
		padding: 0;
		max-width: inherit;
		position: relative;
		//min-height:100%;
		//height:100%;

		& > .layout-3col {
			margin: 0;

			& > .layout-3col__full {
				margin: 0;
				padding: 0;
			}
		}

		& > .layout-swap {
			& > .layout-3col__full {
				padding: 0;
			}
		}
	}
}

::placeholder {
	color: $input-text-color;
	opacity: 1;
}

.layout-swap {
	padding: 0;
}

.layout-3col__left-sidebar {
	padding-left: 0;
	z-index: 20;
}

.layout-3col__right-content {
	padding-top: 40px;

	@media only screen and (max-width: $desktop) {
		padding-top: 87px;
	}

	@media only screen and (max-width: $mobile) {
		padding-top: 74px; // 77px
	}

	> h1 {
		@include page-title;
	}
}

// Hace que el alto del sidebar sea infinito
.layout-3col__right-content {
	position: relative;

	&::before {
		content: "";
		background: $base-blue-soft;
		position: absolute;
		right: 100%;
		bottom: 0;
		width: 100%;
		height: 100%;
	}

	@media only screen and (min-width: $tablet) {
		min-height: 600px;
	}
}

.layout-swap__top {
	display: none;
}

.view-header {
	p {
		color: $base-gray-half;
	}
}

.layout-swap {
	position: static;
}

.layout-3col__right-content {
	@include zen-content($sidebar-desktop-width, $content-desktop-width);

	// Mobile and tablet
	@media only screen and (max-width: $desktop) {
		margin-left: 0;
		width: 100%;
	}
}

aside.layout-3col__left-sidebar {
	@include zen-sidebar($sidebar-desktop-width, $content-desktop-width);
	background-color: $base-blue-soft;
	overflow: hidden;
	padding-top: 20px;
	box-sizing: border-box;

	// Tablet and Desktop only
	// Hace que el sidebar tenga full height, requiere que el padre .layout-swap tenga position static
	@media only screen and (min-width: $desktop) {
		position: absolute;
		top: 0;
		//bottom: 0;
		left: 0;
		height: 100%;
		height: 100vh;
		margin: 0;
	}

	// Mobile and tablet
	@media only screen and (max-width: $desktop) {
		position: fixed;
		margin: 0;
		clear: none;
		float: none;
		display: block;
		padding-top: 10px;
		padding-bottom: 1px; //8px;
		width: 100%;
		min-height: 60px;
	}
}

//#admin-menu,
.messages {
	display: none;
}

.main-content > .messages {
	display:block;
}

body.admin-menu {
//	margin-top: 0 !important;
}

.sticky-header {
	display: none !important;
}

// http://ana.seedlabs.co/gestion-usuarios
.form-actions.moved-with-js {
	@include full-width;
	padding: 18px 0 30px;
	background-color: white;
	position: relative;
	z-index: 20;

	.form-submit {
		margin-right: 18px;
	}

	@media only screen and (max-width: $tablet) {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		padding-bottom: 18px;

		.form-submit {
			margin-right: 0;
			width: 33.33%;
		}
	}
}

.main-content {
	@include clearfix;
}

.linea,
.linea-1,
.linea-2 {
	margin: 10px 0;
	width: 100%;
	height: 1px;
	float: left;
	clear: both;
	background: $linea-color; //$table-border-color;
}

.linea-table {
	@media only screen and (max-width: $tablet) {
		&.linea-1 {
			margin-bottom: 0;
		}

		&.linea-2 {
			margin-top: 0;
		}
	}
}

.logo-raa {
	text-align: center;
}

.subtitle {
	@include input-placeholder;
	color: $base-gray-dark;
	margin-bottom: 11px;
	margin-top: -8px;
	padding-bottom: 19px !important;
	
	@media only screen and (max-width: $mobile) {
		padding-bottom: 9px !important;
		margin-bottom: 12px;
	}
}

aside.layout-3col__left-sidebar {
	@media only screen and (max-width: $desktop) {
		height: auto !important;
	}
}

aside.content-toogle {
	position: fixed;
	top: 0px;
	right: -300px;
	width: 300px;
	height: 100%;
	background: #fff;
	padding: 20px 16px;
	font-size: 13px;
	transition: .3s ease-out all;
	box-shadow: -12px 5px 28px -13px rgba(0, 0, 0,0.4);

	.sidebar-content-visible & {
		right: 0;
		z-index: 14;
	}

	h4 {
		color:$base-blue-soft;
		text-align: center;
	}

	.top {
		color:$base-blue-soft;
		font-size: 12px;
	}
/*
	.view-sanciones-avaluador{
		font-size: 12px;
		.views-field-field-date{
			.views-label{
				color:$base-blue-soft;
			}
			.field-content{
				display: inline-block;
				color:$base-gray-dark;
				font-weight: 200;
			}
		}
		.views-field-body{
			line-height: 1.2;
			font-weight: 200;
			color:$base-gray-dark;
		}
	}
	*/

	.close{
		text-align: right;
		font-size: 20px;
		padding-top: 10px;
		a {
			color: $base-blue-soft;
    		cursor: pointer;
    		text-decoration: none;
		}
	}
}

.description {
	font-size: 12px;
	color: $base-gray-half;
}

.skip-link__wrapper {
	display: none;
}

.messages {
	@include wrapper-content;
	margin-left: auto !important;
	margin-right: auto !important;
}
.form-cutom-ajax{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 1rem 0;
	.button{
		display: inline-block;
		vertical-align: top;
		padding: 0.62em 2em;
		font-size: 1.1em;
		box-shadow: none;
		color: #5a83b6;
		border: 1px solid #5a83b6;
		background-color: transparent;
		text-transform: uppercase;
		font-weight: 400;
		text-decoration: none;
		border-radius: 0;
		font-size: 1em;
		font-weight: 400;
		padding: 0.7em 1.5em;
		margin: 1rem 0;
	}
	label{
		margin-left: 1rem;
	}
}
