.not-front {
	.header {
		display: none;
	}

	// gestion-usuarios
	&.page-gestion-usuarios {
		.layout-3col__right-content {
			@media only screen and (max-width: $desktop) {
				padding-top: 75px;
			}
		}

		.views-field-views-row-selector {
			width: 1%;
		}

		.linea {
			@media only screen and (max-width: $tablet) {
			//	display: none;
			}
		}

		.views-field-edit-node {
			@media only screen and (max-width: $tablet) {
				.appended-th {
					display: none;
				}

				a {
					margin: 0 !important;
				}
			}
		}
	}

	// http://ana.seedlabs.co/avaluadores
	&.page-avaluadores {
		.subtitle {
			margin-bottom: 0;
		}
	}
}
