.page-user{
  .entity-profile2 > div{
    display: flex;
    flex-wrap: wrap;
    .group-general-dates {
      width: 70%;
    }
    .group-foto {
      width: 30%;
      .field-group-format-wrapper{
        padding: 0;
        img{
          height: 220px;
          width: auto;
        }
      }
    }
    .group-documents-add,
    .group-dates-o{
      width: 100%;
    }
    .action-links-field-collection-add,
    .field-collection-view-links{
      display: none;
    }
    .field-group-format-wrapper{
      padding: 1rem 2rem;
    }
  }
}